import React from 'react';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const property_proposed_type: ColumnType<IProjectsPortfolioItem> = ({
  projectPropertyTypes,
}: {
  projectPropertyTypes: {
    type: string;
    name: string;
  }[];
}) => ({
  field: 'property_proposed_type',
  graphFields: ['property_proposed_type'],
  headerName: 'Proposed property type',
  minWidth: 100,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: ({ row }) => {
    const value = row?.original?.property_existing_type;
    return <span>{projectPropertyTypes?.find((x) => x.type === value)?.name || value}</span>;
  },
});

export default property_proposed_type;
