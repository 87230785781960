import { QueryClient, QueryClientConfig } from 'react-query';
import {
  QueryGraphLike,
  createGraphStructure,
  excludeGraphKeys,
  isEmptyGraphStructure,
  filterGraphByStatus,
  getArgumentsForGraphStructure,
  replaceStatus,
} from '@utils';
import { dataProviders } from './provider';
import { getNextPage } from '@globalService';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

declare module 'react-query' {
  interface QueryClient {
    graphRegister(
      queryKey: unknown[],
      graphData: {
        keys?: string[];
        nested?: Record<string, string[]>;
      },
      options?: {
        automaticReady?: boolean;
        infinity?: boolean;
      },
    ): void;
    getGraphBoundFunction(queryKey: unknown[]): () => Promise<unknown>;
    getGraphQueryKey(queryKey: unknown[]): unknown[];
  }
}

enum TimerType {
  Init = 'init',
  Fetch = 'fetch',
}

type TimerCallback = () => void;

export class CustomQueryClient extends QueryClient {
  private timers: Map<TimerType, Map<string, NodeJS.Timeout>> = new Map([
    [TimerType.Init, new Map()],
    [TimerType.Fetch, new Map()],
  ]);

  private readonly DEBOUNCE_TIMES: Record<TimerType, number> = {
    [TimerType.Init]: 50,
    [TimerType.Fetch]: 200,
  };

  constructor(config: QueryClientConfig) {
    super(config);

    this.getQueryCache().subscribe((event) => {
      if (event.type === 'queryUpdated') {
        if (event.query.queryKey[0] === '_graphState') {
          this.graphListener(event);
        } else if (event.action.type === 'success') {
          this.queryListener(event);
        }
      }
    });
  }

  private queryListener(event): void {
    const queryKey = event.query.queryKey;
    const [type, ...rest] = queryKey;
    const graphStateFnName = this.getGraphQueryKey(queryKey);
    const graphState = this.getQueryData<QueryGraphLike>(graphStateFnName);
    if (graphState) {
      const requestedGraph = filterGraphByStatus(graphState.graph, 'requested');

      if (!isEmptyGraphStructure(requestedGraph) && graphState.readyToInit) {
        const debounceKey = this.getDebounceKey([type, rest[0]]);
        this.debounce(TimerType.Fetch, debounceKey, () => {
          this.fetchMoreKeys(queryKey);
        });
      }
      const loadingGraph = filterGraphByStatus(graphState.graph, 'fetching');
      if (!isEmptyGraphStructure(loadingGraph)) {
        this.graphDataFetched(event.query.queryKey);
      }
    }
  }

  private graphListener(event): void {
    const [_type, ...rest] = event.query.queryKey;
    const graphState = event.query.state.data as QueryGraphLike;
    const dataQueryKey = this.getQueryCache().find([rest[0], rest[1]]);
    const requestedGraph = filterGraphByStatus(graphState.graph, 'requested');
    if (
      dataQueryKey?.state?.status === 'success' &&
      graphState.readyToInit &&
      !isEmptyGraphStructure(requestedGraph)
    ) {
      const debounceKey = this.getDebounceKey([rest[0], rest[1]]);
      this.debounce(TimerType.Fetch, debounceKey, () => {
        this.fetchMoreKeys(rest);
      });
    }
  }

  private graphDataFetched(queryKey: unknown[]): void {
    const graphStateFnName = this.getGraphQueryKey(queryKey);

    this.setQueryData<QueryGraphLike>(graphStateFnName, (oldData) => {
      const graph = replaceStatus(oldData.graph, 'fetching', 'success');
      return {
        ...oldData,
        graph,
      };
    });
  }

  private getDebounceKey(queryKey: unknown[]): string {
    const [type, args] = queryKey;
    if (typeof args === 'object' && args !== null) {
      return `${type}:${JSON.stringify(args)}`;
    }
    return `${type}:${args}`;
  }

  private createGraphQuery(queryKey: unknown[]): void {
    const graphDefaults = this.getQueryDefaults(queryKey);
    if (!graphDefaults) {
      this.setQueryDefaults(queryKey, {
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnMount: false,
      });

      this.setQueryData<QueryGraphLike>(queryKey, {
        graph: {},
        readyToInit: false,
      });
    }
  }

  private initGraph(queryKey: unknown[]): void {
    this.setQueryData<QueryGraphLike>(queryKey, (oldData) => ({
      ...oldData,
      readyToInit: true,
    }));
  }

  public getGraphQueryKey(queryKey: any[]): unknown[] {
    return ['_graphState', ...queryKey];
  }

  private debounce(type: TimerType, key: string, callback: TimerCallback): void {
    const timerMap = this.timers.get(type);
    if (!timerMap) return;

    if (!timerMap.has(key)) {
      const timer = setTimeout(() => {
        callback();
        timerMap.delete(key);
      }, this.DEBOUNCE_TIMES[type]);

      timerMap.set(key, timer);
    }
  }

  private async fetchMoreKeys(queryKey: unknown[]) {
    const graphStateFnName = this.getGraphQueryKey(queryKey);
    const graphState = this.getQueryData<QueryGraphLike>(graphStateFnName);
    const requestedGraph = filterGraphByStatus(graphState.graph, 'requested');
    if (!graphState || isEmptyGraphStructure(requestedGraph)) {
      return;
    }

    const boundFunction = this.getGraphBoundFunction(queryKey);
    const addedData = await boundFunction();

    this.setQueryData(queryKey, (oldData: unknown) => {
      return merge(cloneDeep(oldData), addedData);
    });
  }

  public getGraphBoundFunction(queryKey): (params?: any) => Promise<unknown> {
    return (params?: any) => {
      const nextPageImportant = params?.pageParam?.nextPageImportant;
      if (nextPageImportant) {
        return getNextPage(nextPageImportant) as Promise<unknown>;
      }
      const pagination = params?.meta?.args?.pagination;
      const [queryFn, args] = queryKey;
      const fetcher = dataProviders[queryFn];
      if (!fetcher) {
        // console.warn('No query function found for', queryKey);
        return Promise.resolve(null);
      }
      const newArgs = pagination ? { ...args, pagination } : { ...args };
      const graphStateFnName = this.getGraphQueryKey(queryKey);
      const graphState = this.getQueryData<QueryGraphLike>(graphStateFnName);
      const requestedGraph = filterGraphByStatus(graphState.graph, 'requested');
      if (!isEmptyGraphStructure(requestedGraph)) {
        const graphArgs = getArgumentsForGraphStructure(requestedGraph, newArgs);
        this.setQueryData<QueryGraphLike>(graphStateFnName, (oldData) => {
          const graph = replaceStatus(oldData.graph, 'requested', 'fetching');
          return {
            ...oldData,
            graph,
          };
        });
        return fetcher(graphArgs) as Promise<unknown>;
      }
      const loadedGraph = filterGraphByStatus(graphState.graph, 'success');
      if (!isEmptyGraphStructure(loadedGraph)) {
        const graphArgs = getArgumentsForGraphStructure(loadedGraph, args);
        return fetcher(graphArgs) as Promise<unknown>;
      }
    };
  }

  public graphRegister(
    queryKey: unknown[],
    graphData: { keys?: string[]; nested?: Record<string, string[]> },
    options: { automaticReady?: boolean } = {},
  ): void {
    const debounceKey = this.getDebounceKey(queryKey);
    const graphStateFnName = this.getGraphQueryKey(queryKey);

    this.createGraphQuery(graphStateFnName);

    this.setQueryData<QueryGraphLike>(graphStateFnName, (oldData) => {
      const graph = createGraphStructure(oldData?.graph || {}, graphData.keys, graphData.nested);
      if (isEmptyGraphStructure(excludeGraphKeys(graph, oldData?.graph))) return oldData;
      return {
        ...oldData,
        graph,
      };
    });

    if (options.automaticReady !== false) {
      this.debounce(TimerType.Init, debounceKey, () => {
        this.initGraph(graphStateFnName);
      });
    }
  }

  destroy() {
    this.timers.forEach((timerMap) => {
      timerMap.forEach((timer) => clearTimeout(timer));
      timerMap.clear();
    });
  }
}
