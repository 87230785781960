import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, currencyFormatter } from '@utils';

const construction_holdback_current: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'construction_holdback_current',
  graphFields: ['construction_holdback_current'],
  headerName: 'Disbursed construction holdback ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default construction_holdback_current;
