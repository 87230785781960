import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, currencyFormatter } from '@utils';

const project_funds_revised_estimate: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'project_funds.revised_estimate',
  graphNested: {
    project_funds: ['revised_estimate'],
  },
  headerName: 'Revised scheduled value ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) =>
    row?.project_funds?.revised_estimate,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default project_funds_revised_estimate;
