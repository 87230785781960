import action from './actions';
import is_watching from './is_watching';
import loan_external_id from './loan_external_id';
import name from './name';
import address from './address_address_1';
import address_city from './address_city';
import address_state from './address_state';
import project_type from './project_type';
import property_existing_type from './property_existing_type';
import property_proposed_type from './property_proposed_type';
import status from './status';
import draw_request_name from './draw_request_name';
import draw_request_status from './draw_request_status';
import customer_name from './customer_name';
import borrower_name from './borrower_name';
import lender_name from './lender_name';
import coordinator_full_name from './coordinator_full_name';
import loan_loc_commitment from './loan_loc_commitment';
import loan_type from './loan_type';
import project_funds_original_estimate from './project_funds_original_estimate';
import project_funds_revised_estimate from './project_funds_revised_estimate';
import construction_holdback_total from './construction_holdback_total';
import construction_holdback_current from './construction_holdback_current';
import construction_holdback_available from './construction_holdback_available';
import loan_close_date from './loan_close_date';
import loan_maturity_date from './loan_maturity_date';
import loan_extended_maturity_date from './loan_extended_maturity_date';
import duration from './duration';
import inspector_allowance_rate from './inspector_allowance_rate';
import lender_allowance_rate from './lender_allowance_rate';
import gap from './gap';
import is_budget_locked from './is_budget_locked';
import scores_project_score from './scores_project_score';

export default [
  is_watching,
  loan_external_id,
  name,
  address,
  address_city,
  address_state,
  project_type,
  property_existing_type,
  property_proposed_type,
  status,
  draw_request_name,
  draw_request_status,
  customer_name,
  borrower_name,
  lender_name,
  coordinator_full_name,
  loan_loc_commitment,
  loan_type,
  project_funds_original_estimate,
  project_funds_revised_estimate,
  construction_holdback_total,
  construction_holdback_current,
  construction_holdback_available,
  loan_close_date,
  loan_maturity_date,
  loan_extended_maturity_date,
  duration,
  inspector_allowance_rate,
  lender_allowance_rate,
  gap,
  is_budget_locked,
  scores_project_score,
  action,
];
