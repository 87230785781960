import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const duration: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'duration',
  graphFields: ['duration'],
  headerName: 'Length of project (month)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
});

export default duration;
