import React, { FC, useContext, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CollapsedCard, Filter, GeneralRowTable, MilestoneListWithPatch } from '@components';
import { TableKeyEnum } from '@interfaces';
import { SettingsContext } from '@context';
import { useServiceOrderMilestonesTable } from './controller';
import { ProductionInspectionTableV2 } from '../../../../index';

const ServiceOrderMilestonesTable: FC = () => {
  const { isPHBProject } = useContext(SettingsContext);
  const {
    serviceOrder,
    serviceOrderRefetch,
    initColumns,
    milestones,
    serviceOrderPhotos,
    rightMenu,
    rightDrawerParams,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange,
    filterOptions,
    isMilestoneMutatingOrFetching,
    isServiceOrderLinkedToDR,
    fieldsProperties,
  } = useServiceOrderMilestonesTable();
  const containerRef = useRef();

  if (!serviceOrder?.id) return null;

  return (
    <Box
      sx={{
        overflow: 'scroll',
        // maxWidth: { lg: serviceOrder?.result_documents?.length ? '50%' : '80%', xl: '50%' },
        flex: 1,
      }}
      ref={containerRef}
    >
      <Stack spacing={2}>
        <CollapsedCard
          isExpanded
          header={
            <Stack flex={1} spacing={1} direction="row" alignItems="end">
              <Typography variant="h3">Inspection allowance </Typography>
              <Typography variant="label">(Optional)</Typography>
            </Stack>
          }
        >
          <GeneralRowTable
            canAddPhotos={fieldsProperties['inspection_allowance']?.edit}
            photos={serviceOrderPhotos}
            refetch={serviceOrderRefetch}
            rightMenu={rightMenu}
            rightDrawerParams={rightDrawerParams}
            updateRightDrawer={updateRightDrawer}
            comments_preview={serviceOrder.comments_preview}
            documents_preview={serviceOrder.documents_preview}
          />
          {isPHBProject ? (
            <ProductionInspectionTableV2
              showRequestedAmount={isServiceOrderLinkedToDR}
              tableKey={
                fieldsProperties['inspection_allowance']?.edit
                  ? TableKeyEnum.INSPECTION_ENTER_RESULTS
                  : TableKeyEnum.INSPECTION_RESULTS
              }
              containerRef={containerRef}
            />
          ) : (
            <MilestoneListWithPatch
              lockedColumns
              withColumnIndication
              refetch={serviceOrderRefetch}
              tableKey={
                serviceOrder?.is_editable
                  ? TableKeyEnum.INSPECTION_ENTER_RESULTS
                  : TableKeyEnum.INSPECTION_RESULTS
              }
              requestId={serviceOrder?.draw_request?.id}
              key={serviceOrder?.id}
              initColumns={initColumns}
              milestones={milestones?.map((item) => ({
                ...item,
                canAddPhotos: fieldsProperties['inspection_allowance']?.edit,
              }))}
              totals={totals}
              headerLeftPart={[
                {
                  Component: (
                    <Filter
                      filterLabel="Show"
                      onChangeCallback={handleFiltersChange}
                      options={filterOptions}
                      filterValue={filterValue}
                      disabled={isMilestoneMutatingOrFetching}
                      source="inspections__result__line_items_table__filter"
                    />
                  ),
                },
              ]}
              source="inspections__result__line_items_table"
              containerRef={containerRef}
            />
          )}
        </CollapsedCard>
      </Stack>
    </Box>
  );
};

export default ServiceOrderMilestonesTable;
