import React, { useMemo } from 'react';
import { useRequestPaymentData } from '@hooks';
import {
  RequestPaymentConfigurationEditPopup,
  RequestPaymentConfigurationEditPopupV2,
} from '@components';
import { useLaunchDarklyFlags } from '@context';

const RequestPaymentEditPopup = ({
  drawRequestId,
  onClose,
}: {
  drawRequestId: string;
  onClose: () => void;
}) => {
  const flags = useLaunchDarklyFlags();
  const { drawRequestFundingSources } = useRequestPaymentData();
  const customFundingSourcesExist = useMemo(() => {
    return drawRequestFundingSources.some((source) => !source.internal_identifier);
  }, [drawRequestFundingSources]);

  if (customFundingSourcesExist && flags?.['ENG_8724_draw_funding_source_update']) {
    return <RequestPaymentConfigurationEditPopup drawRequestId={drawRequestId} onClose={onClose} />;
  }

  return <RequestPaymentConfigurationEditPopupV2 drawRequestId={drawRequestId} onClose={onClose} />;
};

export default RequestPaymentEditPopup;
