import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, currencyFormatter } from '@utils';

const construction_holdback_total: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'construction_holdback_total',
  graphFields: ['construction_holdback_total'],
  headerName: 'Total construction holdback ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default construction_holdback_total;
