import React, { useState, ReactElement, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { OptionDescription, OptionsListDescription } from './components';
import { IServiceAgency, ServiceProviderEnum, EnumTypeForList } from '@interfaces';
import { CustomAutocomplete } from '@components';
import { DropdownFieldModel, useDropdownFieldModel } from '@models';
import { isServiceProviderManual } from '@utils';

type CustomOption = IServiceAgency & {
  customComponent?: React.ReactNode;
};

interface ServiceProviderSelectionController {
  handleWhatIsTheBestOptionClick: () => void;
  handleInfoIconClick: (item: CustomOption) => void;
  detailsPopup: {
    open: boolean;
    title: string;
    content: ReactElement;
  };
  setDetailsPopup: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      title: string;
      content: ReactElement;
    }>
  >;
  allOptions: CustomOption[];
  radioOptionSelected: string | null;
  setRadioOptionSelected: (value: string) => void;
  manualServiceField: DropdownFieldModel;
}

export const useServiceProviderSelection = (
  serviceAgenciesList: IServiceAgency[],
  serviceAgenciesListIsLoading: boolean,
  agencySelected: IServiceAgency | null,
  setAgencySelected: (value: IServiceAgency) => void,
  defaultInspectionAgency: Pick<IServiceAgency, 'id' | 'display_name' | 'service_provider'>,
): ServiceProviderSelectionController => {
  const defaultAgency = useMemo(() => {
    return serviceAgenciesList?.find((agency) => agency.id === defaultInspectionAgency?.id);
  }, [defaultInspectionAgency, serviceAgenciesList]);

  useEffect(() => {
    if (defaultAgency) {
      setAgencySelected(defaultAgency);
    }
    if (isServiceProviderManual(defaultInspectionAgency?.service_provider)) {
      setRadioOptionSelected('manual_input');
    } else {
      setRadioOptionSelected(defaultAgency?.id);
    }
  }, [defaultInspectionAgency, serviceAgenciesList]);

  useEffect(() => {
    if (!agencySelected) {
      setRadioOptionSelected(null);
    }
  }, [agencySelected]);

  const [detailsPopup, setDetailsPopup] = useState({
    open: false,
    title: '',
    content: <></>,
  });

  const [radioOptionSelected, setRadioOptionSelected] = useState<string | null>(null);
  const serviceAgenciesListRegular = serviceAgenciesList
    .filter((agency) => agency.service_provider !== ServiceProviderEnum.MANUAL)
    ?.map((agency) => ({
      ...agency,
      value: agency.id,
      label: agency.display_name,
    }));

  const serviceAgenciesListManual = serviceAgenciesList
    .filter((agency) => agency.service_provider === ServiceProviderEnum.MANUAL)
    ?.map((agency) => ({
      ...agency,
      value: agency.id,
      label: agency.display_name,
    }));

  const manualServiceField = useDropdownFieldModel({
    initValue:
      defaultAgency?.id && isServiceProviderManual(defaultInspectionAgency?.service_provider)
        ? {
            name: defaultAgency.display_name,
            name_display: defaultAgency.display_name,
            value: defaultAgency.id,
          }
        : {
            name: serviceAgenciesListManual?.[0]?.display_name,
            name_display: serviceAgenciesListManual?.[0]?.display_name,
            value: serviceAgenciesListManual?.[0]?.id,
          },
  });

  useEffect(() => {
    if (manualServiceField.isChanged && manualServiceField.value.value) {
      const selectedManualAgency = serviceAgenciesListManual.find(
        (agency) => agency.id === manualServiceField.value.value,
      );
      if (selectedManualAgency) {
        setAgencySelected(selectedManualAgency);
        setRadioOptionSelected('manual_input');
      }
    }
  }, [manualServiceField.isChanged, manualServiceField.value.value]);

  const allOptions = [
    ...serviceAgenciesListRegular,
    ...(serviceAgenciesListManual.length > 0
      ? [
          {
            id: 'manual_input',
            value: 'manual_input',
            display_name: 'Manual Service',
            description: '',
            logo: '',
            service_provider: ServiceProviderEnum.MANUAL,
            is_premium: false,
            customComponent: (
              <Box sx={{ width: '100%', mt: 1 }}>
                <CustomAutocomplete
                  field={manualServiceField}
                  label="Manual (Service provider name)"
                  options={serviceAgenciesListManual.map((provider) => ({
                    name: provider.display_name,
                    name_display: `${provider.display_name}`,
                    value: provider.id,
                  }))}
                  required={false}
                  disabled={serviceAgenciesListIsLoading}
                  optionsLoading={serviceAgenciesListIsLoading}
                  isOptionEqualToValue={(
                    option: EnumTypeForList | string,
                    value: EnumTypeForList | string,
                  ) => {
                    if (typeof option === 'string' || typeof value === 'string') return false;
                    return option.name === value.name;
                  }}
                  inputProps={{
                    'data-cy': 'order_service__manual_service__select',
                    placeholder: 'Select service provider name',
                  }}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const handleInfoIconClick = (item) => {
    setDetailsPopup({
      open: true,
      title: 'Service provider',
      content: <OptionDescription item={item} />,
    });
  };

  const handleWhatIsTheBestOptionClick = () => {
    setDetailsPopup({
      open: true,
      title: 'What is the best option?',
      content: (
        <OptionsListDescription
          list={serviceAgenciesList.map((item) => ({ id: item.id, label: item.display_name }))}
        />
      ),
    });
  };

  return {
    handleWhatIsTheBestOptionClick,
    handleInfoIconClick,
    detailsPopup,
    setDetailsPopup,
    allOptions,
    radioOptionSelected,
    setRadioOptionSelected,
    manualServiceField,
  };
};
