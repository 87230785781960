import cloneDeep from 'lodash/cloneDeep';
import { IServiceOrder, IPermission, NestedKeys } from '@interfaces';
import { GridColDef } from '@mui/x-data-grid';
import uniq from 'lodash/uniq';
export interface QueryGraphLike {
  graph?: GraphStructure;
  readyToInit?: boolean;
}

export type StatusType = 'requested' | 'fetching' | 'success';

export interface GraphStructure {
  [key: string]: StatusType | GraphStructure;
}
export function getNestedValue<T>(obj: T, path: string): unknown {
  return path.split('.').reduce((acc: any, key: string) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return acc[key];
    }
    return undefined;
  }, obj);
}

export function setNestedValue(obj: GraphStructure, path: string, keys: string[]): void {
  if (!obj) return;
  if (path === '') {
    keys.forEach((key) => {
      if (!(key in obj)) {
        obj[key] = 'requested';
      }
    });
    return;
  }

  const pathKeys = path.split('.');
  let current: GraphStructure = obj;

  pathKeys.forEach((key, index) => {
    if (!(key in current) || typeof current[key] !== 'object') {
      current[key] = {};
    }
    if (index === pathKeys.length - 1) {
      keys.forEach((subKey) => {
        if (!(subKey in (current[key] as GraphStructure))) {
          (current[key] as GraphStructure)[subKey] = 'requested';
        }
      });
    } else {
      current = current[key] as GraphStructure;
    }
  });
}

export function createGraphStructure(
  structureInit: GraphStructure,
  keys: string[] = [],
  nested: Record<string, string[]> = {},
): GraphStructure {
  const structure: GraphStructure = cloneDeep(structureInit);
  const tempStructure = { '': keys, ...nested };
  Object.keys(tempStructure).map((item) => setNestedValue(structure, item, tempStructure[item]));
  return structure;
}

export function excludeGraphKeys(
  obj1: GraphStructure = {},
  obj2: GraphStructure = {},
): GraphStructure {
  const result: GraphStructure = {};

  for (const key in obj1) {
    if (!(key in obj2)) {
      result[key] = obj1[key];
    } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      const nestedResult = excludeGraphKeys(
        obj1[key] as GraphStructure,
        obj2[key] as GraphStructure,
      );
      if (Object.keys(nestedResult).length > 0) {
        result[key] = nestedResult;
      }
    }
  }

  return result;
}

export function isEmptyGraphStructure(obj: GraphStructure): boolean {
  if (Object.keys(obj).length === 0) return true;
  return Object.keys(obj).every((key) => {
    const value = obj[key];
    if (typeof value === 'object') {
      return isEmptyGraphStructure(value);
    }
    return false;
  });
}

export function filterGraphByStatus(obj: GraphStructure, status: StatusType): GraphStructure {
  const result: GraphStructure = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      const filtered = filterGraphByStatus(value, status);
      if (!isEmptyGraphStructure(filtered)) {
        result[key] = filtered;
      }
    } else if (value === status) {
      result[key] = value;
    }
  });

  return result;
}

export function getArgumentsForGraphStructure<T>(
  structure: GraphStructure,
  args: T,
): T & { restQlParams: string } {
  function stringifyStructure(obj: GraphStructure): string {
    return Object.entries(obj)
      .map(([key, value]) =>
        typeof value === 'object' ? `${key}{${stringifyStructure(value)}}` : key,
      )
      .join(',');
  }

  return {
    ...args,
    restQlParams: `{${stringifyStructure(structure)}}`,
  };
}

export function replaceStatus(
  obj: GraphStructure,
  fromStatus: StatusType,
  toStatus: StatusType,
): GraphStructure {
  const result: GraphStructure = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      result[key] = replaceStatus(value, fromStatus, toStatus);
    } else {
      result[key] = value === fromStatus ? toStatus : value;
    }
  });

  return result;
}

export function hasAllKeysInGraph(
  graph: GraphStructure,
  graphData: { keys?: string[]; nested?: Record<string, string[]> },
): boolean {
  if (graphData.keys) {
    for (const key of graphData.keys) {
      if (getNestedValue(graph, key) === undefined) {
        return false;
      }
    }
  }

  if (graphData.nested) {
    for (const [parentPath, nestedKeys] of Object.entries(graphData.nested)) {
      for (const nestedKey of nestedKeys) {
        const fullPath = `${parentPath}.${nestedKey}`;
        if (getNestedValue(graph, fullPath) === undefined) {
          return false;
        }
      }
    }
  }

  return true;
}

export function hasStatusInGraph(
  graph: GraphStructure,
  graphData: { keys?: string[]; nested?: Record<string, string[]> },
  status: StatusType[],
): boolean {
  if (graphData.keys) {
    for (const key of graphData.keys) {
      const value = getNestedValue(graph, key);
      if (status.includes(value as StatusType)) {
        return true;
      }
    }
  }

  if (graphData.nested) {
    for (const [path, nestedKeys] of Object.entries(graphData.nested)) {
      for (const nestedKey of nestedKeys) {
        const fullPath = `${path}.${nestedKey}`;
        const value = getNestedValue(graph, fullPath);
        if (status.includes(value as StatusType)) {
          return true;
        }
      }
    }
  }

  return false;
}

export function filterDataByGraphStructure<T extends object>(
  data: T,
  graphData: { keys?: string[]; nested?: Record<string, string[]> },
): Partial<T> {
  const result: Partial<T> = {};

  graphData.keys?.forEach((key) => {
    if (data && key in data) {
      (result as any)[key] = (data as any)[key];
    }
  });

  if (graphData.nested) {
    Object.entries(graphData.nested).forEach(([parentKey, nestedKeys]) => {
      if (
        (data as any)[parentKey] &&
        typeof (data as any)[parentKey] === 'object' &&
        parentKey in data
      ) {
        (result as any)[parentKey] = filterDataByGraphStructure((data as any)[parentKey], {
          keys: nestedKeys,
        });
      }
    });
  }
  return result;
}

const createBaseKeys = <T>() => ['id'] as Array<keyof T>;
const BASE_NESTED: Record<string, string[]> = {};

export type ColumnConfig<T = IServiceOrder> = GridColDef & {
  enableByPermissions?: (permissions: IPermission[]) => boolean;
  graphFields?: Array<keyof T>;
  graphNested?: NestedKeys<T>;
};

export type ColumnType<T = IServiceOrder> = (params: any) => ColumnConfig<T>;

export const generateGraphKeys = <T = IServiceOrder>(columns?: ColumnConfig<T>[]) =>
  columns
    ? columns.reduce(
        (data, column) => {
          const nested = Object.keys(column?.graphNested || {}).reduce((nested, key) => {
            nested[key] = uniq([...(data.nested[key] || []), ...(column?.graphNested[key] || [])]);
            return nested;
          }, data.nested || {});
          if (column) {
            return {
              keys: uniq([...data.keys, ...(column?.graphFields || [])]),
              nested,
            };
          } else return data;
        },
        { keys: createBaseKeys<T>(), nested: BASE_NESTED },
      )
    : { keys: createBaseKeys<T>(), nested: BASE_NESTED };
