import React from 'react';
import { IconButtonWithTooltip, VerticalMenu } from '@components';
import { IProjectsPortfolioItem } from '@interfaces';
import {
  ChecklistIcon,
  DocumentsIconWithIndicator,
  CommentIconWithIndicator,
  PhotoGalleryIconWithIndicator,
} from '@svgAsComponents';
import { Stack } from '@mui/material';
import { ColumnType } from '@utils';
import { colors } from '@theme';

const menuItems = ({ action }) => [
  {
    action: action('documents'),
    text: 'Documents',
    icon: <DocumentsIconWithIndicator />,
    dataTestName: `requests_queue__table__body__actions__body__documents_gallery__menu_item`,
  },
  {
    action: action('photos'),
    text: 'Progress photos',
    icon: <PhotoGalleryIconWithIndicator color={colors.icons.green} />,
    dataTestName: `requests_queue__table__body__actions__body__photos_gallery__menu_item`,
  },
];

const actions: ColumnType<IProjectsPortfolioItem> = ({
  updateRightDrawer,
  showChecklist,
  isCommentsAvailable,
}) => ({
  field: 'actions',
  graphFields: ['id'],
  graphNested: {
    project: ['status', 'name'],
    comments_preview: ['has_comments', 'has_unread_comments'],
  },
  headerName: 'Actions',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const onIconClick = (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      event['disableRowClick'] = true;
      event.stopPropagation();
      updateRightDrawer({
        title: row.project?.name || tab,
        projectId: row.project?.id,
        requestId: row.id,
        activeTab: tab.toLowerCase(),
      });
    };

    return (
      <Stack flexDirection="row" alignItems="center">
        {showChecklist && (
          <IconButtonWithTooltip
            onClick={onIconClick('Policies')}
            data-cy={`requests_queue__table__body__actions__policies__button`}
            tooltipText="Approval"
          >
            <ChecklistIcon size={24} />
          </IconButtonWithTooltip>
        )}
        <VerticalMenu
          menuItems={menuItems({
            action: (tab: string) => () => {
              updateRightDrawer({
                title: row.project?.name,
                projectId: row.project?.id,
                requestId: row.id,
                activeTab: tab,
              });
            },
          })}
        >
          <IconButtonWithTooltip
            data-cy={`requests_queue__table__body__actions__body__documents_gallery__icon`}
            tooltipText="Upload documents / progress photos"
          >
            <DocumentsIconWithIndicator />
          </IconButtonWithTooltip>
        </VerticalMenu>
        {isCommentsAvailable && (
          <IconButtonWithTooltip
            onClick={onIconClick('Comments')}
            data-cy={`requests_queue__table__body__actions__comments__icon`}
            tooltipText="Comments"
          >
            <CommentIconWithIndicator
              hasComments={row.comments_preview?.has_comments}
              hasUnreadComments={row.comments_preview?.has_unread_comments}
            />
          </IconButtonWithTooltip>
        )}
      </Stack>
    );
  },
});

export default actions;
