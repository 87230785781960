import React, { FC, memo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { MilestoneListColumnTypeV2 } from './common';
import { DrawerContext } from '../drawer';
import { CommonRowType, RequestTableTabsEnum } from '@interfaces';
import { PhotoGalleryIconWithIndicator } from '@svgAsComponents';
import { colors } from '@theme';

const InspectionPhotosV2: FC<{ row: CommonRowType }> = ({ row }) => {
  const { inspectionId, serviceOrderId } = useParams();
  const { updateRightDrawer, source } = useContext(DrawerContext);

  return (
    <IconButton
      data-cy={`${source}__body__inspection_photos_gallery__icon`}
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneSubmitId: row?.milestone_submit_id,
              milestoneName: row?.name,
              inspectionId: inspectionId,
              serviceOrderId: serviceOrderId,
              tab: RequestTableTabsEnum.PHOTOS,
            })
          : row?.updateRightDrawer?.({ tab: RequestTableTabsEnum.PHOTOS })
      }
    >
      <PhotoGalleryIconWithIndicator
        color={colors.icons.green}
        hasImages={row.documents_preview?.has_photos}
        hasUnseenImages={row.documents_preview?.has_not_seen_photos}
      />
    </IconButton>
  );
};

const Memoized = memo(InspectionPhotosV2);

const inspectionPhotosV2: MilestoneListColumnTypeV2 = {
  field: 'inspection_photos',
  headerAlign: 'center',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default inspectionPhotosV2;
