import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const project_type: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'project_type',
  graphFields: ['project_type'],
  headerName: 'Project type',
  minWidth: 130,
  groupable: false,
  aggregable: false,
  flex: 1,
});

export default project_type;
