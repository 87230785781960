import React from 'react';
import { isInactiveProject, ColumnType } from '@utils';
import { VisibilityOnIcon } from '@svgAsComponents';
import { IconButtonWithTooltip } from '@components';
import { IProjectsPortfolioItem } from '@interfaces';
import { colors } from '@theme';

const is_watching: ColumnType<IProjectsPortfolioItem> = ({ onIconClick }) => ({
  field: 'is_watching',
  graphFields: ['is_watching', 'id'],
  headerName: 'Watch',
  minWidth: 50,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: ({ row }) => {
    const isInactiveProjects = isInactiveProject(row?.original?.status);

    return (
      <IconButtonWithTooltip
        tooltipText={row?.original?.is_watching ? 'Unwatch' : 'Watch'}
        onClick={(e) => {
          event['disableRowClick'] = true;
          e.stopPropagation();
          onIconClick(row.is_watching, row.id);
        }}
        disabled={isInactiveProjects}
      >
        <VisibilityOnIcon
          size={24}
          color={row?.original?.is_watching ? colors.status.information.medium : colors.icons.gray}
        />
      </IconButtonWithTooltip>
    );
  },
});

export default is_watching;
