import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, currencyFormatter } from '@utils';

const loan_loc_commitment: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'loan.loc_commitment',
  graphNested: {
    loan: ['loc_commitment'],
  },
  headerName: 'Loan commitment',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) => row?.loan?.loc_commitment,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default loan_loc_commitment;
