import React from 'react';
import { useProjectList } from './useProjectList';
import { TableKeyEnum } from '@interfaces';
import { Stack } from '@mui/material';
import DataTableV3 from '../DataTableV3';
import DrawRequestsListFilters from '../DrawRequestsListFilters';
import Filters from '../Filters';
import TablePaginationNew from '../TablePaginationNew';

const ProjectsListV2 = () => {
  const {
    isLoading,
    page,
    rowsPerPage,
    count,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPageOptions,
    onFiltersReady,
    formattedData,
    setFilterStringQuery,
    filters,
    columns,
  } = useProjectList();
  const handleRowClick = console.log;

  return (
    <DataTableV3
      checkboxSelection
      controllerLoading={isLoading}
      withoutAdaptiveScroll
      tableKey={TableKeyEnum.RISK_RADAR}
      onRowClick={handleRowClick}
      withBuiltInSearch={false}
      headerRight={[]}
      footerRight={[
        {
          Component: (
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2, pr: 2 }}>
              <TablePaginationNew
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                source="all_services__list"
              />
            </Stack>
          ),
        },
      ]}
      headerLeft={[
        {
          Component: (
            <Filters
              padding={0}
              onReady={onFiltersReady}
              tableKey={TableKeyEnum.REQUESTS}
              source="requests"
              setFilterStringQuery={setFilterStringQuery}
              LeftComponent={DrawRequestsListFilters}
              filters={filters}
              onPageChange={onPageChange}
            />
          ),
        },
      ]}
      rows={formattedData}
      columns={columns}
    />
  );
};

export default ProjectsListV2;
