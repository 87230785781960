import { useMemo } from 'react';
import find from 'lodash/find';
import { useGraphQuery } from '@context';
import { QueryNamesEnums } from '@interfaces';

interface UseMilestoneTagsFilterProps {
  tagFilterValue?: string;
}

interface UseMilestoneTagsFilterReturn {
  milestoneTagsOptions: string[];
  tagFilterId?: string;
}

export const useMilestoneTagsFilter = ({
  tagFilterValue,
}: UseMilestoneTagsFilterProps): UseMilestoneTagsFilterReturn => {
  const milestoneTagsQuery = useGraphQuery({
    type: QueryNamesEnums.GET_MILESTONE_TAGS,
    keys: ['id', 'name'],
    args: {},
  });

  const milestoneTagsOptions = useMemo(
    () => ['All', ...(milestoneTagsQuery.data?.results?.map((tag) => tag.name) || [])],
    [milestoneTagsQuery.data],
  );

  const tagFilterId = useMemo(() => {
    return find(milestoneTagsQuery.data?.results, { name: tagFilterValue })?.id;
  }, [tagFilterValue, milestoneTagsQuery.data]);

  return {
    milestoneTagsOptions,
    tagFilterId,
  };
};
