import React from 'react';
import { Stack } from '@mui/material';
import { IconButtonWithTooltip } from '@components';
import { CommentIconWithIndicator, DocumentsIconWithIndicator } from '@svgAsComponents';
import { getServiceTypeDisplayName, isAllowed, ColumnType, checkIsInvestor } from '@utils';
import { PermissionNamesEnums, TeamRolesEnum } from '@interfaces';

interface ServiceDocumentsAndCommentsProps {
  updateRightDrawer: (params: {
    projectId?: string;
    requestId?: string;
    serviceOrderId?: string;
    activeTab?: string;
    title?: string;
  }) => void;
  source: string;
  permissions: any;
  settings: any;
  teamRole: TeamRolesEnum;
}

const documentsAndComments: ColumnType = ({
  updateRightDrawer,
  permissions,
  settings,
  source,
  teamRole,
}: ServiceDocumentsAndCommentsProps) => ({
  field: 'documents_and_comments',
  graphFields: ['comments_preview', 'documents_preview', 'service_type'],
  headerName: '',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const serviceTypesMap = settings?.display?.service_types;
    // INVESTOR can't see tab Services in message panel, so we need to hide comments icon here
    const isCommentsAvailable =
      isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions) && !checkIsInvestor(teamRole);

    const serviceType = getServiceTypeDisplayName({
      serviceTypesMap,
      serviceType: row?.service_type,
    });
    const onIconClick = (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      event['disableRowClick'] = true;
      updateRightDrawer({
        title: serviceType || row?.project?.name || tab,
        projectId: row?.project?.id,
        requestId: row?.draw_request?.id,
        serviceOrderId: row?.id,
        activeTab: tab.toLowerCase(),
      });
    };
    return (
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconButtonWithTooltip
          onClick={onIconClick('Documents')}
          data-cy={`${source}__documents__icon`}
          tooltipText="Upload documents / progress photos"
        >
          <DocumentsIconWithIndicator
            hasDocuments={row.documents_preview?.has_docs || row.documents_preview?.has_photos}
            hasUnreadDocuments={
              row.documents_preview?.has_not_seen_docs || row.documents_preview?.has_not_seen_photos
            }
          />
        </IconButtonWithTooltip>
        {isCommentsAvailable && (
          <IconButtonWithTooltip
            onClick={onIconClick('Comments')}
            data-cy={`requests_queue__table__body__actions__comments__icon`}
            tooltipText="Comments"
          >
            <CommentIconWithIndicator
              hasComments={row.comments_preview?.has_comments}
              hasUnreadComments={row.comments_preview?.has_unread_comments}
            />
          </IconButtonWithTooltip>
        )}
      </Stack>
    );
  },
});

export default documentsAndComments;
