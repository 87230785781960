import { useMemo } from 'react';
import { useGraphQuery } from '@context';
import {
  QueryNamesEnums,
  ServiceOrderStatusEnum,
  ServiceTypeEnum,
  PermissionNamesEnums,
  IPermission,
  IServiceOrder,
} from '@interfaces';
import { isCancelRequestedService, isAllowed } from '@utils';
import { TOOLTIP_TEXTS } from '@constants';

interface UseLastActiveProjectInspectionProps {
  projectId: string;
  permissions: IPermission[];
}

interface UseLastActiveProjectInspectionResult {
  inProgressInspection: Pick<IServiceOrder, 'id' | 'status'>;
  isInspectionCancelRequested: boolean;
  disabledInspectionTooltipText: string;
}

export const useLastActiveProjectInspection = ({
  projectId,
  permissions,
}: UseLastActiveProjectInspectionProps): UseLastActiveProjectInspectionResult => {
  const projectActiveInspectionQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS,
    keys: ['id', 'status'],
    args: {
      project_id: projectId,
      excluded_status: [
        ServiceOrderStatusEnum.COMPLETED,
        ServiceOrderStatusEnum.CREATED,
        ServiceOrderStatusEnum.CANCELLED,
        ServiceOrderStatusEnum.ERROR,
      ].join(','),
      service_type: ServiceTypeEnum.INSPECTION,
    },
    options: {
      skip: !isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  });

  const inProgressInspection = useMemo(
    () => projectActiveInspectionQuery.data?.results?.[0],
    [projectActiveInspectionQuery.data?.results],
  );

  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedService(inProgressInspection?.status),
    [inProgressInspection],
  );

  const disabledInspectionTooltipText = useMemo(() => {
    if (isInspectionCancelRequested) return TOOLTIP_TEXTS.inspectionCancelRequested;
    if (inProgressInspection) return TOOLTIP_TEXTS.inspectionOrdered;
    return '';
  }, [isInspectionCancelRequested, inProgressInspection]);

  return {
    inProgressInspection,
    isInspectionCancelRequested,
    disabledInspectionTooltipText,
  };
};
