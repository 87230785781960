import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, currencyFormatter } from '@utils';

const project_funds_original_estimate: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'project_funds.original_estimate',
  graphNested: {
    project_funds: ['original_estimate'],
  },
  headerName: 'Original scheduled value ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) =>
    row?.project_funds?.original_estimate,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default project_funds_original_estimate;
