import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';
import { PermissionsContext, useGraphQuery } from '@context';
import {
  DeleteDrawRequestListItem,
  ErrorDual,
  IDrawRequestFundingSource,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestTableTabTypesEnum,
} from '@interfaces';
import {
  deleteRequestMilestoneItem,
  getDrawRequestSources,
  patchDrawRequestListItem,
} from '@globalService';
import { StringFieldModel, useStringFieldModel } from '@models';
import { costTypeMap } from '@constants';
import { getCustomFundingSources, isAllowed, parsePathErrorDual } from '@utils';
import { useSafeSnackbar } from '@hooks';

export interface ControllerInterface {
  itemName: StringFieldModel;
  revisedScheduledValue: {
    prevValue: number;
    nextValue: number;
  };
  costType: string;
  costTypeOptions: { value: string; label: string }[];
  handleCostTypeChange: (event: ChangeEvent<{ value: string }>) => void;
  submit: () => void;
  isSubmiting: boolean;
  deleteLineItem: () => void;
  isEditable: boolean;
  comment: StringFieldModel;
  customSources: IDrawRequestFundingSource[];
  isUpdating: boolean;
  handleFundingSourceChange: (
    source: IDrawRequestFundingSource,
    value: string,
    isValid: boolean,
  ) => void;
  isSubmitDisabled: boolean;
}

export interface BreakdownControllerProps {
  drawRequestId: string;
  milestoneId: string;
  setOpen: CallableFunction;
  type: RequestTableTabTypesEnum;
}

export const useLineItemBreakdownPanel = ({
  drawRequestId,
  milestoneId,
  setOpen,
}: BreakdownControllerProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);

  const costTypeOptions = [
    { value: 'HARD', label: costTypeMap.HARD },
    { value: 'SOFT', label: costTypeMap.SOFT },
  ];

  const lineItem = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: [
      'id',
      'name',
      'cost_type',
      'revised_estimate',
      'requested_revised_estimate',
      'milestone_is_new_for_current_draw',
      'budget_changes',
    ],
    args: { projectId, drawRequestId, milestoneId },
  });

  const [fromSources, setFromSources] = useState<{
    [key: string]: { value: number; isValid: boolean };
  }>({});

  const drawRequestFundingSourcesQuery = useQuery<{ results: IDrawRequestFundingSource[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES, { projectId: projectId, drawRequestId }],
    getDrawRequestSources.bind(this, projectId, drawRequestId),
    { enabled: Boolean(projectId) },
  );

  const customSources = useMemo(
    () =>
      getCustomFundingSources(
        drawRequestFundingSourcesQuery.data?.results || [],
      ) as IDrawRequestFundingSource[],
    [drawRequestFundingSourcesQuery.data?.results],
  );

  useEffect(() => {
    setFromSources(
      customSources.reduce(
        (acc, source) => ({ ...acc, [source.id]: { value: 0, isValid: true } }),
        {},
      ),
    );
  }, [customSources]);

  const [costType, setCostType] = useState(lineItem?.data?.cost_type?.['key'] || 'HARD');

  const itemName = useStringFieldModel({
    initValue: lineItem?.data?.name || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
    withProgressCheck: true,
  });

  const isEditable = useMemo(
    () =>
      lineItem.data?.milestone_is_new_for_current_draw &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, lineItem.data],
  );

  const handleCostTypeChange = (event) => {
    setCostType(event.target.value);
  };

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_V2,
        { project_id: projectId, draw_request_id: drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId: drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES,
        { projectId, drawRequestId },
      ]);
      setOpen(false);
    },
  });

  const handleFundingSourceChange = (
    source: IDrawRequestFundingSource,
    value: string,
    isValid: boolean,
  ) => {
    setFromSources((prev) => ({
      ...prev,
      [source.id]: { value: +value, isValid },
    }));
  };

  const submit = useCallback(() => {
    const isValid = Object.values(fromSources).every((source) => source.isValid);
    if (!isValid) {
      return;
    }
    const jsonData = {
      sources: Object.entries(fromSources).map(([key, value]) => ({
        funding_source: key,
        amount: value.value,
      })),
    };

    patchMilestoneRequestMutation.mutateAsync({
      project: projectId,
      drawRequest: drawRequestId,
      milestone: milestoneId,
      json: jsonData,
    });
  }, [drawRequestId, projectId, milestoneId, itemName.value, costType, fromSources]);

  const deleteMilestoneMutation = useMutation<Response, ErrorDual, DeleteDrawRequestListItem>(
    deleteRequestMilestoneItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_V2,
          { project_id: projectId, draw_request_id: drawRequestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_MILESTONES);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSettled: () => {
        setOpen(false);
      },
    },
  );

  const deleteLineItem = useCallback(() => {
    deleteMilestoneMutation.mutateAsync({
      project: projectId,
      drawRequest: drawRequestId,
      milestone: lineItem?.data?.id,
    });
  }, [lineItem?.data?.id, projectId, drawRequestId]);

  const isMutating = useIsMutating();
  return {
    itemName,
    revisedScheduledValue: {
      prevValue: lineItem?.data?.revised_estimate || 0,
      nextValue:
        (lineItem?.data?.revised_estimate || 0) +
        Object.values(fromSources).reduce((acc, source) => acc + +source.value, 0),
    },
    costType,
    costTypeOptions,
    handleCostTypeChange,
    submit,
    isSubmiting: isMutating > 0,
    deleteLineItem,
    isEditable,
    comment: useStringFieldModel({
      initValue: '',
      validationRule: (value) => Boolean(value?.trim()),
      validateOnChange: true,
    }),
    customSources,
    isUpdating: isMutating > 0,
    handleFundingSourceChange,
    isSubmitDisabled: Object.values(fromSources).some((source) => !source.isValid),
  };
};
