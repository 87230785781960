import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';

import { useUpdateUiSettings } from '@hooks';
import { SettingsContext } from '@context';
import { LineItemFilterValues, MS_FILTER_OPTIONS } from '@constants';
import { MutationKeyEnum, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import find from 'lodash/find';

interface HookInterface {
  filterValue: string;
  setFilterValue: Dispatch<React.SetStateAction<string>>;
  handleFilterClick: (value: string) => void;
  isFilterUpdating?: boolean;
  defaultOptions: { totalKey: string; filterValue: string }[];
  isMilestoneMutatingOrFetching: boolean;
  filterKey: string;
}

interface PropsInterface {
  tableKey?: string;
  defaultState?: string;
  accessKey?: string;
}

export const useLineItemsFilter = ({
  tableKey,
  defaultState,
  accessKey = 'filters',
}: PropsInterface): HookInterface => {
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);
  const savedState = useMemo(
    () => settings.personal_setting?.tables?.[tableKey]?.[accessKey]?.line_items,
    [tableKey, settings, accessKey],
  );
  const [filterValue, setFilterValue] = useState<string>(savedState || defaultState || null);

  useEffect(() => {
    savedState && savedState !== filterValue && setFilterValue(savedState);
  }, [savedState]);

  const handleFilterClick = useCallback(
    (value) => {
      setFilterValue(value);
      if (tableKey)
        updateSettings({
          personal_setting: {
            tables: {
              ...settings.personal_setting?.tables,
              [tableKey]: {
                ...settings.personal_setting?.tables?.[tableKey],
                [accessKey]: {
                  ...settings.personal_setting?.tables?.[tableKey]?.[accessKey],
                  line_items: value,
                },
              },
            },
          },
        });
    },
    [settings, tableKey, accessKey],
  );

  const isMilestoneMutating = Boolean(
    useIsMutating({
      predicate: ({ options }) => options.mutationKey === MutationKeyEnum.MILESTONE_PATCH,
    }),
  );

  const isFetchingRequest = Boolean(
    useIsFetching({
      predicate: ({ queryKey }: any) =>
        [
          QueryNamesEnums.GET_DRAW_REQUEST,
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          QueryNamesEnums.GET_DRAW_REQUEST_TOTALS,
        ].includes(queryKey[0]),
    }),
  );

  const filterKey = useMemo(
    () => find(MS_FILTER_OPTIONS, { filterValue })?.filterKey || 'all',
    [filterValue],
  );

  return {
    filterValue,
    handleFilterClick,
    defaultOptions:
      tableKey === TableKeyEnum.PHOTOS
        ? [...MS_FILTER_OPTIONS, LineItemFilterValues.HAS_PHOTOS]
        : MS_FILTER_OPTIONS,
    setFilterValue,
    isMilestoneMutatingOrFetching: isMilestoneMutating || isFetchingRequest,
    filterKey,
  };
};
