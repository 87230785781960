import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ErrorDual,
  IFilterOption,
  IInspection,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  MutationKeyEnum,
  PatchInspectionMSGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  getInspectionMilestoneGroups,
  getProjectInspectionById,
  patchInspectionMSroup,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  getActivePHBGrouping,
  getItemLocalHighlight,
  getPHBTableItemsByActiveView,
  getPHBViewTypes,
  getTypeFilterValue,
  isCurrentPHBViewLoading,
  isLineItemView,
  isModelView,
  isUnitView,
  parsePathErrorDual,
  replaceMilestoneData,
  setDefaultPHBView,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import { useSafeSnackbar, useUpdateUiSettings, useUrlParams } from '@hooks';
import cloneDeep from 'lodash/cloneDeep';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { find } from 'lodash';

export type ControllerInterface = {
  initColumns: string[];
  tableItems: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  patchInspectionMSgroup: (params) => void;
  isLoading: boolean;
  totals: IMilestoneTotal;
  activeView: string;
  setActiveView: (state: string) => void;
  viewTypes: { label: string; value: string }[];
  isLineItemsView: boolean;
  typeFilterValues: string[];
  setTypeFilterValues: Dispatch<SetStateAction<string[]>>;
};

export const useInspectionTable = (
  showRequestedAmount = false,
  isEditable = false,
): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const [unitsTableItems, setUnitsTableItems] = useState<IPHBTableItem[]>([]);
  const [lineItemsTableItems, setLineItemsTableItems] = useState<IPHBTableItem[]>([]);
  const [modelsTableItems, setModelsTableItems] = useState<IPHBTableItem[]>([]);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { inspectionId, projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const query = '{*}';
  const { unitName, modelName } = usePHBNaming();
  const viewTypes = useMemo(() => getPHBViewTypes({ unitName, modelName }), [unitName, modelName]);
  const [typeFilterValues, setTypeFilterValues] = React.useState<string[]>([
    'is_horizontal=true',
    'is_vertical=true',
  ]);
  const typeFilterValue = useMemo(() => getTypeFilterValue(typeFilterValues), [typeFilterValues]);

  const [activeView, setActiveView] = useUrlParams(
    settings.personal_setting?.PHB_TABLE_VIEW?.view_type || viewTypes[0].value,
    'view',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    setDefaultPHBView({
      settings,
      isFirstLoad,
      setIsFirstLoad,
      viewTypes,
      activeView,
      setActiveView,
    });
  }, [settings.personal_setting?.PHB_TABLE_VIEW?.view_type, isFirstLoad, activeView]);
  const isLineItemsView = useMemo(() => isLineItemView(activeView), [activeView]);
  const isModelsView = useMemo(() => isModelView(activeView), [activeView]);
  const isUnitsView = useMemo(() => isUnitView(activeView), [activeView]);

  const { unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping } = usePHBGrouping();
  const { filterValue, handleFilterClick, filterOptions, filterKey } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
  });
  const activeGroupBy = useMemo(
    () =>
      getActivePHBGrouping({
        activeView,
        unitLineItemGrouping,
        lineItemUnitGrouping,
        modelUnitsGrouping,
      }),
    [activeView, unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping],
  );

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
    isModelLevel = false,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
    isModelLevel?: boolean;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: item?.revised_estimate > 0,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: !isModelLevel && item?.milestone_groups?.length > 0,
    canAddPhotos: isEditable,
    isExpanded,
    isNested,
    index,
  });

  const unitsNestedTableProps = useExpandCollapseTable({
    setListItems: setUnitsTableItems,
    createTableObject,
  });

  const lineItemsNestedTableProps = useExpandCollapseTable({
    setListItems: setLineItemsTableItems,
    createTableObject,
  });

  const queryInspectionFields = useMemo(() => {
    const key = find(filterOptions, { filterValue })?.totalKey;
    const query = `{${[
      'status',
      'inspection_agency',
      `totals{${key}{previous_inspector_allowance_rate,inspector_allowance_rate,requested_amount,revised_estimate,borrower_completion_rate}}`,
    ].join(',')}}`;
    return query;
  }, [filterValue, filterOptions]);

  const inspectionQuery = useQuery<IInspection, Error>(
    [
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      { projectId, inspectionId, query: queryInspectionFields },
    ],
    getProjectInspectionById.bind(this, { projectId, inspectionId, query: queryInspectionFields }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );

  const unitsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getInspectionMilestoneGroups.bind(this, {
      projectId,
      inspectionId,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );

  const modelsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        groupBy: modelUnitsGrouping,
        filterKey,
      },
    ],
    getInspectionMilestoneGroups.bind(this, {
      projectId,
      inspectionId,
      groupBy: modelUnitsGrouping,
      filterKey,
    }),
    { enabled: Boolean(inspectionId && projectId && modelUnitsGrouping) },
  );

  const lineItemsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ],
    getInspectionMilestoneGroups.bind(this, {
      projectId,
      inspectionId,
      groupBy: lineItemUnitGrouping,
      typeKeys: typeFilterValue,
    }),
    { enabled: Boolean(inspectionId && projectId && lineItemUnitGrouping) },
  );

  const invalidateAfterLineItemPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_INSPECTION_MILESTONES,
          {
            projectId,
            inspectionId,
            groupBy: lineItemUnitGrouping,
            typeKeys: typeFilterValue,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        filterKey,
        groupBy: unitLineItemGrouping,
      },
    ]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        filterKey,
        groupBy: modelUnitsGrouping,
      },
    ]);
  };

  const invalidateAfterUnitPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_INSPECTION_MILESTONES,
          {
            projectId,
            inspectionId,
            query,
            filterKey,
            groupBy: unitLineItemGrouping,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        filterKey,
        groupBy: modelUnitsGrouping,
      },
    ]);
  };

  const invalidateAfterModelPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_INSPECTION_MILESTONES,
          {
            projectId,
            inspectionId,
            query,
            filterKey,
            groupBy: modelUnitsGrouping,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        filterKey,
        groupBy: unitLineItemGrouping,
      },
    ]);
  };

  const patchPHBMilestoneInspectionMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchInspectionMSGroupParam
  >(patchInspectionMSroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async (data) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId },
      ]);
      if (isLineItemsView) {
        await invalidateAfterLineItemPatch(data);
        lineItemsNestedTableProps.updateListItemsWithParentGroup(data);
        setUnitsTableItems(null);
        setModelsTableItems(null);
      }
      if (isUnitsView) {
        await invalidateAfterUnitPatch(data);
        unitsNestedTableProps.updateListItemsWithParentGroup(data);
        setLineItemsTableItems(null);
        setModelsTableItems(null);
      }
      if (isModelsView) {
        await invalidateAfterModelPatch(data);
        setModelsTableItems(
          (old) =>
            replaceMilestoneData({
              milestones: { results: old },
              milestoneId: data.id,
              json: data,
            })?.results,
        );
        setUnitsTableItems(null);
        setLineItemsTableItems(null);
      }
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  useEffect(() => {
    setUnitsTableItems(null);
    setModelsTableItems(null);
  }, [filterValue, inspectionId]);

  useEffect(() => {
    setLineItemsTableItems(null);
  }, [typeFilterValues, inspectionId]);

  useEffect(() => {
    if (!unitsQuery.data?.results || unitsTableItems?.length) return;

    const clonedMilestones = cloneDeep(unitsQuery.data.results);
    setUnitsTableItems(clonedMilestones.map((item, index) => createTableObject({ item, index })));
  }, [unitsQuery.data?.results, unitsTableItems]);

  useEffect(() => {
    if (!lineItemsQuery.data?.results || lineItemsTableItems?.length) return;

    const clonedMilestones = cloneDeep(lineItemsQuery.data.results);
    setLineItemsTableItems(
      clonedMilestones.map((item, index) => createTableObject({ item, index })),
    );
  }, [lineItemsQuery.data?.results, lineItemsTableItems]);

  useEffect(() => {
    if (!modelsQuery.data?.results || modelsTableItems?.length) return;
    const clonedMilestones = cloneDeep(modelsQuery.data?.results);
    setModelsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) =>
        createTableObject({ item, index, isModelLevel: true }),
      ),
    );
  }, [modelsQuery.data?.results, modelsTableItems]);

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'name',
      'inspectorAllowanceRate',
      ...(flags?.['ENG_9723_borrower_completion_rate_for_PHB_inspection'] && showRequestedAmount
        ? ['borrowerCompletionRate']
        : []),
      ...(showRequestedAmount ? ['requestedAmount'] : []),
      'revisedEstimate',
      'inspectionPhotoUploader',
      'inspectionPhotosV2',
      'comments',
    ],
    [showRequestedAmount, flags?.['ENG_9723_borrower_completion_rate_for_PHB_inspection']],
  );

  const patchInspectionMSgroup = useCallback(
    (params) => {
      return patchPHBMilestoneInspectionMutation.mutate({
        projectId,
        inspectionId,
        group_by: activeGroupBy,
        json: params.json,
        milestoneId: params.milestoneId,
      });
    },
    [inspectionId, projectId, activeGroupBy, patchPHBMilestoneInspectionMutation],
  );

  const totals = useMemo(() => {
    const inspection = inspectionQuery.data;
    return {
      previous_inspector_allowance_rate: inspection?.totals?.all?.previous_inspector_allowance_rate,
      inspector_allowance_rate: inspection?.totals?.all?.inspector_allowance_rate,
      requested_amount: inspection?.totals?.all?.requested_amount,
      revised_estimate: inspection?.totals?.all?.revised_estimate,
      borrower_completion_rate: inspection?.totals?.all?.borrower_completion_rate,
      displayAll: true,
    };
  }, [inspectionQuery.data]);

  const tableItems = useMemo(
    () =>
      getPHBTableItemsByActiveView({
        unitsTableItems,
        modelsTableItems,
        lineItemsTableItems,
        activeView,
      }),
    [lineItemsTableItems, unitsTableItems, modelsTableItems, activeView],
  );

  const isCurrentViewFetching = useMemo(
    () => isCurrentPHBViewLoading({ activeView, lineItemsQuery, unitsQuery, modelsQuery }),
    [activeView, lineItemsQuery, unitsQuery, modelsQuery],
  );

  const isLoading = useMemo(
    () => showLoadingSkeleton || isCurrentViewFetching,
    [showLoadingSkeleton, isCurrentViewFetching],
  );

  return {
    initColumns,
    tableItems,
    onExpandClick: isLineItemsView
      ? lineItemsNestedTableProps.onExpandClick
      : unitsNestedTableProps.onExpandClick,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    isLoading,
    patchInspectionMSgroup,
    totals: isLineItemsView ? undefined : totals,
    activeView,
    setActiveView: (view) => {
      setActiveView(view);
      updateSettings({
        personal_setting: {
          PHB_TABLE_VIEW: { view_type: view },
        },
      });
    },
    viewTypes,
    isLineItemsView,
    typeFilterValues,
    setTypeFilterValues,
  };
};
