import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridEventListener, GridSortModel } from '@mui/x-data-grid';

import {
  getHookState,
  getSortStringByGridSortModel,
  ColumnConfig,
  generateGraphKeys,
  createColumnVisibleModel,
  getTeamRole,
} from '@utils';
import {
  IRightDrawerParams,
  IServiceOrder,
  QueryNamesEnums,
  ServiceOrderStatusEnum,
  TableKeyEnum,
} from '@interfaces';
import { PermissionsContext, SettingsContext, useGraphQuery, AuthContext } from '@context';

import {
  useCommentsAndDocumentsPreview,
  useDayJsFormatter,
  useRightMenu,
  useTablePagination,
  useUserTableSettings,
} from '@hooks';
import { ControllerInterface } from './interface';
import { AllServicesColumnsConfig } from '@components';
import { defaultPersonalSetting } from '@constants';

export const useServicesQueue = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const navigate = useNavigate();

  const { dateFormatter } = useDayJsFormatter();
  const { settings } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const { userTableSettings } = useUserTableSettings({ tableKey: TableKeyEnum.SERVICES_LIST });

  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([]);
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination({});

  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId: rightDrawerParams.projectId,
    drawRequestId: rightDrawerParams.requestId,
    inspectionId: rightDrawerParams.inspectionId,
    serviceOrderId: rightDrawerParams.serviceOrderId,
  });

  const onRightDrawerClose = useCallback(() => {
    updateCommentsPreviewInfo();
    setRightDrawerParams((old) => ({ ...old, activeTab: '' }));
  }, [updateCommentsPreviewInfo]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: onRightDrawerClose,
  });
  const updateRightDrawer = ({
    title,
    projectId,
    requestId,
    inspectionId,
    serviceOrderId,
    activeTab,
  }: IRightDrawerParams) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, requestId, inspectionId, serviceOrderId, activeTab });
  };

  // ! TODO: check this
  // const sortKeysMap = {
  //   'Date ordered': 'ordered_at',
  //   'project.status': 'status',
  // };

  const columnsToFetch = useMemo(() => {
    const columnsModel = userTableSettings?.columns.columnVisibilityModel || {
      ...createColumnVisibleModel(
        AllServicesColumnsConfig.map((column) => column({}).field),
        true,
      ),
      ...createColumnVisibleModel(
        defaultPersonalSetting.tables[TableKeyEnum.PROJECT_SERVICES]?.hidden_columns || [],
      ),
    };
    if (columnsModel) return Object.keys(columnsModel).filter((key) => columnsModel[key]);
    return [];
  }, [userTableSettings?.columns.columnVisibilityModel]);

  const queryTest = useMemo(
    () =>
      generateGraphKeys<IServiceOrder>(
        columnsToFetch?.map((column) =>
          AllServicesColumnsConfig.map((column) => column({})).find(
            (config) => config.field === column,
          ),
        ),
      ),
    [columnsToFetch],
  );

  const servicesListCustomRules = useCallback(
    (column: ColumnConfig<IServiceOrder>) => {
      return column.enableByPermissions ? column.enableByPermissions(permissions) : true;
    },
    [permissions],
  );

  const servicesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_SERVICE_ORDERS_LIST,
    keys: queryTest.keys as (keyof IServiceOrder)[],
    nested: queryTest.nested,
    args: {
      excluded_status: ServiceOrderStatusEnum.CREATED,
      pagination: {
        page,
        rowsPerPage,
      },
      sorting: getSortStringByGridSortModel(tableSortingModel),
      filter: filterStringQuery,
    },
    options: {
      skip: !columnsToFetch?.length || !filtersReady,
    },
  });

  const columns = useMemo(
    () =>
      AllServicesColumnsConfig.map((column) =>
        column({
          updateRightDrawer,
          serviceTypesMap,
          settings,
          dateFormatter,
          permissions,
          teamRole,
        }),
      ).filter(servicesListCustomRules),
    [
      servicesListCustomRules,
      updateRightDrawer,
      serviceTypesMap,
      settings,
      dateFormatter,
      permissions,
      teamRole,
    ],
  );

  const handleRowClick: GridEventListener<'rowClick'> = (
    params,
    event: Parameters<GridEventListener<'rowClick'>>[1] & { disableRowClick?: boolean },
  ) => {
    if (event.disableRowClick) return;
    navigate(`/projects/${params.row?.project?.id}/service/${params.row?.id}`);
  };

  return {
    state: getHookState(servicesQuery),
    rows: servicesQuery?.data?.results || [],
    columns,
    onFiltersReady: () => setFiltersReady(true),
    onSortModelChange: setTableSortingModel,
    setFilterStringQuery,
    isLoading: servicesQuery.isFetching,
    handleRowClick,
    paginationProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onPageChange,
      onRowsPerPageChange,
      itemsCount: servicesQuery?.data?.count,
    },
    rightDrawerParams,
    rightMenu,
  };
};
