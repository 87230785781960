// TODO: delete after ENG_9657_show_inspections_in_services
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { ButtonWithTooltip, Popup, UserFocusRequestCreateDialog, VerticalMenu } from '@components';
import { TOOLTIP_TEXTS } from '@constants';
import { PopupTypeEnum } from '@interfaces';
import { getTooltipText } from '@utils';
import { useServicesButton } from './controller';
import { useParams } from 'react-router-dom';

const ServicesButton = () => {
  const { projectId } = useParams();
  const {
    menuItems,
    isLoading,
    isSubmitting,
    isCurrentProjectArchived,
    infoModalText,
    closeInfoModal,
    openDraft,
    isCurrentProjectActive,
    createModalVisible,
    setCreateModal,
    createRequest,
    continueDraftButtonLabel,
    disabledButton,
    disabledTooltipText,
    isOwner,
  } = useServicesButton();

  return (
    <span data-tour="add_request_icon">
      <Stack direction="row" spacing={2}>
        {continueDraftButtonLabel && (
          <ButtonWithTooltip
            disabled={!isCurrentProjectActive}
            tooltipText={getTooltipText({ nonActiveProject: !isCurrentProjectActive })}
            onClick={openDraft}
            dataTestName="project__continue_draft__button"
            variant={isOwner ? undefined : 'new'}
            color={isOwner ? undefined : 'secondary'}
          >
            {continueDraftButtonLabel}
          </ButtonWithTooltip>
        )}
        {Boolean(menuItems.length) && (
          <>
            {isOwner ? (
              <ButtonWithTooltip
                onClick={() => setCreateModal(true)}
                tooltipText={disabledTooltipText}
                fullWidth={true}
                loading={isLoading}
                disabled={disabledButton}
                dataTestName="project__add_request__button"
              >
                {isSubmitting ? 'Adding request...' : 'Add request'}
              </ButtonWithTooltip>
            ) : (
              <VerticalMenu menuItems={menuItems} disabled={isCurrentProjectArchived || isLoading}>
                <ButtonWithTooltip
                  tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
                  fullWidth={true}
                  loading={isLoading}
                  disabled={isCurrentProjectArchived}
                  dataTestName="project__add_request__button"
                >
                  {isSubmitting ? 'Adding request...' : 'Add request'}
                </ButtonWithTooltip>
              </VerticalMenu>
            )}
            <Popup open={Boolean(infoModalText)} type={PopupTypeEnum.ERROR} title="Delete request">
              <Stack alignItems="center" sx={{ width: '100%' }}>
                <Stack alignItems="center" mb={7.5} sx={{ whiteSpace: 'pre-line' }}>
                  <Typography textAlign="center" variant="body2">
                    {infoModalText}
                  </Typography>
                </Stack>
                <Button variant="new" color="secondary" onClick={closeInfoModal}>
                  Got it
                </Button>
              </Stack>
            </Popup>
            {createModalVisible && (
              <UserFocusRequestCreateDialog
                onClose={() => setCreateModal(false)}
                createRequest={createRequest}
                projectId={projectId}
                source="project"
              />
            )}
          </>
        )}
      </Stack>
    </span>
  );
};

export default ServicesButton;
