import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { HookState, PermissionNamesEnums } from '@interfaces';
import {
  DisbursementStatement,
  DrawRequestStatusChip,
  FullScreenDialog,
  IconButtonWithTooltip,
  LabelAndValue,
  LabelAndValueWithPreload,
  LoadingSkeleton,
  ProgressWithArrow,
  ServiceMessage,
  StyledBox,
} from '@components';
import { PaymentSummaryModalDataEnum, useRequestPaymentSummary } from './controller';
import { currencyFormatter, isCreatedProject, WithPermission } from '@utils';
import { EditIcon } from '@svgAsComponents';
import FeesEditPopup from '../FeesEditPopup';
import { colors } from '@theme';
import { TransactionDateEditPopup } from '../TransactionDateEditPopup';
import { TransactionIdEditPopup } from '../TransactionIdEditPopup';
import { useLaunchDarklyFlags } from '@context';
import { RequestPaymentEditPopup } from '..';

const DialogActionButtons = () => (
  <Button
    onClick={window.print}
    variant="new"
    color="secondary"
    data-cy="payments_tab__disbursement_statement__print__button"
  >
    Print
  </Button>
);

const PaymentSummary: FC = () => {
  const { drawRequestId } = useParams();
  const {
    state,
    borrowerEquity,
    constructionHoldback,
    feesAmount,
    requestProportionText,
    drawRequest,
    progressValues,
    retainageRate,
    isInReview,
    transactionDate,
    transactionId,
    paymentSummaryModalData,
    setPaymentSummaryModalData,
    canEditFees,
    canEditProportion,
    showDisbursement,
    setShowDisbursement,
    isRequestApprovedOrCompleted,
    handleSubmit,
    transactionDateField,
    transactionIdField,
    isLoading,
  } = useRequestPaymentSummary();
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.LOADING: {
      return (
        <>
          <StyledBox>
            <Skeleton />
          </StyledBox>
          <Box mb={2} />
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="request payment summary" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={2}>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <LabelAndValue
                label="Draw proportion"
                text={requestProportionText}
                icon={
                  canEditProportion ? (
                    <IconButtonWithTooltip
                      tooltipText="Edit"
                      sx={{ py: 0 }}
                      data-cy="payments_tab__draw_proportion__edit__icon"
                      onClick={() =>
                        setPaymentSummaryModalData(PaymentSummaryModalDataEnum.PROPORTION)
                      }
                    >
                      <EditIcon size={24} />
                    </IconButtonWithTooltip>
                  ) : null
                }
                iconPosition="right"
              />
              <LabelAndValue label="Draw status">
                <DrawRequestStatusChip drawRequest={drawRequest} />
              </LabelAndValue>
              <LabelAndValue
                label="All fees"
                text={currencyFormatter(feesAmount, '-')}
                icon={
                  canEditFees ? (
                    <IconButtonWithTooltip
                      tooltipText="Edit"
                      onClick={() => setPaymentSummaryModalData(PaymentSummaryModalDataEnum.FEES)}
                      sx={{ py: 0 }}
                      data-cy="payments_tab__fees__edit__icon"
                    >
                      <EditIcon size={24} />
                    </IconButtonWithTooltip>
                  ) : null
                }
                textDataTestName="payments_tab__all_fees__value"
                iconPosition="right"
              />
              {isRequestApprovedOrCompleted && (
                <LabelAndValue label="Disbursement statement">
                  <Typography
                    variant="body3SemiBold"
                    sx={{ color: colors.text.link, cursor: 'pointer' }}
                    data-cy="payments_tab__disbursement_statement__link"
                    onClick={() => setShowDisbursement(true)}
                  >
                    Show
                  </Typography>
                </LabelAndValue>
              )}
            </Stack>
          </StyledBox>
          {(!flags?.['ENG_9697_funding_source_payment_tab'] || isRequestApprovedOrCompleted) && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                {!flags?.['ENG_9697_funding_source_payment_tab'] && (
                  <>
                    <LabelAndValue
                      label="Construction holdback"
                      text={currencyFormatter(constructionHoldback, '-')}
                      textDataTestName="payments_tab__construction_holdback__value"
                    />
                    <LabelAndValue
                      label="Total borrower equity for this draw"
                      text={currencyFormatter(borrowerEquity, '-')}
                      textDataTestName="payments_tab__borrower_equity__value"
                    />
                  </>
                )}
                {isRequestApprovedOrCompleted &&
                  (isCreatedProject(drawRequest?.project?.status) ? (
                    <>
                      <LabelAndValueWithPreload
                        label="Transaction ID"
                        text={transactionId || '-'}
                        isLoading={isLoading}
                        icon={
                          <WithPermission
                            permissionKey={PermissionNamesEnums.PAYMENTS_MARK_AS_PAID}
                          >
                            <IconButtonWithTooltip
                              tooltipText="Edit"
                              onClick={() =>
                                setPaymentSummaryModalData(
                                  PaymentSummaryModalDataEnum.TRANSACTION_ID,
                                )
                              }
                              sx={{ p: 0, pl: 1 }}
                              data-cy="payments_tab__transaction_id__edit__icon"
                            >
                              <EditIcon size={20} />
                            </IconButtonWithTooltip>
                          </WithPermission>
                        }
                        textDataTestName="payments_tab__transaction_id__value"
                      />
                      <LabelAndValueWithPreload
                        label="Transaction date"
                        text={transactionDate || '-'}
                        isLoading={isLoading}
                        icon={
                          <WithPermission
                            permissionKey={PermissionNamesEnums.PAYMENTS_MARK_AS_PAID}
                          >
                            <IconButtonWithTooltip
                              tooltipText="Edit"
                              onClick={() =>
                                setPaymentSummaryModalData(
                                  PaymentSummaryModalDataEnum.TRANSACTION_DATE,
                                )
                              }
                              sx={{ p: 0, pl: 1 }}
                              data-cy="payments_tab__transaction_date__edit__icon"
                            >
                              <EditIcon size={20} />
                            </IconButtonWithTooltip>
                          </WithPermission>
                        }
                        textDataTestName="payments_tab__transaction_date__value"
                      />
                    </>
                  ) : (
                    <>
                      <LabelAndValue
                        label="Transaction ID"
                        text={transactionId || '-'}
                        textDataTestName="payments_tab__transaction_id__value"
                      />
                      <LabelAndValue
                        label="Transaction date"
                        text={transactionDate || '-'}
                        textDataTestName="payments_tab__transaction_date__value"
                      />
                    </>
                  ))}
              </Stack>
            </StyledBox>
          )}
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <Typography
                variant="labelSemiBold"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                Requested ➝ Approved
              </Typography>
              <LabelAndValue label="Total draw amount">
                <ProgressWithArrow
                  showProgress
                  prevValue={progressValues.requestedAmount}
                  nextValue={progressValues.approvedAmount}
                  getTextStyleForActive={isInReview}
                  prevValueDataTestName="payments_tab__requested_amount__value"
                  nextValueDataTestName="payments_tab__approved_amount__value"
                />
              </LabelAndValue>
              {Boolean(retainageRate) && (
                <LabelAndValue label={`Retainage balance to date (${retainageRate}%)`}>
                  <ProgressWithArrow
                    showProgress
                    prevValue={progressValues.prevRetainageBalance}
                    nextValue={progressValues.retainageBalance}
                    getTextStyleForActive={isInReview}
                    prevValueDataTestName="payments_tab__requested_retainage__value"
                    nextValueDataTestName="payments_tab__approved_retainage__value"
                  />
                </LabelAndValue>
              )}
            </Stack>
          </StyledBox>
          {paymentSummaryModalData === PaymentSummaryModalDataEnum.FEES && (
            <FeesEditPopup onClose={() => setPaymentSummaryModalData(null)} />
          )}
          {paymentSummaryModalData === PaymentSummaryModalDataEnum.PROPORTION && (
            <RequestPaymentEditPopup
              drawRequestId={drawRequestId}
              onClose={() => setPaymentSummaryModalData(null)}
            />
          )}
          {paymentSummaryModalData === PaymentSummaryModalDataEnum.TRANSACTION_DATE && (
            <TransactionDateEditPopup
              transactionDate={transactionDateField}
              onClose={() => setPaymentSummaryModalData(null)}
              onSave={handleSubmit}
            />
          )}
          {paymentSummaryModalData === PaymentSummaryModalDataEnum.TRANSACTION_ID && (
            <TransactionIdEditPopup
              transactionId={transactionIdField}
              onClose={() => setPaymentSummaryModalData(null)}
              onSave={handleSubmit}
            />
          )}
          <FullScreenDialog
            open={showDisbursement}
            title="Disbursement statement"
            actionButtons={DialogActionButtons}
            content={() => <DisbursementStatement drawRequestId={drawRequestId} />}
            onClose={() => setShowDisbursement(false)}
          />
        </Stack>
      );
    }
    default:
      return null;
  }
};

export default PaymentSummary;
