import { ColumnType } from '@utils';

const projectName: ColumnType = () => ({
  field: 'project.name',
  graphNested: {
    project: ['name', 'id'],
  },
  headerName: 'Project',
  minWidth: 250,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.project?.name || '-';
  },
});

export default projectName;
