import React from 'react';
import { Grid2 } from '@mui/material';
import { StyledBox } from '@components';
import { OptionDescription } from './index';

interface OptionsListDescriptionProps {
  list: Array<{
    id: string;
    label: string;
    logo?: string;
    description?: string;
  }>;
}

export const OptionsListDescription: React.FC<OptionsListDescriptionProps> = ({ list }) => {
  return (
    <Grid2 container spacing={2} flexWrap="wrap">
      {list?.map((item) => (
        <Grid2 size={{ xs: 6 }} key={item.id}>
          <StyledBox>
            <OptionDescription item={item} />
          </StyledBox>
        </Grid2>
      ))}
    </Grid2>
  );
};
