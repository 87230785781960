import React from 'react';
import { ColumnType, isInactiveProject, isRequestCompleted, WithPermission } from '@utils';
import { colors } from '@theme';
import { IconWithProjectPermission } from '@components';
import { IProjectsPortfolioItem, PermissionNamesEnums } from '@interfaces';
import { Stack, Typography } from '@mui/material';

const coordinator_full_name: ColumnType<IProjectsPortfolioItem> = ({
  updateAssignCoordinatorPopup,
}) => ({
  field: 'coordinator_full_name',
  graphFields: ['status', 'id'],
  graphNested: {
    project: ['id'],
    coordinator: ['full_name'],
  },
  headerName: 'Coordinator',
  minWidth: 50,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: ({ row }) => {
    const openPopupCallback = () => updateAssignCoordinatorPopup(row);
    const value = row?.coordinator?.full_name;
    return (
      <Stack flexDirection="row" alignItems="center">
        {value && (
          <Typography variant="body3SemiBold" sx={{ color: colors.text.link }}>
            {value || ''}
          </Typography>
        )}
        {!isRequestCompleted(row.status) && (
          <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}>
            <IconWithProjectPermission
              permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}
              projectId={row.id}
              openPopupCallback={openPopupCallback}
              isCurrentProjectArchived={isInactiveProject(row.status)}
              source={`projects__draw_coordinator_edit`}
            />
          </WithPermission>
        )}
      </Stack>
    );
  },
});

export default coordinator_full_name;
