import { percentFormatter } from '@utils';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const inspector_allowance_rate: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'inspector_allowance_rate',
  graphFields: ['inspector_allowance_rate'],
  headerName: 'Inspector allowance (%)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
});

export default inspector_allowance_rate;
