import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import {
  checkHasAmountChanges,
  checkIsOwner,
  getDynamicColumnsForBudgetCSV,
  getHookState,
  getItemLocalHighlight,
  getTeamRole,
  isCreatedProject,
  isAllowed,
  parsePathErrorDual,
  isInactiveProject,
} from '@utils';
import {
  AddedMsTableProps,
  DeleteListItemParam,
  ErrorDual,
  HookState,
  IDynamicColumns,
  ILineItemModal,
  IMilestone,
  IMilestoneTotal,
  IRightMenu,
  LineItemModalTypeEnums,
  MutationKeyEnum,
  PatchListItem,
  PatchListItemParam,
  PermissionNamesEnums,
  ProjectById,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteMilestoneItem,
  deleteProjectMilestones,
  getProjectDrawRequestsList,
  getProjectMilestonesList,
  pathMilestoneItem,
  postMilestoneToProject,
} from '@globalService';
import {
  AuthContext,
  PermissionsContext,
  SettingsContext,
  useLaunchDarklyFlags,
  useGraphQuery,
} from '@context';
import {
  ConfirmModalHookInterface,
  useCommentsAndDocumentsPreview,
  useConfirmationModal,
  useRightMenu,
  useLineItemsFilter,
  useSafeSnackbar,
  useMessageLinkParams,
  useMilestoneTagsFilter,
} from '@hooks';
import { excludeCommentsQueryFields, LineItemFilterValues } from '@constants';

export type ControllerInterface = {
  editMode: boolean;
  totals?: IMilestoneTotal;
  initColumns: Array<string>;
  handleFiltersChange: (value: string) => void;
  state: HookState;
  milestones: Array<Partial<IMilestone> & AddedMsTableProps>;
  filterOptions: string[];
  editBudget: () => void | null;
  addLineList: () => void;
  patchMilestone: (args: PatchListItem) => void;
  showEdit?: boolean;
  canReorder?: boolean;
  update: () => void;
  createMode: boolean;
  deleteBudgetModal: ConfirmModalHookInterface;
  deleteBudget: () => void;
  deleteMilestone: (milestone: string) => void;
  filterValue: string;
  updateListData: (updater) => void;
  dynamicColumns: IDynamicColumns[];
  showBudgetLockedWarning?: boolean;
  lineItemModal: ILineItemModal;
  setLineItemModal: Dispatch<React.SetStateAction<ILineItemModal>>;
  query: any;
  rightMenu: IRightMenu;
  rightDrawerParams: {
    projectId?: string;
    milestoneId?: string;
  };
  milestoneTagsOptions: string[];
  tagFilterValue: string;
  handleTagFilterClick: (value: string) => void;
};

export const useProjectMilestones = (projectId: string): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { settings, isPHBProject } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const flags = useLaunchDarklyFlags();

  // if user was redirected from comment deep link, we need to show right drawer
  const { isMessageLink, params } = useMessageLinkParams();
  const [rightDrawerParams, setRightDrawerParams] = useState<{
    projectId?: string;
    milestoneId?: string;
  }>({
    projectId,
  });
  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    milestoneId: rightDrawerParams.milestoneId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  useEffect(() => {
    if (isMessageLink) {
      setRightDrawerParams({ ...rightDrawerParams, milestoneId: params?.milestoneId });
      handleRightDrawerOpenerClick({ title: 'Comments' });
    }
  }, [isMessageLink]);

  const filterOptions = [
    LineItemFilterValues.ALL,
    LineItemFilterValues.ACTIVE,
    LineItemFilterValues.NON_ZERO_BUDGET,
  ];
  const [lineItemModal, setLineItemModal] = useState<ILineItemModal>({
    open: false,
    type: LineItemModalTypeEnums.ADD,
    lineItem: null,
  });

  const { filterValue, handleFilterClick, setFilterValue, filterKey } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.LINE_ITEMS,
  });

  const { filterValue: tagFilterValue, handleFilterClick: handleTagFilterClick } =
    useLineItemsFilter({
      defaultState: 'all',
      tableKey: TableKeyEnum.LINE_ITEMS,
      accessKey: 'tag_filters',
    });

  const { milestoneTagsOptions, tagFilterId } = useMilestoneTagsFilter({
    tagFilterValue,
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const query = useMemo(
    () => (flags?.['ENG_9292_redo_blue_dot_logic_for_comments'] ? '' : excludeCommentsQueryFields),
    [flags],
  );
  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey, tagFilterValue: tagFilterId },
      ],
      queryFn: getProjectMilestonesList.bind(this, {
        projectId,
        query,
        filterKey,
        tagFilterValue: tagFilterId,
      }),
      enabled: !isPHBProject,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
  ]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'name',
      'customer_name',
      'lender_company_logo',
      'is_budget_locked',
      'status',
      'payment_configuration_type',
      'retainage_rate',
    ],
    args: { project_id: projectId },
  });

  const projectMilestones = requestedDataQueries[0];
  const drawRequests = requestedDataQueries[1];

  useEffect(() => {
    if (project.data?.is_budget_locked) {
      setEditMode(false);
    } else if (
      isCreatedProject(project.data?.status) &&
      !drawRequests.data?.results?.length &&
      (isAllowed(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions) ||
        isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions)) &&
      projectMilestones.data?.results?.length
    ) {
      setEditMode(true);
    }
  }, [
    project.data?.is_budget_locked,
    permissions,
    project.data?.status,
    drawRequests.data,
    projectMilestones.data?.results,
  ]);

  useEffect(() => {
    // to show edit mode by default for created projects (only for the first time)
    if (isCreatedProject(project.data?.status) && !isEmpty(settings)) {
      setFilterValue(LineItemFilterValues.ALL.filterValue);
      if (
        projectMilestones.data?.results?.length &&
        !drawRequests.data?.results?.length &&
        (isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions) ||
          (!project.data?.is_budget_locked &&
            isAllowed(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions)))
      ) {
        setEditMode(true);
      }
    }
  }, [project.data, projectMilestones.data, settings, permissions, drawRequests.data]);

  const milestones = useMemo(() => {
    if (!projectMilestones.data?.results) return [];

    return projectMilestones.data.results.map((item) => ({
      ...item,
      activeToEdit: editMode,
      isCreatedProjectMode: isCreatedProject(project.data?.status),
      isInactiveProjectMode: isInactiveProject(project.data?.status),
      canAddPhotos: true,
      localNew: editMode,
      localHighlight: getItemLocalHighlight(item),
      paymentConfigurationType: project.data?.payment_configuration_type,
      original_construction_budget: item.prefunding_cost + item.original_estimate,
      revised_construction_budget: item.prefunding_cost + item.revised_estimate,
    }));
  }, [project.data, projectMilestones.data, filterValue, editMode]);

  const hasAmountChanges = useMemo(
    () => checkHasAmountChanges(projectMilestones.data?.results),
    [projectMilestones.data?.results],
  );

  const retainageRate = useMemo(() => project.data?.retainage_rate, [project.data]);

  const initColumns = useMemo(
    () =>
      editMode
        ? [
            ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.LINE_ITEMS}`]
              ? ['nameForBudget', 'externalId']
              : ['nameV2']),
            'costType',
            'originalConstructionBudget',
            'prefundingCost',
            'originalEstimate',
            ...(retainageRate ? ['retainageRateBudget'] : []),
            'revisedConstructionBudget',
            'deleteLineItem',
            'documentsPhotosUploaderMenuV2',
            'documentsPhotosGalleryMenu',
            'comments',
          ]
        : [
            ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.LINE_ITEMS}`]
              ? ['nameForBudget']
              : ['descriptionV2']),
            ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.LINE_ITEMS}`] ? ['externalId'] : []),
            'costType',
            'originalConstructionBudget',
            'prefundingCost',
            'originalEstimate',
            ...(retainageRate ? ['retainageRateBudget'] : []),
            'revisedConstructionBudget',
            ...(hasAmountChanges ? ['adjustmentsTotalApprovedBudget'] : []),
            'revisedMilestoneAmount',
            ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.LINE_ITEMS}`]
              ? ['balanceToFinishInReview']
              : ['balanceToFinish']),
            'inspectorAllowance',
            'inspectorAllowanceRate',
            'approvedAmountCumulative',
            'lenderAllowanceRate',
            'varianceToLenderAllowance',
            'drawRequestNumbers',
            'changeRequestNumbers',
            ...(flags?.['ENG_9473_line_item_tags'] ? ['lineItemTags'] : []),
            'spaceItem',
            'documentsPhotosUploaderMenuV2',
            'documentsPhotosGalleryMenuV2',
            'comments',
          ],
    [flags, hasAmountChanges, editMode, retainageRate],
  );
  // TODO item list V2 refract

  const update = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES &&
        (query.queryKey[1] as { projectId?: string })?.projectId === projectId,
    });
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
        (query.queryKey[1] as { projectId?: string })?.projectId === projectId,
    });
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST_V2 &&
        (query.queryKey[1] as { project_id?: string })?.project_id === projectId,
    });
  };

  const addLineListItemMutation = useMutation<Response, Error, ProjectById>(
    postMilestoneToProject,
    {
      mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
      onSuccess: () => {
        update();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const addLineListOld = useCallback(
    () => addLineListItemMutation.mutateAsync({ project: projectId }),
    [addLineListItemMutation],
  );

  const addLineList = useCallback(() => {
    setLineItemModal({
      open: true,
      type: LineItemModalTypeEnums.ADD,
      lineItem: null,
      fields: ['name', 'original_estimate', 'prefunding_cost'],
      submitAction: 'postProjectMilestoneMutation',
    });
  }, []);

  const patchMilestoneMutation = useMutation<Response, ErrorDual, PatchListItemParam>(
    pathMilestoneItem,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        update();
      },
    },
  );

  const patchMilestone = useCallback(
    (args: PatchListItem) =>
      patchMilestoneMutation.mutate({
        project: projectId,
        milestone: args.milestone,
        json: args.json,
      }),
    [projectId],
  );

  const deleteBudgetModal = useConfirmationModal();

  const deleteBudgetMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
    }
  >(deleteProjectMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_DELETE,
    onSuccess: () => {
      update();
      deleteBudgetModal.closeConfirmModal();
      setEditMode(false);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const deleteBudget = () =>
    deleteBudgetMutation.mutate({
      projectId,
    });

  const deleteMilestoneMutation = useMutation<Response, ErrorDual, DeleteListItemParam>(
    deleteMilestoneItem,
    {
      onSuccess: () => {
        update();
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
        update();
      },
    },
  );

  const deleteMilestone = useCallback(
    (milestone: string) =>
      deleteMilestoneMutation.mutate({
        project: projectId,
        milestone,
      }),
    [projectId],
  );

  const isCreateMode = useMemo(
    () =>
      !editMode &&
      projectMilestones.isSuccess &&
      !milestones?.length &&
      isCreatedProject(project.data?.status),
    [editMode, projectMilestones.isSuccess, milestones, project.data],
  );

  useEffect(() => {
    setCreateMode(isCreateMode);
  }, [isCreateMode]);

  const updateListData = useCallback(
    (updater) =>
      queryClient.setQueriesData(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query }],
          exact: false,
        },
        updater,
      ),
    [queryClient, projectId, query],
  );

  const dynamicColumns = useMemo(() => getDynamicColumnsForBudgetCSV(permissions), [permissions]);

  const showEdit = useMemo(
    () =>
      isCreatedProject(project.data?.status) &&
      !drawRequests.data?.results?.length &&
      (isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions) ||
        (!project.data?.is_budget_locked &&
          isAllowed(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions))),
    [project.data, drawRequests.data, permissions],
  );

  const showBudgetLockedWarning = useMemo(
    () =>
      project.data?.is_budget_locked &&
      isCreatedProject(project.data?.status) &&
      !drawRequests.data?.results?.length &&
      checkIsOwner(teamRole) &&
      isAllowed(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions),
    [project.data, drawRequests.data, permissions, teamRole],
  );

  return {
    state: getHookState([...requestedDataQueries, project]),
    query: projectMilestones,
    totals: milestones.length ? projectMilestones.data?.totals : null,
    handleFiltersChange: (value) => handleFilterClick(value),
    filterOptions: map(filterOptions, 'filterValue'),
    initColumns,
    milestones,
    editMode,
    addLineList: flags?.[`ENG_9827_table_part_13`] ? addLineList : addLineListOld,
    patchMilestone,
    editBudget: () => {
      setEditMode((data) => !data);
      setCreateMode(false);
    },
    showEdit,
    createMode,
    update,
    deleteBudgetModal,
    deleteBudget,
    deleteMilestone,
    filterValue,
    updateListData,
    dynamicColumns,
    showBudgetLockedWarning,
    lineItemModal,
    setLineItemModal,
    canReorder: isAllowed(
      [PermissionNamesEnums.PROJECTS_DETAILS_EDIT, PermissionNamesEnums.PROJECT_BUDGET_EDIT],
      permissions,
    ),
    rightMenu,
    rightDrawerParams,
    milestoneTagsOptions,
    tagFilterValue,
    handleTagFilterClick,
  };
};
