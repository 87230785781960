import { useContext, useMemo, useState } from 'react';
import { IProject, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import {
  checkIsCustomerSuccess,
  checkIsInvestor,
  checkIsOwner,
  createColumnVisibleModel,
  generateGraphKeys,
  getHookState,
  getSortStringByGridSortModel,
  getTeamRole,
} from '@utils';
import { defaultPersonalSetting } from '@constants';
import { AuthContext, useGraphQuery } from '@context';
import { useTablePagination, useUserTableSettings } from '@hooks';
import { GridSortModel } from '@mui/x-data-grid-premium';
import ColumnsConfig from './columns';

export const useProjectList = () => {
  const { user } = useContext(AuthContext);
  const { userTableSettings } = useUserTableSettings({ tableKey: TableKeyEnum.RISK_RADAR });
  const teamRole = getTeamRole(user);

  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([]);
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const _isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);
  const _isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const _isCustomerSuccess = useMemo(() => checkIsCustomerSuccess(teamRole), [teamRole]);

  const { page, rowsPerPage, onPageChange, rowsPerPageOptions, onRowsPerPageChange } =
    useTablePagination({});

  const columnsToFetch = useMemo(() => {
    const columnsModel = userTableSettings?.columns.columnVisibilityModel || {
      ...createColumnVisibleModel(
        ColumnsConfig.map((column) => column({}).field),
        true,
      ),
      ...createColumnVisibleModel(
        defaultPersonalSetting.tables[TableKeyEnum.RISK_RADAR]?.hidden_columns || [],
      ),
    };
    if (columnsModel) return Object.keys(columnsModel).filter((key) => columnsModel[key]);
    return [];
  }, [userTableSettings?.columns.columnVisibilityModel]);

  const queryTest = useMemo(
    () =>
      generateGraphKeys(
        columnsToFetch?.map((column) =>
          ColumnsConfig.map((column) => column({})).find((config) => config.field === column),
        ),
      ),
    [columnsToFetch],
  );

  const projectsQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECTS_LIST,
    keys: queryTest.keys as (keyof IProject)[],
    nested: queryTest.nested,
    args: {
      pagination: {
        page,
        rowsPerPage,
      },
      sorting: getSortStringByGridSortModel(tableSortingModel),
      filter: filterStringQuery,
    },
    options: {
      skip: !columnsToFetch?.length || !filtersReady,
    },
  });

  const columns = useMemo(() => ColumnsConfig.map((column) => column({})), []);

  return {
    isLoading: projectsQuery.isLoading || projectsQuery.isPostLoading,
    state: getHookState(projectsQuery),
    count: projectsQuery.data?.count,
    results: projectsQuery.data?.results,
    onFiltersReady: () => setFiltersReady(true),
    page,
    rowsPerPage,
    onPageChange,
    rowsPerPageOptions,
    onRowsPerPageChange,
    onSortModelChange: setTableSortingModel,
    setFilterStringQuery,
    _isOwner,
    columns,
    filters: [],
    formattedData: projectsQuery.data?.results || [],
  };
};
