import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DateValidationEnum,
  ServiceOrderStatusEnum,
  QueryNamesEnums,
  IServiceOrder,
  IServiceOrderPayload,
  PermissionNamesEnums,
  CommonServiceOrderPayload,
} from '@interfaces';
import { useDateFieldModel } from '@models';
import {
  getServiceOrderById,
  patchServiceOrderToProject,
  deleteServiceOrder,
} from '@globalService';
import {
  getHookState,
  isServiceProviderAutomatedByCS,
  isAllowed,
  getServiceTypeDisplayName,
  isCompletedService,
  checkIsInvestor,
  getTeamRole,
} from '@utils';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useRightMenu,
  useSafeSnackbar,
  useConfirmationModal,
  useDayJsFormatter,
} from '@hooks';
import { SettingsContext, PermissionsContext, useGraphQuery, AuthContext } from '@context';
import { statusMap } from '@constants';

export const useServiceOrderEnterResult = () => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { getDateValidationRule, getInitialValue } = useDayJsFormatter();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { serviceOrderId, projectId } = useParams();
  const { settings, isCurrentProjectArchived } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const imagePicker = useImagePicker();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isApprovalPopupOpen, setIsApprovalPopupOpen] = useState<boolean>(false);
  const imageContainer = useRef();
  const deleteServiceOrderModal = useConfirmationModal();
  const [editedFieldKey, setEditedFieldKey] = useState<string>('');

  const isViewRestricted = useMemo(() => {
    return !isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions);
  }, [permissions]);

  const serviceCompletedAt = useDateFieldModel({
    initValue: getInitialValue({ addTimestamp: true }),
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS_OR_EQUAL,
        maxDate: new Date(),
      }),
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    serviceOrderId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const rightDrawerParams = useMemo(
    () => ({
      projectId,
      serviceOrderId,
    }),
    [projectId, serviceOrderId],
  );

  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const queryServiceOrderFields = `{${[
    'id',
    'invoiced_amount',
    'status',
    'service_number',
    'service_agency{display_name,service_provider}',
    'provider_order_id',
    'provider_service_cost',
    'ordered_at',
    'completed_at',
    'cancelled_at',
    'cancelled_by{full_name}',
    'ordered_by{full_name}',
    'draw_request{id,number}',
    'provider_status_display',
    'transaction_id',
    'comment',
    'result_documents',
    'comments_preview',
    'service_type',
    'project{id}',
  ].join(',')}}`;
  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
        { projectId, serviceOrderId, restQlquery: queryServiceOrderFields },
      ],
      queryFn: getServiceOrderById.bind(this, {
        projectId,
        serviceOrderId,
        restQlquery: queryServiceOrderFields,
      }),
    },
  ]);

  const serviceOrder = useMemo(() => requestedDataQueries[0].data, [requestedDataQueries[0].data]);

  const serviceTypeDisplayName = useMemo(() => {
    if (!serviceOrder) return '';
    return getServiceTypeDisplayName({
      serviceTypesMap: settings?.display?.service_types,
      serviceType: serviceOrder.service_type,
    });
  }, [settings?.display?.service_types, serviceOrder]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'name', 'comments_preview', 'status', 'status_change_reason'],
    args: { project_id: projectId },
  });

  useEffect(() => {
    if (serviceOrder?.completed_at) {
      serviceCompletedAt.setValue(getInitialValue({ date: serviceOrder?.completed_at }));
    }
  }, [serviceOrder?.completed_at]);

  const updateQueries = () => {
    const params = { projectId, drawRequestId: serviceOrder?.draw_request?.id };
    queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS, params]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
      { projectId, serviceOrderId },
    ]);
  };

  const completeServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: () => {
      updateQueries();
      setIsApprovalPopupOpen(true);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const confirmCallBack = useCallback(async () => {
    if (serviceCompletedAt.validate())
      await completeServiceOrder.mutateAsync({
        projectId,
        serviceOrderId,
        json: {
          status: ServiceOrderStatusEnum.COMPLETED,
          completed_at: serviceCompletedAt.value,
        },
      });
  }, [projectId, serviceOrderId, serviceCompletedAt]);

  const updateServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: () => {
      setEditedFieldKey('');
      updateQueries();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleUpdateServiceOrderField = useCallback(
    async (fieldKey, value) => {
      await updateServiceOrder.mutateAsync({
        projectId,
        serviceOrderId,
        json: {
          [fieldKey]: value,
          ...(fieldKey === 'status' &&
          isCompletedService(value) &&
          serviceCompletedAt.value &&
          serviceCompletedAt.isValid
            ? { completed_at: serviceCompletedAt.value }
            : {}),
        },
      });
    },
    [projectId, serviceOrderId, serviceCompletedAt],
  );

  const isServiceOrderLinkedToDR = useMemo(
    () => Boolean(serviceOrder?.draw_request?.id),
    [serviceOrder?.draw_request?.id],
  );

  const goBack = () => navigate(state || `/projects/${projectId}/services/all/`);

  const serviceStatusValue = statusMap(serviceOrder?.status, settings.display, 'service_order');
  const statusChipProps = {
    color: serviceStatusValue.color,
    backgroundColor: serviceStatusValue.backgroundColor,
    label: serviceStatusValue.text || serviceOrder?.status,
    dataTestName: 'data-cy="service_results__status_chip"',
  };

  const providerStatusValue = statusMap(
    ServiceOrderStatusEnum.ORDERED,
    settings.display,
    'service_order',
  );

  const providerStatusChipProps = {
    color: providerStatusValue.color,
    backgroundColor: providerStatusValue.backgroundColor,
    label: serviceOrder?.provider_status_display,
    dataTestName: 'service_results__provider_status_chip',
  };

  const shouldShowDeleteButton = useMemo(
    () =>
      !isCurrentProjectArchived &&
      ((!isServiceProviderAutomatedByCS(serviceOrder?.service_agency?.service_provider) &&
        isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions)) ||
        isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)),
    [serviceOrder, permissions, project, isCurrentProjectArchived],
  );

  const deleteServiceOrderMutation = useMutation<Response, Error, CommonServiceOrderPayload>(
    deleteServiceOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
          { projectId, drawRequestId: serviceOrder?.draw_request?.id },
        ]);
        goBack();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleDeleteServiceOrder = useCallback(async () => {
    await deleteServiceOrderMutation.mutateAsync({
      projectId,
      serviceOrderId,
    });
  }, [projectId, serviceOrderId]);

  const isCommentsAvailable = useMemo(
    () => isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions) && !checkIsInvestor(teamRole),
    [permissions, teamRole],
  );

  return {
    state: getHookState(requestedDataQueries),
    serviceOrder,
    updateQueries,
    project: project?.data,
    showPreview,
    setShowPreview,
    confirmCallBack,
    imagePicker,
    imageContainer,
    goBack,
    drawRequestNumber: serviceOrder?.draw_request?.number,
    rightDrawerParams,
    rightMenu,
    updateRightDrawer,
    isServiceOrderLinkedToDR,
    activeDocumentId: imagePicker.pdf?.[0]?.id || imagePicker.gallery?.[0]?.id,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    serviceCompletedAt,
    statusChipProps,
    providerStatusChipProps,
    shouldShowDeleteButton,
    deleteServiceOrderModal,
    isCurrentProjectArchived,
    handleDeleteServiceOrder,
    handleUpdateServiceOrderField,
    serviceTypeDisplayName,
    editedFieldKey,
    setEditedFieldKey,
    isMutating:
      updateServiceOrder.isLoading ||
      completeServiceOrder.isLoading ||
      deleteServiceOrderMutation.isLoading,
    isViewRestricted,
    isCommentsAvailable,
  };
};
