import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useRequestPaymentData, useSafeSnackbar } from '@hooks';
import {
  FundingSourceInternalIdEnums,
  PatchDrawRequestParam,
  PaymentConfiguration,
  QueryNamesEnums,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import { calculateFraction, currencyFormatter, getHookState, validationShareRule } from '@utils';
import { patchDrawRequest } from '@globalService';
import { ComponentProps, ControllerInterface } from './interface';
import { useGraphQuery } from '@context';

export const useEditRequestPaymentConfigurationV2 = ({
  drawRequestId,
  onClose,
}: ComponentProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const { getRequestOriginalValuePieChartData, constructionHoldbackFS, borrowerEquityFS } =
    useRequestPaymentData();

  const drawRequest = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_V2,
    keys: ['approved_amount'],
    args: { project_id: projectId, draw_request_id: drawRequestId },
    options: {
      skip: !projectId || !drawRequestId,
    },
  });

  const totalAmount = useMemo(() => {
    return drawRequest.data?.approved_amount || 0;
  }, [drawRequest.data?.approved_amount]);

  const initErrorText = useCallback(
    (internal_identifier: FundingSourceInternalIdEnums) => {
      const prefix =
        internal_identifier === FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK
          ? 'Construction holdback'
          : 'Borrower equity';
      const availableAmount =
        internal_identifier === FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK
          ? +constructionHoldbackFS?.revised_total -
            +constructionHoldbackFS?.funded_before_current_request
          : +borrowerEquityFS?.revised_total - +borrowerEquityFS?.funded_before_current_request;
      const minAmount = totalAmount < availableAmount ? totalAmount : availableAmount;
      return `${prefix} must be between 0 and  ${
        minAmount === totalAmount ? 'approved amount' : 'available amount in this funding source'
      } (${currencyFormatter(minAmount)})`;
    },
    [totalAmount, constructionHoldbackFS, borrowerEquityFS],
  );

  const amountValidationRule = useCallback(
    (value: string, internal_identifier: FundingSourceInternalIdEnums) => {
      const fundingSource =
        internal_identifier === FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK
          ? constructionHoldbackFS
          : borrowerEquityFS;
      return (
        (+value <= drawRequest.data?.approved_amount || 0) &&
        +value <= +fundingSource?.revised_total - +fundingSource?.funded_before_current_request &&
        +value >= 0
      );
    },
    [totalAmount, constructionHoldbackFS, borrowerEquityFS],
  );

  const constructionHoldback = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
    validationRule: (value) =>
      amountValidationRule(value, FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK),
    initError: initErrorText(FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK),
  });
  const constructionHoldbackFraction = useStringFieldModel({
    initValue: '100',
    validationRule: validationShareRule,
    validateOnChange: true,
  });
  const borrowerEquityFraction = useStringFieldModel({
    initValue: '0',
    validationRule: validationShareRule,
    validateOnChange: true,
  });
  const borrowerEquity = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
    validationRule: (value) =>
      amountValidationRule(value, FundingSourceInternalIdEnums.BORROWER_EQUITY),
    initError: initErrorText(FundingSourceInternalIdEnums.BORROWER_EQUITY),
  });

  useEffect(() => {
    if (!constructionHoldbackFS || !borrowerEquityFS) return;
    const constructionHoldbackAmount = constructionHoldbackFS?.amount || 0;
    const borrowerEquityAmount = borrowerEquityFS?.amount || 0;
    const constructionHoldbackRate = calculateFraction(constructionHoldbackAmount, totalAmount);
    const borrowerEquityRate = calculateFraction(borrowerEquityAmount, totalAmount);

    constructionHoldbackFraction.setValue(constructionHoldbackRate.toFixed(2));
    constructionHoldbackFraction.validate();
    borrowerEquityFraction.setValue(borrowerEquityRate.toFixed(2));
    borrowerEquityFraction.validate();
    constructionHoldback.setValue(constructionHoldbackAmount.toString());
    constructionHoldback.validate();
    borrowerEquity.setValue(borrowerEquityAmount.toString());
    borrowerEquity.validate();
  }, [constructionHoldbackFS, borrowerEquityFS, totalAmount]);

  const updatePaymentConfiguration = useMutation<Response, Error, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_V2);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSubmit = async () => {
    if (!constructionHoldbackFraction.value && !borrowerEquityFraction.value) return;

    await updatePaymentConfiguration.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      sources: [
        {
          id: constructionHoldbackFS?.id,
          amount: +constructionHoldback.value,
        },
        {
          id: borrowerEquityFS?.id,
          amount: +borrowerEquity.value,
        },
      ],
      payment_configuration_type: PaymentConfiguration.PER_DRAW_REQUEST,
    });

    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES);

    onClose();
  };

  return {
    state: getHookState(drawRequest),
    constructionHoldback,
    constructionHoldbackFraction,
    borrowerEquityFraction,
    borrowerEquity,
    handleSubmit,
    disableSaveButton: [
      constructionHoldback,
      borrowerEquity,
      constructionHoldbackFraction,
      borrowerEquityFraction,
    ].some(({ isValid }) => !isValid),
    originalValuePieChartData: getRequestOriginalValuePieChartData(),
    totalAmount,
  };
};
