import { ColumnType } from '@utils';
import { IProjectsPortfolioItem } from '@interfaces';

const name: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'name',
  graphFields: ['name'],
  headerName: 'Name',
  minWidth: 130,
  groupable: false,
  aggregable: false,
  flex: 1,
});

export default name;
