import React, { FC, useContext } from 'react';
import { Button, ButtonGroup, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Route, Routes, useParams } from 'react-router-dom';
import { HookState, PopupTypeEnum, RequestModalTypeEnums } from '@interfaces';
import {
  ButtonWithTooltip,
  ConfirmationModal,
  Filter,
  IconButtonWithTooltip,
  LineItemEdit,
  LineItemEditV2,
  LumpSumModal,
  MessagePanel,
  MilestoneListWithPatch,
  PDFViewerNew,
  RightDrawer,
  ServiceMessage,
} from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { AutofillAllowance } from '../index';
import RequestSummary from '../RequestSummary';
import { getDeleteDrawConfirmationText, getTooltipText } from '@utils';
import {
  AddProofpointIcon,
  BudgetReviewIcon,
  CommentIconWithIndicator,
  DeleteIcon,
  NoDataImage,
  UpdateIcon,
} from '@svgAsComponents';
import { ControllerInterface } from './interface';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { ProductionRequestTableV2 } from '../../../productionBuild';
import { useDayJsFormatter } from '@hooks';

const DrawRequestTabWrapper = ({ drawRequest }) => {
  if (!drawRequest) {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
        <NoDataImage size={200} />
        <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
          There are no created draws in the project yet.
        </Typography>
      </Stack>
    );
  }

  return <DrawRequestTab drawRequest={drawRequest} />;
};

const DrawRequestTab = ({ drawRequest }) => {
  const { projectId } = useParams();
  const controller = Controller.useDrawRequest(projectId, drawRequest);

  switch (controller.state) {
    case HookState.ERROR: {
      return <ServiceMessage text="Draws" />;
    }

    case HookState.SUCCESS:
    case HookState.LOADING: {
      return (
        <Routes>
          <Route
            path="draw-requests/:requestId"
            element={<DrawRequest controller={controller} />}
          />
        </Routes>
      );
    }

    default:
      return null;
  }
};

const DrawRequest: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const {
    drawRequest,
    updateQueries,
    initColumns,
    milestones,
    totals,
    handleAutofillLenderAllowance,
    handleFiltersChange,
    filterOptions,
    showAutofillButton,
    canViewReport,
    addLineList,
    openDraft,
    canDeleteRequest,
    deleteRequest,
    reportCallback,
    openPdfReport,
    pdf,
    close,
    isDeleting,
    isAutofillLoading,
    confirmModalType,
    setConfirmModalType,
    lineItemModal,
    setLineItemModal,
    openEditMilestoneModal,
    filterValue,
    isMilestoneMutatingOrFetching,
    isCurrentProjectActive,
    continueDraftButtonLabel,
    updateRightDrawer,
    showAddNewLine,
    rightMenu,
    creatingNewLine,
    isRequestDraftOrInReview,
    isLumpSumApprovalAvailable,
    openLumpSumModal,
    setOpenLumpSumModal,
    isCommentsAvailable,
    filterKey,
    rightDrawerParams,
    milestoneTagsOptions,
    handleTagFilterClick,
    tagFilterValue,
  } = controller;
  const { dateFormatter } = useDayJsFormatter();
  const { isCurrentProjectArchived, isPHBProject } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();

  const confirmationModal = {
    [RequestModalTypeEnums.DELETE]: (
      <ConfirmationModal
        open
        title="Delete request"
        text={getDeleteDrawConfirmationText(drawRequest?.status)}
        onClose={() => setConfirmModalType('')}
        confirmCallback={deleteRequest}
        type={PopupTypeEnum.ERROR}
        isLoading={isDeleting}
        confirmButtonLabel="Permanently delete draw & documents"
        icon={DeleteIcon}
      />
    ),
  };

  return (
    <>
      <RequestSummary />
      <Stack sx={{ mt: 2, backgroundColor: colors.background.white, borderRadius: '4px', flex: 1 }}>
        {isPHBProject ? (
          <ProductionRequestTableV2 />
        ) : (
          <MilestoneListWithPatch
            rowReordering={isRequestDraftOrInReview}
            withColumnIndication
            withProgress
            requestStatus={drawRequest?.status}
            tableKey={drawRequest?.type}
            refetch={updateQueries}
            requestId={drawRequest?.id}
            key={drawRequest?.id + filterKey}
            initColumns={initColumns}
            milestones={milestones}
            totals={totals}
            exportToCSV
            withCredit
            contingencyMode={drawRequest?.contingency_mode}
            headerLeftPart={[
              {
                Component: (
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Filter
                      filterLabel="Show"
                      onChangeCallback={handleFiltersChange}
                      options={filterOptions}
                      filterValue={filterValue}
                      disabled={isMilestoneMutatingOrFetching}
                      source="requests_tab__line_items_table__filter"
                    />
                    {flags?.['ENG_9473_line_item_tags'] && (
                      <Filter
                        filterLabel="Tags"
                        onChangeCallback={handleTagFilterClick}
                        options={milestoneTagsOptions}
                        filterValue={tagFilterValue}
                        disabled={isMilestoneMutatingOrFetching}
                        source="requests_tab__line_items_table__tag_filter"
                      />
                    )}
                  </Stack>
                ),
              },
            ]}
            headerRightPart={[
              {
                Component: (
                  <Stack spacing={1} direction="row" alignItems="center">
                    {!flags?.[`ENG_7895_table_v3__${drawRequest?.type}`] && (
                      <>
                        {showAddNewLine && (
                          <Button
                            loading={creatingNewLine}
                            size="small"
                            onClick={addLineList}
                            data-cy="draw_add_line_item_button"
                            variant="new"
                            color="secondary"
                          >
                            Add line item
                          </Button>
                        )}
                      </>
                    )}
                    {showAutofillButton && (
                      <AutofillAllowance
                        drawRequest={drawRequest}
                        handleAutofillLender={handleAutofillLenderAllowance}
                        loading={isAutofillLoading}
                      />
                    )}

                    {canViewReport && (
                      <ButtonGroup disableElevation>
                        <Button
                          size="small"
                          variant="new"
                          color="secondary"
                          onClick={drawRequest?.report ? openPdfReport : reportCallback}
                          data-cy="draw__generate_report__button"
                        >
                          {'TrustPoint® report'}
                        </Button>
                        {drawRequest?.report && (
                          <Tooltip
                            title={`Generate new report. Last version: ${dateFormatter({
                              date: drawRequest?.report?.created_at,
                              withTime: true,
                            })}`}
                          >
                            <IconButton color="primary" onClick={reportCallback}>
                              <UpdateIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    )}
                    {continueDraftButtonLabel && (
                      <ButtonWithTooltip
                        disabled={!isCurrentProjectActive}
                        tooltipText={getTooltipText({ nonActiveProject: !isCurrentProjectActive })}
                        size="small"
                        onClick={openDraft}
                        dataTestName="draw_continue_draft_button"
                      >
                        {continueDraftButtonLabel}
                      </ButtonWithTooltip>
                    )}
                  </Stack>
                ),
              },
            ]}
            openEditMilestoneModal={openEditMilestoneModal}
            footerDataTestName="draw_milestone_list_table_footer"
            menuItems={[
              ...(flags?.[`ENG_7895_table_v3__${drawRequest?.type}`]
                ? [
                    ...(showAddNewLine
                      ? [
                          {
                            action: addLineList,
                            text: 'Add line item',
                            icon: <AddProofpointIcon size={18} />,
                            dataTestName: 'draw_add_line_item_button',
                          },
                        ]
                      : []),
                  ]
                : []),
              ...(isLumpSumApprovalAvailable
                ? [
                    {
                      action: () => setOpenLumpSumModal(true),
                      text: 'Enter lump sum',
                      icon: <BudgetReviewIcon size={18} />,
                      dataTestName: 'add_lump_sum__menu_item',
                    },
                  ]
                : []),
              ...(canDeleteRequest
                ? [
                    {
                      action: () => setConfirmModalType(RequestModalTypeEnums.DELETE),
                      text: (
                        <Typography
                          variant="body3SemiBold"
                          sx={{ color: colors.status.error.medium }}
                        >
                          Delete request
                        </Typography>
                      ),
                      icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
                      disabled: isCurrentProjectArchived,
                      disabledTooltipText: getTooltipText({ isCurrentProjectArchived }),
                      dataTestName: 'delete_request__menu_item',
                    },
                  ]
                : []),
            ]}
            source="requests_tab__line_items_table"
            isLoading={controller.state === HookState.LOADING}
            commentIconComponent={
              isCommentsAvailable &&
              (flags?.[`ENG_7895_table_v3__${drawRequest?.type}`] ? (
                <Tooltip title="Comments">
                  <Button
                    data-cy="requests_tab__comments__icon"
                    startIcon={
                      <CommentIconWithIndicator
                        hasComments={drawRequest?.comments_preview?.has_comments}
                        hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
                      />
                    }
                    variant="shortIcon"
                    onClick={updateRightDrawer()}
                  />
                </Tooltip>
              ) : (
                <IconButtonWithTooltip
                  tooltipText="Comments"
                  onClick={updateRightDrawer()}
                  data-cy="requests_tab__comments__icon"
                  size="large"
                >
                  {/** add permission */}
                  <CommentIconWithIndicator
                    hasComments={drawRequest?.comments_preview?.has_comments}
                    hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
                  />
                </IconButtonWithTooltip>
              ))
            }
          />
        )}
      </Stack>

      {confirmationModal[confirmModalType]}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
      {lineItemModal?.open &&
        (flags?.['ENG_9827_table_part_13'] ? (
          <LineItemEditV2
            modalConfig={lineItemModal}
            setLineItemModal={setLineItemModal}
            request={drawRequest}
          />
        ) : (
          <LineItemEdit
            lineItem={lineItemModal?.lineItem}
            open={lineItemModal?.open}
            setLineItemModal={setLineItemModal}
            type={lineItemModal?.type}
            request={drawRequest}
          />
        ))}
      <RightDrawer {...rightMenu}>
        <MessagePanel {...rightDrawerParams} source="requests_tab__comments" />
      </RightDrawer>
      {openLumpSumModal && (
        <LumpSumModal
          open={openLumpSumModal}
          onClose={() => setOpenLumpSumModal(false)}
          fieldKey="approved_amount"
          initValue={drawRequest?.approved_amount?.toString()}
        />
      )}
    </>
  );
};

export default DrawRequestTabWrapper;
