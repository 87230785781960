import React from 'react';
import { StyledLink } from '@components';
import { ColumnType } from '@utils';

const drawRequestNumber: ColumnType = () => ({
  field: 'draw_request_number',
  graphNested: {
    draw_request: ['number', 'id'],
    project: ['id'],
  },
  headerName: 'Draw request',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return (
      <StyledLink
        onClick={(e) => e.stopPropagation()}
        to={`/projects/${row?.project?.id}/draws/draw-requests/${row?.draw_request?.id}`}
        variant="body3Semibold"
        data-cy="services_list__linked_request_number"
      >
        {row?.draw_request?.number}
      </StyledLink>
    );
  },
});

export default drawRequestNumber;
