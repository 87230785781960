import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const draw_request_name: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'draw_request.name',
  graphFields: [],
  graphNested: {},
  headerName: 'Request name',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  renderCell: () => {
    return 'CHECK';
  },
});

export default draw_request_name;
