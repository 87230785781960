import { getServiceTypeDisplayName, ColumnType } from '@utils';

interface ServiceTypeProps {
  serviceTypesMap: Record<string, string>;
}

const serviceType: ColumnType = ({ serviceTypesMap }: ServiceTypeProps) => ({
  field: 'service_type',
  graphFields: ['service_type'],
  headerName: 'Service type',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const serviceType = getServiceTypeDisplayName({
      serviceTypesMap,
      serviceType: row.service_type,
    });

    return serviceType;
  },
  flex: 1,
});

export default serviceType;
