import React from 'react';
import Typography from '@mui/material/Typography';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const is_budget_locked: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'is_budget_locked',
  graphFields: ['is_budget_locked'],
  headerName: 'Locked budget',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return (
      <Typography data-cy="projects__table__body__is_budget_locked" variant="body2">
        {row.is_budget_locked ? 'Yes' : 'No'}
      </Typography>
    );
  },
});

export default is_budget_locked;
