import { ColumnType } from '@utils';

const serviceNumber: ColumnType = () => ({
  field: 'service_number',
  graphFields: ['service_number'],
  headerName: 'Service number',
  minWidth: 200,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.service_number || '-';
  },
  flex: 1,
});

export default serviceNumber;
