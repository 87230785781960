import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const address: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'address.address_1',
  graphNested: {
    address: ['address_1'],
  },
  headerName: 'Address',
  minWidth: 130,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) => row?.address?.address_1,
  flex: 1,
});

export default address;
