import { alpha, styled } from '@mui/system';
import { Box } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';
import { colors } from '@theme';
import { TableStyleProps } from './interface';

export const TRIGGERHEIGHT = 60;

export const TableStyle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'containerSpacing' &&
    prop !== 'onFullScreen' &&
    prop !== 'dynamicHeight' &&
    prop !== 'withoutAdaptiveScroll' &&
    prop !== 'listStyle' &&
    prop !== 'inContainer',
})<TableStyleProps>(
  ({
    containerSpacing = { top: 60, bottom: 0 },
    dynamicHeight,
    onFullScreen,
    listStyle,
    withoutAdaptiveScroll,
    theme,
    inContainer,
  }) => ({
    background: 'white',
    ...(onFullScreen
      ? {
          width: '100%',
          position: 'fixed',
          zIndex: 1200,
          top: 60,
          left: 0,
          right: 0,
          bottom: 0,
          height: `calc( 100vh - ${containerSpacing?.top + containerSpacing?.bottom}px)`,
          maxHeight: '100vh',
        }
      : {
          width: '100%',
          height: dynamicHeight ? `${dynamicHeight}px` : 'auto',
          ['& .MuiDataGrid-virtualScroller']:
            inContainer || withoutAdaptiveScroll
              ? {}
              : {
                  overflowY: 'hidden',
                },
          [`.${gridClasses['scrollbar--vertical']}`]:
            inContainer || withoutAdaptiveScroll
              ? {}
              : {
                  display: 'none',
                },
        }),
    ['.MuiTablePagination-selectLabel']: {
      margin: 0,
    },
    [`.${gridClasses.columnHeaderTitle}`]: {
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
    [`.${gridClasses.menuIcon}`]: {
      position: 'absolute',
      right: 5,
      top: 2,
      bottom: 2,
      marginLeft: 0,
      alignItems: 'center',
      backgroundColor: 'rgba(247, 247, 247, 0.9)',
      [' .MuiSvgIcon-root ']: {
        fill: colors.neutral.darkest,
      },
    },
    [`.${gridClasses.iconButtonContainer}`]: {
      position: 'absolute',
      right: 20,
      top: 2,
      bottom: 2,
      marginLeft: 0,
      alignItems: 'center',
      backgroundColor: 'rgba(247, 247, 247, 0.9)',
      [' .MuiSvgIcon-root ']: {
        fill: colors.neutral.darkest,
      },
    },
    [`.${gridClasses['columnHeader--alignRight']}`]: {
      [`.${gridClasses.menuIcon}`]: {
        left: 5,
        right: 'auto',
      },
      [`.${gridClasses.iconButtonContainer}`]: {
        left: 20,
        right: 'auto',
      },
    },
    [`.${gridClasses['scrollbar--horizontal']}`]: {
      position: 'fixed',
      left: 20,
      right: 20,
      zIndex: 10000000,
      width: 'auto',
    },
    [`.${gridClasses.columnHeader}:focus`]: {
      outline: 'none',
    },
    [`.${gridClasses.columnHeader}.not-bordered`]: {
      borderRight: 0,
      [`& .${gridClasses.columnSeparator}`]: {
        display: 'none',
      },
    },
    [`.${gridClasses.columnHeader}.number`]: {
      textAlign: 'right',
    },
    [`.${gridClasses.columnHeader}.icon`]: {
      borderRight: 0,
      [`& .${gridClasses.columnSeparator}`]: {
        display: 'none',
      },
    },
    [`.${gridClasses.root}`]: {
      [`.${gridClasses.cell}`]: {
        ['&:focus']: {
          ...(listStyle ? { outline: 'none' } : {}),
        },
        borderBottom: `1px solid ${colors.neutral.lighter}`,
        [`&.not-selectable:focus`]: {
          outline: 'none',
        },
        ['&.text, &.number, &.select']: {
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          flexDirection: 'row',
          lineHeight: '1em',
          justifyContent: 'flex-end',
          color: colors.neutral.darker,
          backgroundColor: 'rgba(247, 247, 247, 0.9)',
          ['&.Mui-selected:hover']: {
            backgroundColor: 'rgba(247, 247, 247, 0.9)',
            [`&.${gridClasses['cell--editable']}`]: {
              color: colors.text.dark,
              backgroundColor: 'white',
              ['& .MuiSelect-root']: {
                backgroundColor: 'white',
              },
            },
          },
          [`&.${gridClasses['cell--pinnedLeft']}, &.${gridClasses['cell--pinnedRight']}`]: {
            [`&:not(.${gridClasses['cell--editable']})`]: {
              backgroundColor: 'rgba(247, 247, 247, 1)',
            },
            borderBottom: `1px solid ${colors.neutral.lighter}`,
          },
        },
        [`&.icon`]: {
          borderRight: 0,
          padding: 0,
          justifyContent: 'center',
          backgroundColor: 'rgba(247, 247, 247, 0.9)',
        },
        ['&.text']: {
          justifyContent: 'flex-start',
        },
        ['&.index-column']: {
          backgroundColor: colors.neutral.lightest,
          color: colors.text.medium,
          paddingRight: theme.spacing(1),
          fontSize: '0.75rem',
        },
        [`&.select`]: {
          justifyContent: 'flex-start',
          position: 'relative',
          [`&:not(.${gridClasses['cell--editing']})`]: {
            [`&:after`]: {
              position: 'absolute',
              right: 15,
              content: `""`,
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '0 5px 5px 5px',
              borderColor: 'transparent transparent #585858 transparent',
              transform: 'rotate(180deg)',
            },
          },
          [`&:not(.${gridClasses['cell--editable']})`]: {
            [`&:after`]: {
              display: 'none',
            },
          },
        },
        [`&.Mui-selected`]: {
          [`&.${gridClasses['cell--rangeTop']}`]: {
            borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          },
          [`&.${gridClasses['cell--rangeBottom']}`]: {
            borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          },
          [`&.${gridClasses['cell--rangeLeft']}`]: {
            borderLeft: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          },
          [`&.${gridClasses['cell--rangeRight']}`]: {
            borderRight: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          },
        },
        [`&.${gridClasses['cell--editable']}`]: {
          color: colors.text.dark,
          backgroundColor: 'white',
          ['& .MuiSelect-root']: {
            backgroundColor: 'white',
          },
        },
      },
      [`.${gridClasses.row}`]: {
        ['&:hover']: {
          ...(listStyle ? { cursor: 'pointer' } : { backgroundColor: 'transparent' }),
          [`.${gridClasses.cell}.${gridClasses['cell--pinnedLeft']}`]: {
            backgroundColor: 'rgba(247, 247, 247, 1)',
            [`&.${gridClasses['cell--editable']}`]: {
              color: colors.text.dark,
              backgroundColor: 'white',
            },
            ['&.Mui-selected']: {
              backgroundColor: 'rgba(247, 247, 247, 1)',
              [`&.${gridClasses['cell--editable']}`]: {
                color: colors.text.dark,
                backgroundColor: 'white',
              },
            },
            [`&[data-field="__reorder__"]`]: {
              backgroundColor: 'white',
            },
          },
        },
        ['&.totals, &.totals:hover']: {
          backgroundColor: 'rgba(247, 247, 247, 1) !important',
          fontWeight: 'bold',
          [`& [data-field="__reorder__"]`]: {
            backgroundColor: 'rgba(247, 247, 247, 1) !important',
          },
          [`.${gridClasses.cell}.icon button`]: {
            display: 'none',
          },
        },
      },
    },
    [`.${gridClasses['root--densityCompact']}`]: {
      [`.${gridClasses.cell}`]: {
        fontSize: '0.8rem',
        ['.MuiTypography-root']: {
          fontSize: '0.8rem',
        },
      },
      [`.${gridClasses.columnHeaderTitle}`]: {
        fontSize: '0.75rem',
      },
    },
  }),
);
