import React, { FC, useRef } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import {
  ButtonWithTooltip,
  CollapsedCard,
  ConfirmationModal,
  DateTimePickerWithTimezone,
  Gallery,
  IconButtonWithTooltip,
  InspectionInputsBlock,
  LoadingSkeleton,
  MessagePanel,
  MessagePanelButton,
  PDFViewerNew,
  PermissionDenied,
  ProjectNameLabel,
  RightDrawer,
  ServiceCancelButton,
  ServiceMessage,
  ServiceRelatedDocuments,
  StyledBox,
  SuccessModal,
  TotalInspectionAllowance,
} from '@components';

import { HookState, MessagePanelTabsEnum, PopupTypeEnum } from '@interfaces';
import { useServiceOrderEnterResult } from './controller';
import { isCompletedService } from '@utils';
import { ArrowBackIcon } from '@svgAsComponents';
import { colors } from '@theme';
import {
  useDayJsFormatter,
  useServiceOrderFieldsProperties,
  ServiceFieldsVisibility,
} from '@hooks';
import { ControllerInterface } from './interface';
import { MilestonesTableComponent, ServiceSummary } from './components';
import ServiceInstructions from '../OrderService/components/ServiceInstructions';

const ServiceResults: FC = () => {
  const controller = useServiceOrderEnterResult();
  const {
    state,
    project,
    serviceOrder,
    updateRightDrawer,
    rightMenu,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    goBack,
    shouldShowDeleteButton,
    deleteServiceOrderModal,
    isCurrentProjectArchived,
    handleDeleteServiceOrder,
    confirmCallBack,
    serviceCompletedAt,
    isMutating,
    isViewRestricted,
    isCommentsAvailable,
  } = controller;
  const fieldsProperties = useServiceOrderFieldsProperties({ serviceOrder });
  const { areEqualDates } = useDayJsFormatter();
  if (isViewRestricted) return <PermissionDenied />;
  switch (state) {
    case HookState.LOADING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="service" />
        </StyledBox>
      );
    }

    case HookState.SUCCESS: {
      return (
        <>
          <Stack direction="column" sx={{ height: '100%' }}>
            <Stack sx={{ p: 2 }} direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <IconButtonWithTooltip
                  onClick={goBack}
                  data-cy="service_results__back__icon"
                  tooltipText="Go back"
                >
                  <ArrowBackIcon />
                </IconButtonWithTooltip>
                <Typography sx={{ ml: 2 }} variant="h2">
                  {fieldsProperties['title']?.label}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <ProjectNameLabel />
                {isCommentsAvailable && (
                  <MessagePanelButton
                    commentsPreview={serviceOrder?.comments_preview}
                    handleRightDrawerOpenerClick={updateRightDrawer()}
                    source="service_results"
                  />
                )}
              </Stack>
              <RightDrawer {...rightMenu}>
                <MessagePanel
                  projectId={project?.id}
                  requestId={serviceOrder?.draw_request?.id}
                  serviceOrderId={serviceOrder?.id}
                  source="service_results__comments"
                  tab={MessagePanelTabsEnum.SERVICES}
                  showTabs={false}
                />
              </RightDrawer>
            </Stack>
            <Box
              sx={{
                backgroundColor: colors.background.gray,
                flex: 1,
                p: 3,
              }}
            >
              <ServiceEnterResults controller={controller} fieldsProperties={fieldsProperties} />
            </Box>
            <Stack spacing={2} direction="row" padding={3} justifyContent="flex-end">
              {serviceOrder?.is_cancellable && (
                <ServiceCancelButton serviceOrder={serviceOrder} source="service_results" />
              )}
              {shouldShowDeleteButton && (
                <Button
                  color="error"
                  disabled={isCurrentProjectArchived}
                  onClick={deleteServiceOrderModal.askConfirm}
                  data-cy="service_results__delete__button"
                >
                  Delete
                </Button>
              )}
              {serviceOrder?.is_editable && (
                <ButtonWithTooltip
                  loading={isMutating}
                  onClick={confirmCallBack}
                  disabled={
                    !serviceCompletedAt.isValid ||
                    (isCompletedService(serviceOrder?.status) &&
                      areEqualDates({
                        value1: serviceCompletedAt.value,
                        value2: new Date(serviceOrder?.completed_at),
                        withTime: true,
                      }))
                  }
                  tooltipText="Completion date is required"
                  dataTestName="service_results__confirm__button"
                >
                  {fieldsProperties['confirm_button']?.label}
                </ButtonWithTooltip>
              )}
            </Stack>
          </Stack>
          {isApprovalPopupOpen && (
            <SuccessModal
              text="Service order has been completed."
              open
              onClose={() => {
                setIsApprovalPopupOpen(false);
                goBack();
              }}
            />
          )}

          <ConfirmationModal
            open={deleteServiceOrderModal.isConfirmModalOpened}
            title="Delete service"
            text="Are you sure you want to delete this service?"
            onClose={deleteServiceOrderModal.closeConfirmModal}
            confirmCallback={() =>
              deleteServiceOrderModal.confirmCallback({
                action: async () => {
                  await handleDeleteServiceOrder();
                },
              })
            }
            type={PopupTypeEnum.CONFIRMATION}
            cancelButtonLabel="Close"
          />
        </>
      );
    }
    default:
      return null;
  }
};

const ServiceEnterResults: FC<{
  controller: ControllerInterface;
  fieldsProperties: ServiceFieldsVisibility;
}> = ({ controller, fieldsProperties }) => {
  const {
    serviceOrder,
    updateQueries,
    imageContainer,
    imagePicker,
    activeDocumentId,
    serviceCompletedAt,
    statusChipProps,
    providerStatusChipProps,
    serviceTypeDisplayName,
    editedFieldKey,
    setEditedFieldKey,
    handleUpdateServiceOrderField,
    isMutating,
    isInspectionServiceOrder,
    totalInspectionAllowance,
    updateIsTotalInspectionAllowanceEnabled,
    inspectionFields,
    isTruePicInspection,
  } = controller;
  const containerRef = useRef();
  return (
    <WrapperWithFixedHeight>
      <Box
        sx={{
          overflow: 'scroll',
          maxWidth: { lg: serviceOrder?.result_documents?.length ? '50%' : '80%', xl: '50%' },
          flex: 1,
        }}
        ref={containerRef}
      >
        <Stack spacing={2}>
          <ServiceSummary
            serviceOrder={serviceOrder}
            fieldsProperties={fieldsProperties}
            statusChipProps={statusChipProps}
            providerStatusChipProps={providerStatusChipProps}
            serviceTypeDisplayName={serviceTypeDisplayName}
            editedFieldKey={editedFieldKey}
            setEditedFieldKey={setEditedFieldKey}
            handleUpdateServiceOrderField={handleUpdateServiceOrderField}
            isMutating={isMutating}
          />

          {isInspectionServiceOrder && (
            <CollapsedCard
              header={<Typography variant="h3">Onsite contact information</Typography>}
            >
              <Box sx={{ mx: 2 }}>
                <InspectionInputsBlock
                  source="service_results"
                  inputWidth={6}
                  showRequestedDate
                  showContactsTooltip={false}
                  isTruePicInspection={isTruePicInspection}
                  {...inspectionFields}
                  disabled
                />
              </Box>
            </CollapsedCard>
          )}

          <StyledBox>
            <Stack spacing={2}>
              <Typography variant="h3">General</Typography>
              <Stack spacing={3} sx={{ width: '50%', pt: 1 }}>
                {serviceOrder?.is_editable && (
                  <DateTimePickerWithTimezone
                    label="Date completed"
                    field={serviceCompletedAt}
                    maxDate={new Date()}
                    inputProps={{
                      'data-cy': 'inspection_results__date_picker__input',
                    }}
                    required
                    addTimestamp
                  />
                )}
                {isInspectionServiceOrder && fieldsProperties['inspector_allowance_rate']?.view && (
                  <TotalInspectionAllowance
                    field={totalInspectionAllowance}
                    isEditable={fieldsProperties['inspector_allowance_rate']?.edit}
                    isTotalInspectionAllowanceEnabled={serviceOrder.is_single_value}
                    updateIsTotalInspectionAllowanceEnabled={
                      updateIsTotalInspectionAllowanceEnabled
                    }
                  />
                )}
              </Stack>
            </Stack>
          </StyledBox>

          <ServiceInstructions serviceOrder={serviceOrder} />

          <StyledBox>
            <ServiceRelatedDocuments
              drawRequestId={serviceOrder?.draw_request?.id}
              serviceOrderId={serviceOrder?.id}
              isServiceReports
              uploadRefetchCallback={updateQueries}
              source="service_results__related_documents"
              onDocumentClick={(document) => {
                imagePicker.close();
                imagePicker.open([document]);
              }}
              activeDocumentId={activeDocumentId}
              sx={{ width: '100%', p: 0 }}
              isEditable={serviceOrder?.is_editable}
            />
          </StyledBox>
          <StyledBox>
            <ServiceRelatedDocuments
              drawRequestId={serviceOrder?.draw_request?.id}
              serviceOrderId={serviceOrder?.id}
              uploadRefetchCallback={updateQueries}
              source="service_results__result_documents"
              onDocumentClick={(document) => {
                imagePicker.close();
                imagePicker.open([document]);
              }}
              activeDocumentId={activeDocumentId}
              sx={{ width: '100%', p: 0 }}
              isEditable={serviceOrder?.is_editable}
            />
          </StyledBox>

          {isInspectionServiceOrder && <MilestonesTableComponent />}
        </Stack>
      </Box>
      {Boolean(serviceOrder?.result_documents?.length) && (
        <ServiceReport imagePicker={imagePicker} imageContainer={imageContainer} />
      )}
    </WrapperWithFixedHeight>
  );
};

const ServiceReport = ({ imagePicker, imageContainer }) => {
  return (
    <>
      <Box sx={{ position: 'sticky', zIndex: 99 }} flex={1}>
        <CollapsedCard expandOnMount fullHeight title="Document preview">
          {imagePicker.pdf && (
            <Stack sx={{ height: '67vh' }}>
              <MemoizedPDF file={imagePicker.pdf[0]} />
            </Stack>
          )}
          <Box sx={{ height: imagePicker.gallery && '67vh' }} ref={imageContainer} />
        </CollapsedCard>
      </Box>
      {imagePicker.gallery && (
        <MemoizedGallery container={imageContainer.current} files={imagePicker.gallery} />
      )}
    </>
  );
};

const PDFViewer = ({ file }) => {
  return <PDFViewerNew pdfFile={file} withoutPortal />;
};

const ImageViewer = ({ container, files }) => {
  return <Gallery container={container} startIndex={0} files={files} />;
};

const MemoizedPDF = React.memo(
  PDFViewer,
  (prevProps, nextProps) => prevProps?.file?.id === nextProps?.file?.id,
);

const MemoizedGallery = React.memo(
  ImageViewer,
  (prevProps, nextProps) => prevProps?.files?.[0]?.id === nextProps?.files?.[0]?.id,
);
export default ServiceResults;

const WrapperWithFixedHeight = ({ children }) => {
  return (
    <Stack
      spacing={2}
      direction={{ lg: 'row', xs: 'column' }}
      sx={{
        maxHeight: { lg: 'calc(100vh - 272px)', xs: 'unset' }, // 64+72+88+24+24
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        overflow: { lg: 'scroll', xs: 'unset' },
      }}
    >
      {children}
    </Stack>
  );
};
