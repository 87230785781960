import { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useRequestPaymentData, useSafeSnackbar } from '@hooks';
import {
  IDrawRequestFundingSource,
  PatchDrawRequestParam,
  PaymentConfiguration,
  QueryNamesEnums,
} from '@interfaces';
import { getHookState } from '@utils';
import { patchDrawRequest } from '@globalService';
import { ComponentProps, ControllerInterface } from './interface';
import { useGraphQuery } from '@context';

export const useEditRequestPaymentConfiguration = ({
  drawRequestId,
  onClose,
}: ComponentProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const { getRequestOriginalValuePieChartData, drawRequestFundingSources } =
    useRequestPaymentData();

  const [fundingSources, setFundingSources] = useState<IDrawRequestFundingSource[]>(
    drawRequestFundingSources || [],
  );

  const drawRequest = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_V2,
    keys: ['approved_amount'],
    args: { project_id: projectId, draw_request_id: drawRequestId },
    options: {
      skip: !projectId || !drawRequestId,
    },
  });

  const approvedAmount = useMemo(() => {
    return drawRequest.data?.approved_amount || 0;
  }, [drawRequest.data?.approved_amount]);

  const totalAmount = useMemo(() => {
    return fundingSources.reduce((acc, source) => acc + +source.amount, 0);
  }, [fundingSources]);

  const patchDrawRequestMutation = useMutation<Response, Error, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_V2);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSubmit = async () => {
    if (totalAmount !== +approvedAmount) return;

    await patchDrawRequestMutation.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      sources: fundingSources.map((source) => ({
        id: source.id,
        amount: +source.amount,
      })),
      payment_configuration_type: PaymentConfiguration.PER_DRAW_REQUEST,
    });

    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES);

    onClose();
  };

  return {
    state: getHookState(drawRequest),
    handleSubmit,
    disableSaveButton: totalAmount !== +approvedAmount,
    originalValuePieChartData: getRequestOriginalValuePieChartData(),
    approvedAmount,
    totalAmount,
    fundingSources,
    setFundingSources,
  };
};
