import React, { FC, memo, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { MilestoneListColumnTypeV2 } from './common';
import { IconButtonWithTooltip, UploaderWithForm } from '@components';
import { DrawerContext } from '../drawer';
import { useFilesUploader } from '@hooks';
import { CommonRowType, DocumentContentTypeEnum, TransloaditTemplateEnum } from '@interfaces';
import { AddPhotoIcon } from '@svgAsComponents';

const InspectionPhotoUploader: FC<{ row: CommonRowType }> = ({ row }) => {
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
    progressPhotoTypeId,
  } = useFilesUploader();
  const { inspectionId, projectId } = useParams();
  // if row has an id -> it is milestone id
  const isMilestone = Boolean(row.id);
  const { source } = useContext(DrawerContext);

  const uploadClick = useCallback(
    () =>
      uploadMedia({
        fields: {
          inspection_id: inspectionId,
          project_id: projectId,
          object_id: row.id || inspectionId,
          content_type: DocumentContentTypeEnum.INSPECTION,
        },
        templateType: TransloaditTemplateEnum.PROOFPOINTS,
      }),
    [inspectionId, projectId, row.id, isMilestone],
  );

  const uploader = () => {
    return (
      <UploaderWithForm
        isOpen={isFilesUploaderOpened}
        documentTypes={[]}
        closeUploader={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        restrictions={restrictions}
        source={`${source}__body__inspection_photos`}
        projectId={projectId}
        inspectionId={inspectionId}
        milestoneId={row?.id}
        documentTypeId={progressPhotoTypeId}
      />
    );
  };

  if (!row.canAddPhotos) return null;

  return (
    <>
      <IconButtonWithTooltip
        tooltipText={row.disabled?.reason}
        disabled={row.disabled?.value}
        onClick={uploadClick}
        data-cy={
          source
            ? `${source}__body__photos__upload__icon`
            : `${row.generalRowDataSource}__photos__upload__icon}`
        }
      >
        <AddPhotoIcon />
      </IconButtonWithTooltip>
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
};

const Memoized = memo(InspectionPhotoUploader);

const inspectionPhotoUploader: MilestoneListColumnTypeV2 = {
  field: 'inspection_photos_uploader',
  headerAlign: 'center',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default inspectionPhotoUploader;
