import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const lender_name: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'lender_name',
  graphFields: ['lender_name'],
  headerName: 'Lender',
  minWidth: 120,
  groupable: false,
  aggregable: false,
});

export default lender_name;
