import React, { FC, memo, useContext, useMemo } from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { DocumentsIconWithIndicator, PhotoGalleryIconWithIndicator } from '@svgAsComponents';
import { CommonRowType, RequestTableTabsEnum } from '@interfaces';
import { IconButtonWithTooltip, VerticalMenu } from '@components';
import { colors } from '@theme';
import { SettingsContext } from '@context';
import { DrawerContext } from '../drawer';
import { getContentTooltipText } from '@utils';

const DocumentsPhotosMenu: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(DrawerContext);
  const { isPHBProject } = useContext(SettingsContext);
  const docTooltipText = useMemo(
    () => getContentTooltipText(row.documents_preview, 'documents'),
    [row.documents_preview],
  );

  const photoTooltipText = useMemo(
    () => getContentTooltipText(row.documents_preview, 'photos'),
    [row.documents_preview],
  );

  const menuItems = useMemo(
    () => [
      {
        action: updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneSubmitId: row?.milestone_submit_id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.DOCUMENTS,
            })
          : row?.updateRightDrawer?.(),
        text: 'Documents',
        icon: (
          <IconButtonWithTooltip tooltipText={docTooltipText} sx={{ p: 0 }}>
            <DocumentsIconWithIndicator
              hasDocuments={row.documents_preview?.has_docs}
              hasUnreadDocuments={row.documents_preview?.has_not_seen_docs}
            />
          </IconButtonWithTooltip>
        ),
        dataTestName: `${source}__body__documents_gallery__menu_item`,
      },
      ...(isPHBProject
        ? []
        : [
            {
              action: updateRightDrawer({
                tab: RequestTableTabsEnum.PHOTOS,
                milestoneId: row?.id,
                milestoneSubmitId: row?.milestone_submit_id,
                milestoneName: row?.name,
              }),
              text: 'Progress photos',
              icon: (
                <IconButtonWithTooltip tooltipText={photoTooltipText} sx={{ p: 0 }}>
                  <PhotoGalleryIconWithIndicator
                    color={colors.icons.green}
                    hasImages={row.documents_preview?.has_photos}
                    hasUnseenImages={row.documents_preview?.has_not_seen_photos}
                  />
                </IconButtonWithTooltip>
              ),
              dataTestName: `${source}__body__photos_gallery__menu_item`,
            },
          ]),
    ],
    [row, updateRightDrawer, source, isPHBProject],
  );
  if (row.id === 'totals') return null;

  const tooltipText = useMemo(
    () => getContentTooltipText(row.documents_preview, 'combined'),
    [row.documents_preview],
  );

  return (
    <VerticalMenu menuItems={menuItems}>
      <IconButtonWithTooltip
        data-cy={`${source}__body__documents_gallery__icon`}
        tooltipText={tooltipText}
      >
        <DocumentsIconWithIndicator
          hasDocuments={row.documents_preview?.has_docs || row.documents_preview?.has_photos}
          hasUnreadDocuments={
            row.documents_preview?.has_not_seen_docs || row.documents_preview?.has_not_seen_photos
          }
        />
      </IconButtonWithTooltip>
    </VerticalMenu>
  );
};

const Memoized = memo(DocumentsPhotosMenu);

const documentsPhotosGalleryMenu: MilestoneListColumnTypeV2 = {
  field: 'documents_photos_gallery_menu',
  headerAlign: 'center',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default documentsPhotosGalleryMenu;
