import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useSafeSnackbar } from '@hooks';
import {
  IDrawRequest,
  IInspection,
  IServiceOrder,
  QueryNamesEnums,
  UpdateInspectionPayload,
  IServiceOrderPayload,
} from '@interfaces';
import {
  getProjectDrawRequestsList,
  patchInspectionToProject,
  patchServiceOrderToProject,
} from '@globalService';
import { isDrawRequest, isInspectionService } from '@utils';
import { useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  drawRequestId: string;
  drawRequests: IDrawRequest[];
  saveInspectionRequest: () => void;
  unlinkInspectionRequest: () => void;
  handleChange: (event: SelectChangeEvent) => void;
  renderValueFn: (value: string) => string;
  open: boolean;
  setOpen: (open: boolean) => void;
  labelText: string;
  isMutating: boolean;
}

export const useLinkRequestToServicePicker = (
  serviceOrder: IInspection | Pick<IServiceOrder, 'draw_request' | 'service_type' | 'id'>,
): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [drawRequestId, setDrawRequestId] = useState<string>(serviceOrder?.draw_request?.id);
  const [open, setOpen] = useState<boolean>(false);
  const { projectId } = useParams();

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: !!projectId },
  );

  const handleChange = (event: SelectChangeEvent) => {
    setDrawRequestId(event.target.value);
  };

  const drawRequests = useMemo(() => {
    return drawRequestsQuery.data?.results?.filter((x) => isDrawRequest(x)) || [];
  }, [drawRequestsQuery.data]);

  const updateInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
          { projectId, drawRequestId: serviceOrder?.draw_request?.id },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: serviceOrder?.draw_request?.id },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_V2,
          { project_id: projectId, draw_request_id: serviceOrder?.draw_request?.id },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
          { projectId, inspectionId: serviceOrder?.id },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_INSPECTIONS_LIST);
        enqueueSnackbar(
          variables?.inspectionData?.draw_request ? 'Inspection linked' : 'Inspection unlinked',
          { variant: 'success' },
        );
        setOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId: serviceOrder?.draw_request?.id },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_V2,
        { project_id: projectId, draw_request_id: serviceOrder?.draw_request?.id },
      ]);
      // TODO: delete this after ENG_9657_show_inspections_in_services is released
      if (variables?.json?.draw_request) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
          { projectId, drawRequestId: variables?.json?.draw_request },
        ]);
      }
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
        { projectId, serviceOrderId: serviceOrder?.id },
      ]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId }]);
      queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
      // TODO **end of delete
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
        { project_id: projectId, service_order_id: serviceOrder?.id },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS,
        { project_id: projectId },
      ]);
      if (variables?.json?.draw_request) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
          { project_id: projectId, draw_request_id: variables?.json?.draw_request },
        ]);
      }
      queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
      enqueueSnackbar(variables?.json?.draw_request ? 'Service linked' : 'Service unlinked', {
        variant: 'success',
      });
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const saveInspectionRequest = useCallback(async () => {
    if (
      isInspectionService(serviceOrder.service_type) &&
      !flags?.['ENG_9657_show_inspections_in_services']
    ) {
      await updateInspection.mutateAsync({
        projectId,
        inspectionData: { inspectionId: serviceOrder.id, draw_request: drawRequestId },
      });
    } else {
      await updateServiceOrder.mutateAsync({
        projectId,
        serviceOrderId: serviceOrder?.id,
        json: { draw_request: drawRequestId },
      });
    }
  }, [drawRequestId, flags?.['ENG_9657_show_inspections_in_services']]);

  const unlinkInspectionRequest = useCallback(async () => {
    if (
      isInspectionService(serviceOrder.service_type) &&
      !flags?.['ENG_9657_show_inspections_in_services']
    ) {
      await updateInspection.mutateAsync({
        projectId,
        inspectionData: { inspectionId: serviceOrder.id, draw_request: null },
      });
    } else {
      await updateServiceOrder.mutateAsync({
        projectId,
        serviceOrderId: serviceOrder.id,
        json: { draw_request: null },
      });
    }
    setDrawRequestId('');
  }, [drawRequestId, flags?.['ENG_9657_show_inspections_in_services']]);

  const renderValueFn = useCallback(
    (value) => {
      const drawRequest = drawRequests.find((x) => x.id === value);
      return drawRequest ? `Draw ${drawRequest?.counter_per_request_type}` : 'Not linked';
    },
    [drawRequests],
  );

  const labelText = useMemo(
    () => (drawRequestsQuery.isFetching ? '' : renderValueFn(serviceOrder?.draw_request?.id)),
    [drawRequestsQuery],
  );

  return {
    drawRequests,
    saveInspectionRequest,
    unlinkInspectionRequest,
    handleChange,
    drawRequestId,
    renderValueFn,
    open,
    setOpen,
    labelText,
    isMutating: updateInspection.isLoading || updateServiceOrder.isLoading,
  };
};
