import React, { FC } from 'react';
import { Button, Grid2, Stack, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ButtonWithTooltipOnClick,
  CenteredStyledBox,
  LabelAndValue,
  ServiceRelatedDocuments,
  StyledBox,
  SuccessModal,
  ProjectNameLabel,
  IconButtonWithTooltip,
  InspectionInputsBlock,
  CollapsedCard,
} from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { ServiceTypeEnum } from '@interfaces';
import { ArrowBackIcon } from '@svgAsComponents';
import { ServiceTypeSelection, ServiceProviderSelection, ServiceInstructions } from './components';
import { getServiceTypeDisplayName, isInspectionService } from '@utils';

const OrderServiceComponent = () => {
  const { projectId, serviceOrderId } = useParams();

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceOrder,
    handleServiceOrderRequest,
    isSubmitting,
    isSubmitDisabled,
    isSuccessModalOpen,
    commentField,
    serviceType,
    setServiceType,
    inspectionFields,
    isTruePicInspection,
    disabledInspectionTooltipText,
    serviceTypesMap,
    getLeavePageConfirmModal,
    isUpdated,
    defaultInspectionAgency,
    showDisclaimer,
    goBack,
  } = Controller.useService({
    projectId,
    serviceOrderId,
  });

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ px: { md: 3, xs: 2 }, height: 84 }}
        >
          <IconButtonWithTooltip
            tooltipText="Go back"
            onClick={goBack}
            data-cy="add_borrower_page__back__icon"
          >
            <ArrowBackIcon />
          </IconButtonWithTooltip>
          <Typography variant="h1">Order service</Typography>
        </Stack>
        <ProjectNameLabel showStatusChip />
      </Stack>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          pt: 3,
          flex: 1,
          alignItems: 'center',
        }}
        spacing={2}
      >
        <Stack sx={{ flex: 1, width: '100%', alignItems: 'center' }}>
          <Stack justifyContent="space-between" sx={{ flex: 1 }}>
            <Stack spacing={2}>
              <CollapsedCard
                header={<Typography variant="h3">Service type</Typography>}
                value={getServiceTypeDisplayName({
                  serviceTypesMap,
                  serviceType,
                })}
              >
                <CenteredStyledBox>
                  <Box sx={{ width: '100%' }}>
                    <ServiceTypeSelection
                      selectedType={serviceType}
                      onTypeSelect={(type) => {
                        setServiceType(type);
                      }}
                      disabledInspectionTooltipText={disabledInspectionTooltipText}
                    />
                  </Box>
                </CenteredStyledBox>
              </CollapsedCard>
              <ServiceProviderSelection
                serviceAgenciesList={serviceAgenciesList}
                serviceAgenciesListIsLoading={serviceAgenciesListIsLoading}
                agencySelected={agencySelected}
                setAgencySelected={setAgencySelected}
                defaultInspectionAgency={
                  isInspectionService(serviceType) ? defaultInspectionAgency : null
                }
              />
              <Grid2 container spacing={1} justifyContent="space-between">
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <StyledBox flex={1}>
                    {serviceOrder?.draw_request?.id && (
                      <LabelAndValue
                        label="Draw request"
                        text={`#${serviceOrder?.draw_request?.number}`}
                      />
                    )}
                  </StyledBox>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <StyledBox flex={1}>
                    <LabelAndValue
                      label="Address"
                      text={serviceOrder?.project?.address?.full_address}
                    />
                  </StyledBox>
                </Grid2>
              </Grid2>
              {serviceType === ServiceTypeEnum.INSPECTION && (
                <CenteredStyledBox>
                  <Box sx={{ mt: 2 }}>
                    <InspectionInputsBlock
                      source="order_inspection_dialog"
                      inputWidth={6}
                      showRequestedDate
                      isTruePicInspection={isTruePicInspection}
                      {...inspectionFields}
                      disabled={!!disabledInspectionTooltipText}
                    />
                  </Box>
                </CenteredStyledBox>
              )}

              <ServiceInstructions serviceOrder={serviceOrder} commentField={commentField} />

              {serviceOrder?.id && agencySelected && (
                <ServiceRelatedDocuments
                  drawRequestId={serviceOrder?.draw_request?.id}
                  serviceOrderId={serviceOrder?.id}
                  serviceType={serviceType}
                  source="order_service__related_documents"
                />
              )}

              {showDisclaimer && (
                <CenteredStyledBox>
                  <Typography variant="body3">
                    <span style={{ color: colors.status.information.medium, fontWeight: 700 }}>
                      Disclaimer.{' '}
                    </span>
                    By clicking "Order" below, I agree and acknowledge that I am ordering a product
                    or service from a third-party provider ("Provider"), not TrustPoint. I
                    understand and agree that this order is subject to the Provider's, and not
                    TrustPoint's, Terms and Conditions, which can be found{' '}
                    <a
                      href="https://www.trustpoint.ai/third-party-terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    . I certify that I have read and understood the Provider's Terms and Conditions,
                    and I agree to abide by, and be bound by, the Provider's Terms and Conditions.
                  </Typography>
                </CenteredStyledBox>
              )}
              {isSuccessModalOpen && (
                <SuccessModal
                  text="Service has been ordered."
                  open
                  buttons={<ModalBackToProjectServicesButtons projectId={projectId} />}
                />
              )}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              backgroundColor: colors.background.white,
              mt: 2,
              padding: '1.5rem',
              width: '100%',
            }}
          >
            <Button
              variant="new"
              sx={{ mr: '0.5rem' }}
              onClick={goBack}
              data-cy="order_service__cancel__button"
            >
              Cancel
            </Button>
            <ButtonWithTooltipOnClick
              onClick={handleServiceOrderRequest}
              loading={isSubmitting}
              disabled={isSubmitting || !serviceOrder?.id || !agencySelected}
              conditionallyDisabled={isSubmitDisabled}
              dataTestName="order_service__submit__button"
              tooltipText={!isUpdated ? 'No changes to save' : undefined}
            >
              {isSubmitting ? 'Ordering...' : 'Order'}
            </ButtonWithTooltipOnClick>
          </Stack>
        </Stack>
      </Stack>
      {getLeavePageConfirmModal()}
    </Stack>
  );
};

export default OrderServiceComponent;

const ModalBackToProjectServicesButtons: FC<{
  projectId: string;
}> = ({ projectId }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row">
      <Button
        variant="new"
        color="secondary"
        onClick={() => navigate(`/projects/${projectId}/services/`)}
        data-cy="order_service_modal_back_to_project_button"
      >
        Go to project
      </Button>
      <Button
        variant="new"
        color="secondary"
        onClick={() => navigate(`/services/`)}
        sx={{ ml: '8px' }}
        data-cy="order_service_modal_back_to_services_button"
      >
        Go to services list
      </Button>
    </Stack>
  );
};
