import React from 'react';
import { Stack, Typography } from '@mui/material';
import { StyledBox } from '@components';

interface OptionDescriptionProps {
  item: {
    label: string;
    logo?: string;
    description?: string;
  };
}

export const OptionDescription: React.FC<OptionDescriptionProps> = ({ item }) => {
  return (
    <StyledBox>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{item.label}</Typography>
        {item.logo && (
          <img
            alt="service agency logo"
            src={item.logo}
            style={{ minHeight: '50px', maxHeight: '50px' }}
          />
        )}
      </Stack>

      <Typography
        variant="body1"
        sx={{ mt: 1, fontSize: '14px' }}
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </StyledBox>
  );
};
