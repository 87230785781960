import React, { FC, useContext } from 'react';
import { Button, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

import { SettingsContext } from '@context';
import { useUpdateUiSettings } from '@hooks';
import { WarningIcon } from '@svgAsComponents';
import { ActionButton } from '../MilestoneList/columnsV2/common';

const CustomErrorTooltip: FC<{
  params: GridRenderCellParams;
  actionButtons?: Array<ActionButton>;
  persistTooltip?: boolean;
  errorForCurrentRow?: string;
  api: React.MutableRefObject<GridApiPremium>;
  hasChanged: boolean;
  setHasChanged: (hasChanged: boolean) => void;
}> = ({
  params,
  actionButtons,
  persistTooltip,
  errorForCurrentRow,
  api,
  hasChanged,
  setHasChanged,
}) => {
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const autoOpenErrorTooltip =
    persistTooltip && !settings?.personal_setting?.prevent_auto_error_tooltip;
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Tooltip
      key={autoOpenErrorTooltip ? 'tooltip-open' : 'tooltip-closed'}
      {...(autoOpenErrorTooltip
        ? {
            open: hasChanged || isHovered,
            onClose: () => {
              setIsHovered(false);
              setHasChanged(false);
            },
          }
        : null)}
      componentsProps={{
        popper: {
          sx: {
            '& .MuiTooltip-tooltip': {
              maxWidth: 500,
              py: 1.5,
            },
          },
        },
      }}
      title={
        <Stack>
          <Typography
            variant="label"
            dangerouslySetInnerHTML={{ __html: errorForCurrentRow }}
            sx={{ whiteSpace: 'pre-line' }}
          ></Typography>
          {!!actionButtons?.length && (
            <Stack
              direction="row"
              justifyContent={persistTooltip ? 'space-between' : 'flex-end'}
              alignItems="center"
              mt={2}
              sx={{ width: '100%' }}
            >
              {persistTooltip && (
                <Stack direction="row" alignItems="center" flexShrink={0}>
                  <Checkbox
                    sx={{ ml: -1.5 }}
                    size="small"
                    onChange={(e) => {
                      updateSettings({
                        ...settings,
                        personal_setting: {
                          ...settings?.personal_setting,
                          prevent_auto_error_tooltip: e.target.checked,
                        },
                      });
                    }}
                    defaultChecked={settings?.personal_setting?.prevent_auto_error_tooltip}
                    data-cy="error-notification-checkbox"
                  />
                  <Typography variant="caption">Don't show again</Typography>
                </Stack>
              )}
              <Stack direction="row" spacing={1} flexShrink={0}>
                {actionButtons.map(
                  ({ label, onClickCustom, hideButton, ...buttonProps }, index) => {
                    if (hideButton?.(params.row)) return null;
                    return (
                      <Button
                        key={index.toString()}
                        {...buttonProps}
                        onClick={() => onClickCustom(api, params.row)}
                      >
                        {label}
                      </Button>
                    );
                  },
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack onMouseEnter={() => setIsHovered(true)} sx={{ cursor: 'pointer', p: 0.5 }}>
        <WarningIcon size={14} />
      </Stack>
    </Tooltip>
  );
};

export default CustomErrorTooltip;
