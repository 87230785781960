import * as React from 'react';
import {
  DataGridPremium,
  GridColumnsPanel,
  GridDragIcon,
  GridToolbarProps,
  FooterPropsOverrides,
} from '@mui/x-data-grid-premium';
import { Tooltip } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import useDataTable from './hooks/useDataTable';
import { TableStyle } from './styled';
import CustomGridColumnsPanel from './components/GridColumnsPanel';
import CustomToolbar from './components/CustomToolbar';
import CustomFooter from './components/CustomFooter';
import { withSettingsProtect } from './components/withSettingProtect';
import { DataTableV3Interface } from './interface';

interface CustomToolbarProps extends GridToolbarProps {
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  dotsMenu?: React.ReactNode;
  withBuiltInSearch?: boolean;
  clearSettings?: () => void;
  onFullScreen?: boolean;
  setOnFullScreen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataTableV3 = ({
  controllerLoading,
  columns,
  rows,
  tableKey,
  rowUpdateApi,
  bulkUpdateApi,
  totals,
  errors,
  headerLeft,
  headerRight,
  footerLeft,
  footerRight,
  withCustomColumnPicker,
  withBuiltInSearch = true,
  onRowClick,
  dotsMenu,
  withoutAdaptiveScroll,
  rowReordering,
  onSortModelChange,
  getTreeDataPath,
  groupingColDef,
  withSingleClickHandle,
  withColumnIndex,
  containerSpacing,
  withProgressiveColumns,
  disableRowSelectionOnClick,
  checkboxSelection,
  selectedRowId,
  inContainer,
  containerRef: masterContainerRef,
}: DataTableV3Interface) => {
  const {
    initialSettings,
    apiRef,
    saveCurrentSet,
    clearSettings,
    isCellEditable,
    processRowUpdate,
    memoColumns,
    containerHeight,
    rowsReorder,
    tableLoadingBlock,
    onClipboardPasteStart,
    onClipboardPasteEnd,
    onBeforeClipboardPasteStart,
    renderRows,
    containerRef,
    onFullScreen,
    setOnFullScreen,
    cellModesModel,
    onCellModesModelChange,
    onCellClick,
    onSortModelChangeLocal,
    canRowsReorder,
  } = useDataTable({
    withoutAdaptiveScroll,
    tableKey,
    errors,
    rowUpdateApi,
    rows,
    totals,
    columns,
    bulkUpdateApi,
    rowReordering,
    withColumnIndex,
    withSingleClickHandle,
    withProgressiveColumns,
    selectedRowId,
    inContainer,
    masterContainerRef,
  });

  return (
    <TableStyle
      id="table-v3"
      listStyle={!!onRowClick}
      ref={containerRef}
      onFullScreen={onFullScreen}
      dynamicHeight={containerHeight}
      containerSpacing={containerSpacing}
      withoutAdaptiveScroll={withoutAdaptiveScroll}
      inContainer={inContainer}
    >
      <DataGridPremium
        key={tableKey}
        checkboxSelection={checkboxSelection}
        initialState={{ ...initialSettings }}
        ignoreValueFormatterDuringExport
        loading={tableLoadingBlock || controllerLoading}
        onRowOrderChange={rowsReorder}
        rowReordering={!!rowReordering}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        isCellEditable={isCellEditable}
        processRowUpdate={processRowUpdate}
        onBeforeClipboardPasteStart={onBeforeClipboardPasteStart}
        onClipboardPasteStart={onClipboardPasteStart}
        onClipboardPasteEnd={onClipboardPasteEnd}
        onColumnWidthChange={() => saveCurrentSet()}
        onPinnedColumnsChange={() => saveCurrentSet()}
        onColumnOrderChange={() => saveCurrentSet(true)}
        onColumnVisibilityModelChange={() => saveCurrentSet()}
        onDensityChange={() => saveCurrentSet()}
        onSortModelChange={onSortModelChange || onSortModelChangeLocal}
        {...(withSingleClickHandle
          ? {
              cellModesModel,
              onCellModesModelChange,
            }
          : {})}
        getRowClassName={(params) => `${params.row['id']}`}
        cellSelection={!onRowClick}
        hideFooter={!footerLeft && !footerRight}
        apiRef={apiRef}
        columns={memoColumns}
        rows={renderRows}
        localeText={{
          toolbarDensity: '',
          toolbarColumns: '',
        }}
        treeData={!!getTreeDataPath}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        slotProps={{
          loadingOverlay: {
            noRowsVariant: 'skeleton',
          },
          panel: {
            keepMounted: true,
            anchorEl: () => document.getElementById('columnMenuButton'),
            placement: 'right-start',
          },
          toolbar: {
            headerLeft,
            headerRight,
            dotsMenu,
            withBuiltInSearch,
            clearSettings,
            onFullScreen,
            setOnFullScreen,
            listStyle: !!onRowClick,
          } as unknown as CustomToolbarProps,
          footer: {
            footerRight,
            footerLeft,
          },
          columnsManagement: {
            disableResetButton: true,
          },
          basePopper: {
            sx: {
              [' .MuiPaper-root']: {
                maxHeight: '90vh',
                flexDirection: 'column',
              },
            },
          },
        }}
        slots={{
          rowReorderIcon: canRowsReorder
            ? GridDragIcon
            : () => (
                <Tooltip title={'Reset sorting to reorder line items.'}>
                  <GridDragIcon />
                </Tooltip>
              ),
          toolbar: CustomToolbar as React.ComponentType<GridToolbarProps>,
          columnsPanel: withCustomColumnPicker ? CustomGridColumnsPanel : GridColumnsPanel,
          footer: CustomFooter as React.ComponentType<
            React.HTMLAttributes<HTMLDivElement> & {
              sx?: SxProps<Theme>;
            } & FooterPropsOverrides
          >,
        }}
        pinnedRows={{
          bottom: totals ? [totals] : [],
        }}
      />
    </TableStyle>
  );
};

export default withSettingsProtect(DataTableV3);
