import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const loan_external_id: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'loan.external_id',
  graphFields: [],
  graphNested: {
    loan: ['external_id'],
  },
  headerName: 'Loan #',
  minWidth: 130,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) => row?.loan?.external_id,
});

export default loan_external_id;
