import React from 'react';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const loan_type: ColumnType<IProjectsPortfolioItem> = ({ loan_types }) => ({
  field: 'loan.type',
  graphNested: {
    loan: ['type'],
  },
  headerName: 'Loan type',
  minWidth: 50,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: ({ row }) => {
    const loanTypeNameDisplay = (loan_types || [])?.find(
      (type) => type.name === row.loan?.type,
    )?.name_display;
    return <span data-cy={`projects__list__loan_type`}>{loanTypeNameDisplay}</span>;
  },
});

export default loan_type;
