import { QueryNamesEnums, PermissionNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { getServiceAgencyNameByRole } from '@utils';

export const serviceProviderV2: FilterObject = {
  title: 'Services provider', // general name for all services
  filterKey: 'service_agency',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: false, // url is not updated because we use display_name instead of id
  needToUpdateSetting: false, // setting is not updated because we use display_name instead of id
  // pagination here is disabled because later we filter the resutl
  // to have a unique list of service providers and it's unpredictable how many items we will have
  // pagination on scroll does not work if the list length is exactly 10 items
  getDataParamsPaginated: ({ q, skip, id }) => ({
    type: QueryNamesEnums.GET_SERVICE_AGENCIES,
    keys: ['id', 'display_name', 'service_provider'],
    args: { pagination: null, q, id },
    options: {
      skip,
    },
  }),
  strictSerialize: (data, permissions) => {
    if (!data?.pages.length || !data?.pages[0]) return [];

    const filteredData = data.pages
      ?.flatMap((page) =>
        page?.results.map((item) => {
          const label = getServiceAgencyNameByRole({ serviceAgency: item, permissions });
          return {
            value: item.display_name,
            label,
          };
        }),
      )
      .filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));

    return filteredData;
  },
  cypressSelector: 'filters__services_list_agency',
  withPagination: true,
};
