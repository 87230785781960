import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { LabelAndValue, LabelAndValueWithPreload, RequestApproveSummaryBlock } from '@components';
import {
  checkIsReallocateComplete,
  checkIsReallocateCompleteByLender,
  currencyFormatter,
  isDrawRequest,
} from '@utils';
import { WarningIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { useApproveSummary } from './controller';
import { useLaunchDarklyFlags } from '@context';

const ApproveSummary: FC<{ drawRequestId: string; projectId: string }> = ({
  drawRequestId,
  projectId,
}) => {
  const flags = useLaunchDarklyFlags();
  const controller = useApproveSummary({ drawRequestId, projectId });
  const {
    drawRequest,
    isApprovedRequest,
    amountToDisburseIsLoading,
    amountToDisburse,
    drawRequestOnHoldText,
    hasDrawRequestErrors,
  } = controller;

  return (
    <>
      <Stack alignItems="flex-start">
        {drawRequest && (
          <Typography role="heading" variant="h4" sx={{ mb: 1 }}>
            {`${isDrawRequest(drawRequest) ? 'Draw' : 'Change'} request #${
              drawRequest?.counter_per_request_type || ''
            }`}
          </Typography>
        )}
        {isApprovedRequest && (
          <Stack sx={{ width: '100%' }}>
            <LabelAndValueWithPreload
              label="Net funds to disburse"
              isLoading={amountToDisburseIsLoading}
              text={currencyFormatter(amountToDisburse)}
            />
          </Stack>
        )}

        {!isApprovedRequest && (
          <RequestApproveSummaryBlock projectId={projectId} drawRequestId={drawRequestId} />
        )}
        {drawRequest?.is_on_hold && (
          <Stack sx={{ width: '100%', pt: 2 }}>
            <LabelAndValue
              noWrap
              marginRightAfterLabel={false}
              justifyContent="flex-start"
              label={drawRequestOnHoldText}
              colorLabel={colors.status.error.medium}
              icon={<WarningIcon size={14} />}
              iconPosition="left"
            />
            <Typography
              variant="body3"
              dangerouslySetInnerHTML={{ __html: drawRequest?.is_on_hold_change_reason }}
            />
          </Stack>
        )}

        {Boolean(drawRequest) &&
          (flags?.['ENG_9511_increase_budget_from_funding_source']
            ? hasDrawRequestErrors
            : !checkIsReallocateCompleteByLender(drawRequest) ||
              !checkIsReallocateComplete(drawRequest)) && (
            <Stack sx={{ width: '100%', pt: 1 }}>
              <LabelAndValue
                noWrap
                marginRightAfterLabel={false}
                label="Something doesn’t add up. You need to update"
                colorLabel={colors.status.error.medium}
                icon={<WarningIcon size={14} />}
                iconPosition="left"
                iconDataTestName="request__action_popup__summary__error_icon"
                labelDataTestName="request__action_popup__summary__error_text"
              >
                <Button
                  variant="new"
                  component={Link}
                  onClick={(e) => e.stopPropagation()}
                  to={`/projects/${projectId}/draws/draw-requests/${drawRequest?.id}`}
                  color="secondary"
                  size="small"
                >
                  Check request
                </Button>
              </LabelAndValue>
            </Stack>
          )}
      </Stack>
    </>
  );
};

export default ApproveSummary;
