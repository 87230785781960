import React from 'react';
import { Grid2, Stack, Tooltip, Typography, Box } from '@mui/material';
import { ServiceTypeEnum } from '@interfaces';

import {
  AppraisalIcon,
  BuilderReportIcon,
  FeasibilityIcon,
  InspectionIcon,
  PermitIcon,
  ServicePriceIcon,
  TitleIcon,
} from '@svgAsComponents';
import { Card } from '@components';
import { colors } from '@theme';

interface ServiceTypeSelectionProps {
  selectedType: ServiceTypeEnum;
  onTypeSelect: (type: ServiceTypeEnum) => void;
  disabledInspectionTooltipText: string;
}

// TODO : move to constants / order service button
const serviceTypes = [
  {
    type: ServiceTypeEnum.INSPECTION,
    label: 'Inspection',
    icon: InspectionIcon,
    description: 'Ensure construction is on track before approving disbursements.',
    dataCy: 'inspection__button',
  },
  {
    type: ServiceTypeEnum.TITLE,
    label: 'Title',
    icon: TitleIcon,
    description: 'Confirm ownership and uncover any hidden liens.',
    dataCy: 'title__button',
  },
  {
    type: ServiceTypeEnum.FEASIBILITY,
    label: 'Feasibility',
    icon: FeasibilityIcon,
    description: 'Assess project viability and reduce lending risk.',
    dataCy: 'feasibility__button',
  },
  {
    type: ServiceTypeEnum.PERMIT_VERIFICATION_REPORT,
    label: 'Permit Verification',
    icon: PermitIcon,
    description: 'Avoid delays by confirming required permits are secured.',
    dataCy: 'permit_verification__button',
  },
  {
    type: ServiceTypeEnum.BUILDER_INFORMATION_REPORT,
    label: 'Builder Information',
    icon: BuilderReportIcon,
    description: "Get insights into the builder's experience and reliability.",
    dataCy: 'builder_information__button',
  },
  {
    type: ServiceTypeEnum.BROKER_PRICE_OPINION,
    label: 'Broker Price Opinion',
    icon: ServicePriceIcon,
    description: 'Obtain a market-driven property valuation from real estate expert.',
    dataCy: 'broker_price_opinion__button',
  },
  {
    type: ServiceTypeEnum.APPRAISAL,
    label: 'Appraisal',
    icon: AppraisalIcon,
    description: 'Assess loan collateral and property value.',
    dataCy: 'appraisal__button',
  },
];

export const ServiceTypeSelection: React.FC<ServiceTypeSelectionProps> = ({
  selectedType,
  onTypeSelect,
  disabledInspectionTooltipText,
}) => {
  return (
    <Grid2 container spacing={2}>
      {serviceTypes.map((service) => {
        const Icon = service.icon;
        const isDisabled =
          service.type === ServiceTypeEnum.INSPECTION && disabledInspectionTooltipText;
        return (
          <Grid2
            size={{ xs: 12, sm: 6, md: 4 }}
            key={service.type}
            data-cy={`order_service__${service.dataCy}`}
          >
            <Tooltip title={isDisabled ? disabledInspectionTooltipText : ''}>
              <Box
                onClick={() => !isDisabled && onTypeSelect(service.type)}
                sx={{ cursor: isDisabled ? 'default' : 'pointer' }}
              >
                <Card
                  sx={{
                    borderRadius: 1,
                    border:
                      selectedType === service.type
                        ? `1px solid ${colors.main.primary.light}`
                        : `1px solid ${colors.neutral.lighter}`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    spacing: 0.5,
                    padding: '24px 16px',
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icon size={24} color={colors.icons.green} />
                    <Typography variant="body1SemiBold">{service.label}</Typography>
                  </Stack>

                  <Typography variant="body3">{service.description}</Typography>
                </Card>
              </Box>
            </Tooltip>
          </Grid2>
        );
      })}
    </Grid2>
  );
};
