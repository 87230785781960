import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { IServiceOrder } from '@interfaces';
import { CollapsedCard, StyledBox, WysiwygEditor } from '@components';
import { StringFieldModel } from '@models';
type ServiceInstructionsProps = {
  serviceOrder: Pick<IServiceOrder, 'draw_request' | 'comment'>;
  commentField?: StringFieldModel;
};

const ServiceInstructions: FC<ServiceInstructionsProps> = ({ serviceOrder, commentField }) => {
  if (commentField)
    return (
      <CollapsedCard
        expandOnMount
        header={<Typography variant="h3">Special instructions</Typography>}
      >
        <StyledBox>
          {serviceOrder?.draw_request?.submitted_inspection_comment && (
            <Stack sx={{ mb: 2 }}>
              <Typography variant="label">Comment from borrower (Internal)</Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: serviceOrder?.draw_request?.submitted_inspection_comment,
                }}
              />
            </Stack>
          )}

          <WysiwygEditor editField={commentField} source="order_service__comments" />
        </StyledBox>
      </CollapsedCard>
    );

  if (serviceOrder?.comment) {
    return (
      <CollapsedCard header={<Typography variant="h3">Special instructions</Typography>}>
        <StyledBox>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: serviceOrder.comment }} />
        </StyledBox>
      </CollapsedCard>
    );
  }
  return null;
};

export default ServiceInstructions;
