import { useState, useEffect, ReactElement, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  isInspectionService,
  isServiceProviderManual,
  isTruePicInspection,
  Override,
} from '@utils';
import { ServiceTypeEnum, IServiceOrder, IServiceAgency } from '@interfaces';
import { useStringFieldModel, StringFieldModel } from '@models';
import { useServiceQueriesAndMutations } from './utils';
import { useInspectionFields, useLeavePageBlocker } from '@hooks';

export type ControllerInterface = Override<
  ReturnType<typeof useService>,
  {
    serviceAgenciesList: IServiceAgency[];
    serviceAgenciesListIsLoading: boolean;
    agencySelected: IServiceAgency | null;
    setAgencySelected: (value: IServiceAgency) => void;
    setServiceType: (value: ServiceTypeEnum) => void;
    serviceOrder: IServiceOrder;
    handleServiceOrderRequest: () => void;
    isSubmitting: boolean;
    isSubmitDisabled: boolean;
    isSuccessModalOpen: boolean;
    commentField: StringFieldModel;
    inspectionFields: {
      additionalContactName: string;
      additionalContactPhone: string;
      accessCode: string;
      primaryContactUserList: any[];
      isBorrowerUsersLoading: boolean;
      inspectionRequestedAt: string;
      primaryContactUser: any;
      primaryContactPhone: string;
      isContactListHasUsersWithPhone: boolean;
    };
    isTruePicInspection: boolean;
    inspectionDisabledTooltipText: string;
    serviceTypesMap: Record<string, string>;
    getLeavePageConfirmModal: () => ReactElement;
    isUpdated: boolean;
    defaultInspectionAgency: Pick<IServiceAgency, 'id' | 'display_name' | 'service_provider'>;
    showDisclaimer: boolean;
  }
>;

interface CustomizedState {
  serviceType: ServiceTypeEnum;
}

export const useService = ({
  projectId,
  serviceOrderId,
}: {
  projectId: string;
  serviceOrderId: string;
}) => {
  const [agencySelected, setAgencySelected] = useState<IServiceAgency | null>(null);
  const location = useLocation();
  const state = location?.state as CustomizedState;
  const [serviceType, setServiceType] = useState<ServiceTypeEnum>(
    state?.serviceType || ServiceTypeEnum.INSPECTION,
  );
  const commentField = useStringFieldModel({
    initValue: '',
  });
  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
    isProjectInspectionSettingsUpdated,
  } = useInspectionFields({});

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    patchServiceOrder,
    serviceOrder,
    isSuccessModalOpen,
    disabledInspectionTooltipText,
    serviceTypesMap,
    deleteServiceOrderMutation,
    defaultInspectionAgency,
    updateInfoProject,
    goBack,
  } = useServiceQueriesAndMutations({
    projectId,
    serviceOrderId,
    serviceType,
  });

  useEffect(() => {
    if (serviceOrder.data?.draw_request?.submitted_inspection_comment) {
      commentField.setValue(serviceOrder.data?.draw_request?.submitted_inspection_comment);
    }
    if (serviceOrder.data?.draw_request?.submitted_inspection_requested_at) {
      inspectionRequestedAt.setInitValue(
        serviceOrder.data?.draw_request?.submitted_inspection_requested_at,
      );
    }
  }, [serviceOrder.data]);

  // ?TODO: move it to utils
  const isUpdated = useMemo(() => {
    return (
      commentField.value !== serviceOrder.data?.draw_request?.submitted_inspection_comment ||
      (agencySelected?.id !== null &&
        (isInspectionService(serviceType)
          ? agencySelected?.id !== defaultInspectionAgency?.id
          : true)) ||
      isProjectInspectionSettingsUpdated ||
      inspectionRequestedAt.value !==
        serviceOrder.data?.draw_request?.submitted_inspection_requested_at
    );
  }, [
    commentField.value,
    agencySelected?.id,
    isProjectInspectionSettingsUpdated,
    serviceType,
    defaultInspectionAgency,
    inspectionRequestedAt.value,
    serviceOrder.data?.draw_request?.submitted_inspection_requested_at,
    serviceOrder.data?.draw_request?.submitted_inspection_comment,
  ]);

  const { getLeavePageConfirmModal, setTriggerExit, isLeaveModalOpen } = useLeavePageBlocker({
    currentPagePathname: '/services/*/order',
    confirmTitle: 'Exit ordering service',
    isUpdated,
  });

  const handleDeleteServiceOrder = () => {
    deleteServiceOrderMutation.mutateAsync({ projectId, serviceOrderId });
  };

  const handleServiceOrderRequest = async () => {
    const json = {
      service_type: serviceType,
      service_agency: agencySelected?.id,
      ...(commentField.value ? { comment: commentField.value } : {}),
      ...(inspectionRequestedAt?.value ? { requested_at: inspectionRequestedAt.value } : {}),
    };

    if (isProjectInspectionSettingsUpdated) {
      await updateInfoProject.mutateAsync({
        projectId,
        json: {
          inspection_additional_contact_name: additionalContactName.value,
          inspection_additional_contact_phone: additionalContactPhone.valueToSave,
          inspection_entry_access_code: accessCode.value,
          inspection_primary_contact_user_id: primaryContactUser.value?.id || null,
        },
      });
    }
    await patchServiceOrder.mutateAsync({ projectId, serviceOrderId, json });

    setTriggerExit(() => ({
      path: `/projects/${projectId}/services/`,
      isNavigationConfirmed: true,
    }));
  };

  useEffect(() => {
    if (isLeaveModalOpen) {
      setTriggerExit({
        path: `/projects/${projectId}/services/`,
        isNavigationConfirmed: false,
        confirmCallbackFn: handleDeleteServiceOrder,
      });
    }
  }, [isLeaveModalOpen]);

  const showDisclaimer = useMemo(() => {
    return !isServiceProviderManual(agencySelected?.service_provider);
  }, [agencySelected]);

  return {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceType,
    setServiceType,
    serviceOrder: serviceOrder.data,
    handleServiceOrderRequest,
    isSubmitting: patchServiceOrder.isLoading,
    isSubmitDisabled: !agencySelected || patchServiceOrder.isLoading,
    isSuccessModalOpen,
    commentField,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactUser,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
    },
    isTruePicInspection: isTruePicInspection(agencySelected?.service_provider),
    disabledInspectionTooltipText,
    serviceTypesMap,
    getLeavePageConfirmModal,
    isUpdated,
    defaultInspectionAgency,
    showDisclaimer,
    goBack,
  };
};
