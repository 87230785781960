import { useLocation } from 'react-router-dom';

interface MessageLinkParams {
  isMessageLink: boolean;
  params: Record<string, any>;
}

export const useMessageLinkParams = (): MessageLinkParams => {
  const location = useLocation();
  const state = location?.state as { isMessageLink: boolean; params: any } | undefined;
  const { isMessageLink = false, params = {} } = state || {};

  return {
    isMessageLink,
    params,
  };
};
