import React, { useContext } from 'react';
import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { Link, matchPath, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import {
  Budget,
  MessagePanel,
  RightDrawer,
  StyledBox,
  TabSwitcher,
  ToolTipLine,
} from '@components';
import { colors } from '@theme';
import PaymentsTab from './PaymentsTab';
import {
  Documents,
  DrawsTab,
  OverviewTab,
  Photos,
  PhotosV2,
  Policies,
  ProjectMainInfo,
  ProjectMainInfoV2,
  ServicesTab,
  // ServicesTab,
} from './components';
import { DocumentTabEnums, HookState } from '@interfaces';
import { isMobileOnly } from 'react-device-detect';
import { DotIcon } from '@svgAsComponents';
import { useProject } from './controller';
import { useGetActionTabs } from './utils';
import { ProductionBuildBudget } from './../productionBuild';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import ServicesTabV2 from './components/ServicesTabV2';
const Project = () => {
  const { isPHBProject } = useContext(SettingsContext);
  const { projectId } = useParams();
  const {
    TABS,
    TABS_LIST,
    DRSwitcherTabs,
    activeDrawRequestId,
    activeDrTab,
    handleActiveDRTabChange,
    state,
    handleRightDrawerOpenerClick,
    rightMenu,
    tabLinkPath,
    isActiveRequestHistorical,
  } = useProject(projectId);

  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const pathBase = `/projects/${projectId}`;

  const tabCircle = useGetActionTabs();
  const flags = useLaunchDarklyFlags();

  // !include here the tabs where you want to see DR switcher
  const showDRSwitcher =
    [
      TABS.DOCUMENTS.value,
      TABS.DRAWS.value,
      TABS.PAYMENTS.value,
      TABS.PHOTOS.value,
      TABS.POLICIES.value,
      TABS.SERVICES.value,
    ].includes(match?.params?.tab) &&
    Boolean(DRSwitcherTabs.length) &&
    !isMobileOnly;

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      {flags?.['ENG_9657_show_inspections_in_services'] ? (
        <ProjectMainInfoV2 handleRightDrawerOpenerClick={handleRightDrawerOpenerClick} />
      ) : (
        <ProjectMainInfo handleRightDrawerOpenerClick={handleRightDrawerOpenerClick} />
      )}
      {!isMobileOnly && (
        <Box
          sx={{
            mt: '.5rem',
            position: 'relative',
            '& .MuiTabs-root': { height: '60px' },
          }}
        >
          <Tabs
            value={
              TABS_LIST.some((tab) => tab.value === match?.params?.tab)
                ? match?.params?.tab
                : TABS.OVERVIEW.value
            }
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
          >
            {TABS_LIST.map((tab) => (
              <Tab
                key={tab.value}
                data-cy={`project_tab_${tab.value}`}
                label={tab.label}
                value={tab.value}
                component={Link}
                to={tabLinkPath(tab.value)}
                icon={tab.hasActionIcon ? <ActionIcon showIcon={tabCircle[tab.label]} /> : null}
                iconPosition="end"
              />
            ))}
          </Tabs>
        </Box>
      )}
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 2 }}>
        {showDRSwitcher &&
          (state === HookState.SUCCESS ? (
            <>
              <StyledBox sx={{ mb: 2, p: 0 }}>
                <TabSwitcher
                  tabs={DRSwitcherTabs}
                  onTabChange={handleActiveDRTabChange}
                  activeTab={activeDrTab}
                  source="project__tab_switcher"
                />
              </StyledBox>
              {isActiveRequestHistorical && (
                <Box mb={2}>
                  <ToolTipLine
                    text="This is a historical request and may have limited data"
                    typographyVariant="body2"
                    fullWidth
                    dataTestName="draw_historical_text"
                  />
                </Box>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" width="100%" height={72} sx={{ mb: 2 }} />
          ))}
        <Routes>
          <Route path={`${TABS.OVERVIEW.value}`} element={<OverviewTab />} />
          <Route
            path={`${TABS.BUDGET.value}`}
            element={isPHBProject ? <ProductionBuildBudget /> : <Budget />}
          />
          <Route
            path={`${TABS.DRAWS.value}/*`}
            element={<DrawsTab drawRequest={activeDrawRequestId} />}
          />
          <Route
            path={`${TABS.DOCUMENTS.value}/*`}
            element={
              <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0 }}>
                <Documents
                  source="documents_tab__documents"
                  isAllDocumentsTab={activeDrTab === DocumentTabEnums.ALL}
                />
              </StyledBox>
            }
          />
          {flags?.['ENG_9657_show_inspections_in_services'] ? (
            <Route path={`${TABS.SERVICES.value}/*`} element={<ServicesTabV2 />} />
          ) : (
            <Route path={`${TABS.SERVICES.value}/*`} element={<ServicesTab />} />
          )}
          <Route path={`${TABS.PAYMENTS.value}/*`} element={<PaymentsTab />} />
          {flags?.['ENG_9658_new_photo_tab'] ? (
            <Route path={`${TABS.PHOTOS.value}/*`} element={<PhotosV2 />} />
          ) : (
            <Route path={`${TABS.PHOTOS.value}/*`} element={<Photos />} />
          )}
          <Route path={`${TABS.POLICIES.value}/*`} element={<Policies />} />
        </Routes>
      </Stack>

      <RightDrawer {...rightMenu}>
        <MessagePanel projectId={projectId} source={'project__comments'} />
      </RightDrawer>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.OVERVIEW.value}`} replace />
  );
};

const ActionIcon = ({ showIcon }) => {
  return showIcon ? (
    <Stack sx={{ position: 'relative', bottom: '14px' }}>
      <DotIcon size={8} color={colors.icons.green} />
    </Stack>
  ) : null;
};
export default Project;
