import { useContext, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  ErrorDual,
  QueryNamesEnums,
  IServiceOrderPayload,
  IServiceOrder,
  CommonServiceOrderPayload,
  ProjectUpdatePayload,
} from '@interfaces';
import {
  patchServiceOrderToProject,
  deleteServiceOrder,
  scheduleServiceOrderToProject,
  updateProjectFields,
} from '@globalService';
import { AuthContext, useGraphQuery, PermissionsContext, SettingsContext } from '@context';
import { checkIsCustomerSuccess, getTeamRole } from '@utils';
import {
  useSafeSnackbar,
  useServiceOrderStatusInvalidation,
  useLastActiveProjectInspection,
} from '@hooks';

export const useServiceQueriesAndMutations = ({ projectId, serviceOrderId, serviceType }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const isCustomerSuccess = checkIsCustomerSuccess(teamRole);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();

  const handleServiceOrderChangeInvalidation = useServiceOrderStatusInvalidation();

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  const serviceAgenciesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_SERVICE_AGENCIES,
    keys: ['id', 'display_name', 'logo', 'description', 'is_premium', 'service_provider'],
    args: { service_type: serviceType },
  });

  const { disabledInspectionTooltipText } = useLastActiveProjectInspection({
    projectId,
    permissions,
  });

  const goBack = () => {
    navigate(`/projects/${projectId}/services/`);
  };

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const deleteServiceOrderMutation = useMutation<
    Response,
    Error,
    { projectId: string; serviceOrderId: string }
  >(deleteServiceOrder, {
    onSuccess: () => {
      goBack();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const serviceOrderQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
    args: { project_id: projectId, service_order_id: serviceOrderId },
    keys: ['id', 'status', 'is_editable', 'service_type', 'service_agency', 'comment'],
    nested: {
      service_agency: ['display_name', 'service_provider', 'id'],
      draw_request: [
        'id',
        'number',
        'submitted_inspection_comment',
        'submitted_inspection_requested_at',
        'status',
      ],
      project: ['id', 'name'],
    },
    options: {
      skip: !serviceOrderId,
    },
  });

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['service_order_inspection_agency'],
    nested: {
      service_order_inspection_agency: ['id', 'display_name', 'service_provider'],
    },
    args: { project_id: projectId },
  });

  const patchServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: async (data: IServiceOrder) => {
      await handleSchedulingServiceOrder(data);
    },
    onError: (error, variables) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleServiceOrderChangeInvalidation({
        projectId,
        serviceOrderId: variables?.serviceOrderId,
        drawRequestId: variables?.json?.draw_request,
      });
    },
  });

  const scheduleServiceOrder = useMutation<Response, ErrorDual, CommonServiceOrderPayload>(
    scheduleServiceOrderToProject,
    {
      onSuccess: () => {
        setSuccessModalOpen(true);
      },
      onError: () => {
        const errorText = isCustomerSuccess
          ? 'Project is not configured properly, please check required fields'
          : 'Project is not configured properly, please ask Customer Success for help';
        enqueueSnackbar(errorText, { variant: 'error' });
      },
      onSettled: (_data, _error, variables) => {
        handleServiceOrderChangeInvalidation({
          projectId,
          serviceOrderId: variables?.serviceOrderId,
        });
      },
    },
  );

  const handleSchedulingServiceOrder = async (serviceOrder: IServiceOrder) => {
    await scheduleServiceOrder.mutateAsync({
      projectId,
      serviceOrderId: serviceOrder?.id,
    });
  };

  const updateInfoProject = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return {
    serviceAgenciesList: serviceAgenciesQuery.data?.results,
    serviceAgenciesListIsLoading: serviceAgenciesQuery.isLoading,
    patchServiceOrder,
    deleteServiceOrderMutation,
    isSuccessModalOpen,
    serviceOrder: serviceOrderQuery,
    disabledInspectionTooltipText,
    serviceTypesMap,
    defaultInspectionAgency: project.data?.service_order_inspection_agency,
    updateInfoProject,
    goBack,
  };
};
