import React from 'react';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';
import { statusMap } from '@constants';
import { StatusChip } from '@components';

const status: ColumnType<IProjectsPortfolioItem> = ({ settingsDisplay }) => ({
  field: 'status',
  renderCell: ({ row }: { row: Partial<IProjectsPortfolioItem> }) => {
    const projectStatusValue = statusMap(row.status, settingsDisplay, 'project');
    return (
      <span>
        <StatusChip
          color={projectStatusValue.color}
          backgroundColor={projectStatusValue.backgroundColor}
          label={projectStatusValue.text}
        />
      </span>
    );
  },
  graphFields: ['status'],
  headerName: 'Project status',
  minWidth: 120,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default status;
