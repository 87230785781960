import React from 'react';
import { DrawRequestStatusChip } from '@components';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const draw_request_status: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'draw_request.status',
  graphNested: {
    draw_request: [
      'status',
      'is_resubmit',
      'is_resubmit_change_reason',
      'is_on_hold',
      'is_on_hold_change_reason',
    ],
  },
  headerName: 'Request status',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => <DrawRequestStatusChip drawRequest={row} />,
});

export default draw_request_status;
