import { FunctionArguments, FunctionReturnType, NestedKeys } from '@interfaces';
import { dataProviders } from '../provider';
import { useMemo, useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import useGraph from './useGraph';
import config from '@config';

export function useGraphInfiniteQuery<
  P extends keyof typeof dataProviders,
  ReturnType = FunctionReturnType<(typeof dataProviders)[P]>,
  IsResultsType = ReturnType extends { results: unknown[] } ? true : false,
  IsArrayType = ReturnType extends Array<unknown> ? true : false,
  DataType = IsResultsType extends true
    ? ReturnType extends { results: (infer R)[] }
      ? R
      : never
    : IsArrayType extends true
      ? ReturnType extends Array<infer R>
        ? R
        : never
      : ReturnType,
  K extends keyof DataType = keyof DataType,
  O extends { skip?: boolean } = {
    skip?: boolean;
  },
  A extends FunctionArguments<(typeof dataProviders)[P]> = FunctionArguments<
    (typeof dataProviders)[P]
  >,
>({
  type,
  keys,
  args,
  nested,
  options,
}: {
  type: P;
  keys?: K[];
  nested?: NestedKeys<DataType>;
  args: A;
  options?: O;
}) {
  const queryClient = useQueryClient();
  const queryKey = useMemo<[P, any]>(() => {
    if (args && 'pagination' in args) {
      const { pagination, ...rest } = args;
      return [type, rest];
    }
    return [type, args];
  }, [type, args]);
  const shortKey = useMemo<string>(() => JSON.stringify(queryKey), [queryKey]);
  const { graphReady } = useGraph(queryKey, {
    keys: keys as string[],
    nested: nested as Record<string, string[]>,
  });

  const { dataUpdatedAt, ...queryData } = useInfiniteQuery(
    queryKey,
    queryClient.getGraphBoundFunction(queryKey),
    {
      enabled: graphReady && !options?.skip,
      meta: { args },
      getNextPageParam: (lastPage: any) => {
        if (!lastPage?.next) return undefined;
        return {
          nextPageImportant: lastPage.next.toString().replace(`${config.api}/`, ''),
        };
      },
    },
  );

  useEffect(() => {
    if (!options?.skip) {
      queryClient.graphRegister(
        queryKey,
        {
          keys: keys as string[],
          nested: nested as Record<string, string[]>,
        },
        {
          automaticReady: true,
        },
      );
    }
  }, [options?.skip, shortKey]);

  return queryData;
}
