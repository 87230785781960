import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import find from 'lodash/find';

import {
  DateValidationEnum,
  IInspection,
  IMilestone,
  IRightDrawerParams,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestTableTabsEnum,
  ServiceOrderStatusEnum,
  TableKeyEnum,
  UpdateInspectionPayload,
} from '@interfaces';
import { useDateFieldModel, useStringFieldModel } from '@models';
import {
  getInspectionDocuments,
  getInspectionMilestones,
  getProjectInspectionById,
  patchInspectionToProject,
} from '@globalService';
import {
  getHookState,
  getInspectionNameByRole,
  getTeamRole,
  isAllowed,
  isCompletedService,
  isTruePicInspection,
  checkIsInvestor,
} from '@utils';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useInspectionFields,
  useLineItemsFilter,
  useRightMenu,
  useSafeSnackbar,
  useServiceOrderFieldsProperties,
  useDayJsFormatter,
} from '@hooks';
import {
  AuthContext,
  PermissionsContext,
  SettingsContext,
  useGraphQuery,
  useLaunchDarklyFlags,
} from '@context';
import { LineItemFilterValues, onlyProgressPhotos } from '@constants';
import {
  getInspectionTotals,
  getInitColumns,
  getStatusChipProps,
  getFilterOptions,
  updateAllQueries,
  getProviderStatusChipProps,
} from './utils';

export const useInspectionEnterResult = () => {
  const { state } = useLocation();
  const { getInitialValue, getDateValidationRule } = useDayJsFormatter();
  const navigate = useNavigate();
  const { inspectionId, projectId, action } = useParams();
  const { user } = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const queryClient = useQueryClient();
  const imagePicker = useImagePicker();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [editedFieldKey, setEditedFieldKey] = useState<string>('');
  const teamRole = getTeamRole(user);
  const flags = useLaunchDarklyFlags();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'name', 'address', 'comments_preview', 'status', 'status_change_reason'],
    args: { project_id: projectId },
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
  } = useInspectionFields({});

  const inspectionCompletedAt = useDateFieldModel({
    initValue: getInitialValue({ addTimestamp: true }),
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS_OR_EQUAL,
        maxDate: new Date(),
      }),
  });

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isApprovalPopupOpen, setIsApprovalPopupOpen] = useState<boolean>(false);
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({
    projectId,
    inspectionId,
  });

  const imageContainer = useRef();

  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.INSPECTION_RESULTS,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    inspectionId,
  });

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const updateRightDrawer = useCallback(
    ({ tab }: { tab?: RequestTableTabsEnum }) =>
      () => {
        handleRightDrawerOpenerClick({
          title: tab ? 'Inspection' : 'Comments',
        });
        setRightDrawerParams({
          tab,
          projectId,
          inspectionId,
        });
      },
    [projectId, inspectionId, handleRightDrawerOpenerClick],
  );

  const queryInspectionFields = useMemo(() => {
    const key = find(defaultOptions, { filterValue })?.totalKey;
    const query = `{${[
      'id',
      'status',
      'service_number',
      'inspection_agency{display_name,service}',
      'invoiced_amount',
      'provider_order_id',
      'provider_service_cost',
      'ordered_at',
      'completed_at',
      'ready_for_review_at',
      'draw_request{id,number}',
      'provider_status_display',
      'error_msg',
      'gc_comment',
      'comment',
      'reports',
      'documents_preview',
      'comments_preview',
      `totals{${key}{previous_inspector_allowance_rate,inspector_allowance_rate,requested_amount,revised_estimate,borrower_completion_rate}}`,
      'transaction_id',
      'gc_requested_at',
      'service_type',
      'project{id}',
      'inspector_allowance',
      'is_single_value',
    ].join(',')}}`;
    return query;
  }, [filterValue]);

  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId, query: queryInspectionFields },
      ],
      queryFn: getProjectInspectionById.bind(this, {
        projectId,
        inspectionId,
        query: queryInspectionFields,
      }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
        { projectId, inspectionId, query: onlyProgressPhotos },
      ],
      queryFn: getInspectionDocuments.bind(this, {
        projectId,
        inspectionId,
        query: onlyProgressPhotos,
      }),
    },
  ]);

  // TODO: add restql here?
  const inspectionMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId, filterKey }],
    getInspectionMilestones.bind(this, { projectId, inspectionId, filterKey }),
    { enabled: Boolean(projectId && inspectionId) },
  );
  const inspection = useMemo(() => requestedDataQueries[0].data, [requestedDataQueries[0].data]);
  const fieldsProperties = useServiceOrderFieldsProperties({ serviceOrder: inspection });

  const inspectionPhotos = useMemo(
    () => requestedDataQueries[1].data?.results,
    [requestedDataQueries[1].data?.results],
  );
  const inspectionMilestones = useMemo(
    () =>
      inspectionMilestonesQuery.data?.results.map((item) => ({
        ...item,
        canAddPhotos: fieldsProperties['inspection_allowance']?.edit,
      })),
    [inspectionMilestonesQuery.data?.results, fieldsProperties],
  );

  const totalInspectionAllowance = useStringFieldModel({
    initValue: inspection?.inspector_allowance?.rate?.toString(),
    withProgressCheck: true,
  });

  useEffect(() => {
    if (inspection?.completed_at) {
      inspectionCompletedAt.setValue(new Date(inspection.completed_at));
    } else if (inspection?.ready_for_review_at) {
      inspectionCompletedAt.setValue(new Date(inspection.ready_for_review_at));
    }
    if (inspection?.gc_requested_at) {
      inspectionRequestedAt.setValue(getInitialValue({ date: inspection.gc_requested_at }));
    }
  }, [inspection?.completed_at, inspection?.ready_for_review_at, inspection?.gc_requested_at]);

  useEffect(() => {
    if (inspectionRequestedAt.value) {
      inspectionRequestedAt.setValid(true); //it's historical date here, so we don't need to validate it
    }
  }, [inspectionRequestedAt.value]);

  const updateQueries = useCallback(() => {
    updateAllQueries(queryClient, {
      projectId,
      inspectionId,
      drawRequestId: inspection?.draw_request?.id,
      requestedDataQueries,
    });
  }, [queryClient, projectId, inspectionId, inspection, requestedDataQueries]);

  const confirmInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        updateQueries();
        setIsApprovalPopupOpen(true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
      },
    },
  );

  const updateInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        setEditedFieldKey('');
        updateQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const confirmCallBack = useCallback(async () => {
    if (inspectionCompletedAt.validate()) {
      await confirmInspection.mutateAsync({
        projectId,
        inspectionData: {
          inspectionId,
          status: ServiceOrderStatusEnum.COMPLETED,
          completed_at: inspectionCompletedAt.value,
          ...(inspection?.is_single_value &&
            flags?.['ENG_10009_inspection_lump_sum'] && {
              inspector_allowance_rate: totalInspectionAllowance.value,
            }),
        },
      });
    }
  }, [
    projectId,
    inspectionId,
    inspectionCompletedAt,
    totalInspectionAllowance.value,
    flags?.['ENG_10009_inspection_lump_sum'],
    inspection?.is_single_value,
  ]);

  const openFile = useCallback(() => {
    if (inspection?.reports?.length) {
      imagePicker.open([inspection.reports?.[0]]);
    }
  }, [inspection?.reports]);

  useEffect(() => {
    imagePicker.close();
    openFile();
  }, [inspection?.reports]);

  const isInspectionLinkedToDR = useMemo(() => Boolean(inspection?.draw_request?.id), [inspection]);

  const initColumns = useMemo(() => {
    const isNewTable =
      flags?.[`ENG_7895_table_v3__${TableKeyEnum.INSPECTION_ENTER_RESULTS}`] ||
      flags?.[`ENG_7895_table_v3__${TableKeyEnum.INSPECTION_RESULTS}`];

    const isEditable = fieldsProperties['inspection_allowance']?.edit;
    const showInspectorRate =
      !fieldsProperties['inspector_allowance_rate']?.view || !inspection?.is_single_value;
    if (!isNewTable) {
      return getInitColumns({
        isEditable,
        isInspectionLinkedToDR,
        showInspectorRate,
      });
    }

    return [
      'nameV2',
      ...(isEditable ? ['previousInspectorAllowanceRate'] : []),
      ...(showInspectorRate ? ['inspectorAllowanceRate'] : []),
      ...(isInspectionLinkedToDR ? ['borrowerCompletionRate', 'requestedAmount'] : []),
      'revisedMilestoneAmount',
      'inspectionPhotoUploader',
      'inspectionPhotosV2',
      'comments',
    ];
  }, [
    fieldsProperties['inspection_allowance']?.edit,
    isInspectionLinkedToDR,
    inspection?.is_single_value,
    fieldsProperties['inspector_allowance_rate']?.view,
    flags?.[`ENG_7895_table_v3__${TableKeyEnum.INSPECTION_ENTER_RESULTS}`],
    flags?.[`ENG_7895_table_v3__${TableKeyEnum.INSPECTION_RESULTS}`],
  ]);

  const goBack = useCallback(
    () => navigate(state || `/projects/${projectId}/services/all/`),
    [state],
  );

  const inspectionName = getInspectionNameByRole({
    teamRole,
    inspectionAgency: inspection?.inspection_agency,
  });

  const totals = useMemo(
    () => getInspectionTotals(inspection, filterValue, defaultOptions),
    [inspection, filterValue, defaultOptions],
  );

  const statusChipProps = useMemo(
    () => getStatusChipProps(inspection, settings),
    [inspection, settings],
  );

  const providerStatusChipProps = useMemo(
    () => getProviderStatusChipProps(inspection, settings),
    [inspection, settings],
  );

  const handleUpdateServiceOrderField = useCallback(
    async (fieldKey, value) => {
      await updateInspection.mutateAsync({
        projectId,

        inspectionData: {
          inspectionId,
          [fieldKey]: value,
          ...(fieldKey === 'status' &&
          isCompletedService(value) &&
          inspectionCompletedAt.value &&
          inspectionCompletedAt.isValid
            ? { completed_at: inspectionCompletedAt.value }
            : {}),
        },
      });
    },
    [projectId, inspectionId, inspectionCompletedAt],
  );

  const isUserHasEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  const isCommentsAvailable = useMemo(
    () => isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions) && !checkIsInvestor(teamRole),
    [permissions, teamRole],
  );
  const isViewRestricted = useMemo(() => {
    return !isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions);
  }, [permissions]);

  const updateIsTotalInspectionAllowanceEnabled = useCallback(
    (value: boolean) => {
      handleUpdateServiceOrderField('is_single_value', value);
    },
    [handleUpdateServiceOrderField],
  );

  return {
    state: getHookState(requestedDataQueries[0]),
    inspectionCompletedAt,
    inspection,
    inspectionRefetch: updateQueries,
    project: project.data,
    showPreview,
    setShowPreview,
    confirmCallBack,
    imagePicker,
    imageContainer,
    openFile,
    initColumns,
    goBack,
    milestones: inspectionMilestones || [],
    drawRequestNumber: inspection?.draw_request?.number,
    inspectionPhotos,
    inspectionName,
    rightDrawerParams,
    rightMenu,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange: handleFilterClick,
    filterOptions: getFilterOptions(defaultOptions),
    isMilestoneMutatingOrFetching,
    isInspectionLinkedToDR,
    activeDocumentId: imagePicker.pdf?.[0]?.id || imagePicker.gallery?.[0]?.id,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    statusChipProps,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUser,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
    },
    isTruePicInspection: isTruePicInspection(inspection?.inspection_agency?.service),
    isCommentsAvailable,
    providerStatusChipProps,
    editedFieldKey,
    setEditedFieldKey,
    handleUpdateServiceOrderField,
    isUserHasEditPermission,
    isMutating: confirmInspection.isLoading || updateInspection.isLoading,
    isViewRestricted,
    fieldsProperties,
    totalInspectionAllowance,
    updateIsTotalInspectionAllowanceEnabled,
    action,
  };
};
