import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const addressState: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'address.state',
  graphNested: {
    address: ['state'],
  },
  headerName: 'State',
  minWidth: 90,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) => row?.address?.state,
  flex: 1,
});

export default addressState;
