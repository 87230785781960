import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getDrawRequestMilestoneListColumns } from '@globalService';
import { IMilestoneColumn, QueryNamesEnums } from '@interfaces';
import isEmpty from 'lodash/isEmpty';
import { useGraphQuery } from '@context';

export const useDrawRequestErrors = ({
  projectId,
  drawRequestId,
}: {
  projectId: string;
  drawRequestId: string;
}) => {
  const drawRequest = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_V2,
    keys: ['errors'],
    args: { project_id: projectId, draw_request_id: drawRequestId },
    options: {
      skip: !projectId || !drawRequestId,
    },
  });

  const drawRequestMilestonesColumns = useQuery<IMilestoneColumn[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS, { projectId, requestId: drawRequestId }],
    getDrawRequestMilestoneListColumns.bind(this, projectId, drawRequestId),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const hasDrawRequestErrors = useMemo(() => {
    return (
      !isEmpty(drawRequest?.data?.errors) ||
      drawRequestMilestonesColumns.data?.some((column) => column.icon_type === 'ERROR')
    );
  }, [drawRequestMilestonesColumns.data, drawRequest?.data?.errors]);

  return { hasDrawRequestErrors };
};
