import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button, Stack } from '@mui/material';
import {
  ButtonWithTooltip,
  CustomCurrencyTextField,
  CustomPercentTextField,
  LabelAndValue,
  LoadingSkeleton,
  PieChartItem,
  Popup,
  ToolTipLine,
} from '@components';
import * as Controller from './controller';
import { ComponentProps } from './interface';
import { calculateAmount, calculateFraction, currencyFormatter, validationShareRule } from '@utils';
import { HookState, IDrawRequestFundingSource } from '@interfaces';
import { useStringFieldModel } from '@models';

const FundingSourceRow = ({
  source,
  approvedAmount,
  setFundingSources,
}: {
  source: IDrawRequestFundingSource;
  approvedAmount: number;
  setFundingSources: Dispatch<SetStateAction<IDrawRequestFundingSource[]>>;
}) => {
  const attrSource = 'request__payment_configuration';

  const amountField = useStringFieldModel({
    initValue: source.amount.toString(),
    validationRule: (value) => {
      return +value <= +approvedAmount && +value >= 0 && +value <= source.used;
    },
    validateOnChange: true,
    initError: `${source.name} amount must be between $0 and ${currencyFormatter(Math.min(approvedAmount, source.used))}`,
  });

  const fraction = useStringFieldModel({
    initValue: calculateFraction(source.amount, approvedAmount)?.toString(),
    validationRule: validationShareRule,
    validateOnChange: true,
  });

  return (
    <Stack direction="row" spacing={2}>
      <Stack sx={{ flex: 1 }} spacing={0.25}>
        <CustomCurrencyTextField
          label={`${source.name} ($)`}
          field={amountField}
          onChange={(e) => {
            amountField.setValue(e.target.value);
            fraction.setValue(calculateFraction(+e.target.value, approvedAmount)?.toString());
            setFundingSources((prev: IDrawRequestFundingSource[]) =>
              prev.map((fundingSource) =>
                fundingSource.id === source.id
                  ? { ...fundingSource, amount: Number(e.target.value) }
                  : fundingSource,
              ),
            );
          }}
          inputProps={{ 'data-cy': `${attrSource}__${source.name}__input` }}
          validationText={amountField.errorTip}
        />
        {!source.internal_identifier && (
          <ToolTipLine
            typographyVariant="label"
            text={`Available: ${currencyFormatter(source.used)}`}
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-start',
              pl: 1,
            }}
            size="small"
          ></ToolTipLine>
        )}
      </Stack>

      <Stack sx={{ flex: 1 }}>
        <CustomPercentTextField
          label={`${source.name} (%)`}
          field={fraction}
          onChange={(e) => {
            fraction.setValue(e.target.value);
            const amount = calculateAmount(e.target.value, approvedAmount);
            amountField.setValue(amount?.toString());
            setFundingSources((prev: IDrawRequestFundingSource[]) =>
              prev.map((fundingSource) =>
                fundingSource.id === source.id
                  ? { ...fundingSource, amount: Number(amount) }
                  : fundingSource,
              ),
            );
          }}
          inputProps={{ 'data-cy': `${attrSource}__${source.name}_fraction__input` }}
          validationText={fraction.errorTip}
        />
      </Stack>
    </Stack>
  );
};

const RequestPaymentConfigurationEditPopup: FC<ComponentProps> = ({ onClose, drawRequestId }) => {
  const controller = Controller.useEditRequestPaymentConfiguration({
    drawRequestId,
    onClose,
  });
  const {
    handleSubmit,
    disableSaveButton,
    originalValuePieChartData,
    approvedAmount,
    totalAmount,
    state,
    fundingSources,
    setFundingSources,
  } = controller;

  return (
    <Popup open maxWidth="md" title="Edit payment contribution for this draw">
      <Stack sx={{ width: '100%', mt: 3 }}>
        {state === HookState.LOADING && <LoadingSkeleton type="twoBlocks" />}
        {state === HookState.SUCCESS && (
          <>
            <Stack spacing={3}>
              <PieChartItem data={originalValuePieChartData} />
              {fundingSources.map((source) => (
                <FundingSourceRow
                  key={source.id}
                  source={source}
                  approvedAmount={approvedAmount}
                  setFundingSources={setFundingSources}
                />
              ))}
              <LabelAndValue
                label="Total amount:"
                text={currencyFormatter(totalAmount)}
                justifyContent="flex-start"
              />
              <LabelAndValue
                label="Approved amount:"
                text={currencyFormatter(approvedAmount)}
                justifyContent="flex-start"
              />
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7.5 }}>
              <Button
                variant="text"
                onClick={onClose}
                data-cy="request__payment_configuration__cancel__button"
              >
                Cancel
              </Button>
              <ButtonWithTooltip
                onClick={handleSubmit}
                sx={{ ml: '0.5rem' }}
                dataTestName="request__payment_configuration__save__button"
                disabled={disableSaveButton}
                tooltipText={
                  disableSaveButton
                    ? 'Total amount of all funding sources must be equal to the total approved amount'
                    : ''
                }
              >
                Save
              </ButtonWithTooltip>
            </Stack>
          </>
        )}
      </Stack>
    </Popup>
  );
};

export default RequestPaymentConfigurationEditPopup;
