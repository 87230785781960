import { ILoanError, QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router-dom';
import { currencyFormatter } from '@utils';
import { useGraphQuery } from '@context';
import { useMemo } from 'react';

interface Amounts {
  prefundingCost: string;
  scheduleOfValues: string;
  loanCommitment: string;
}

interface ControllerInterface {
  budgetAmounts: Amounts;
  loanAmounts: Amounts;
  showPrefundingCosts: boolean;
  showScheduleOfValues: boolean;
  showLoanCommitment: boolean;
}

export const useErrorsPopup = ({ errors }: { errors: ILoanError[] }): ControllerInterface => {
  const { projectId } = useParams();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    args: { project_id: projectId },
    nested: {
      loan: [
        'funding_source_inactive_amount',
        'funding_source_active_amount',
        'budget_post_funding_construction_budget',
        'budget_prefunding_cost',
      ],
    },
  });
  const loan = project.data?.loan;
  const showPrefundingCosts = useMemo(() => {
    return loan?.budget_prefunding_cost !== loan?.funding_source_inactive_amount;
  }, [loan]);
  const showScheduleOfValues = useMemo(() => {
    return loan?.budget_post_funding_construction_budget !== loan?.funding_source_active_amount;
  }, [loan]);
  const loanCommitmentError = useMemo(() => {
    return errors?.find((error) => error.title === 'Loan commitment');
  }, [errors]);

  return {
    budgetAmounts: {
      prefundingCost: currencyFormatter(loan?.budget_prefunding_cost || 0),
      scheduleOfValues: currencyFormatter(loan?.budget_post_funding_construction_budget || 0),
      loanCommitment: loanCommitmentError?.budget || '-',
    },
    loanAmounts: {
      prefundingCost: currencyFormatter(loan?.funding_source_inactive_amount || 0),
      scheduleOfValues: currencyFormatter(loan?.funding_source_active_amount || 0),
      loanCommitment: loanCommitmentError?.loan || '-',
    },
    showPrefundingCosts,
    showScheduleOfValues,
    showLoanCommitment: !!loanCommitmentError,
  };
};
