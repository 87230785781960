import { IDocumentsPreview } from '@interfaces';

type ContentType = 'documents' | 'photos' | 'combined';

export const getContentTooltipText = (
  documentsPreview: IDocumentsPreview | undefined,
  contentType: ContentType,
) => {
  const config = {
    documents: {
      hasThisRequestContent: documentsPreview?.has_not_seen_docs,
      hasOtherRequestsContent: documentsPreview?.has_docs,
      prefix: 'Documents',
    },
    photos: {
      hasThisRequestContent: documentsPreview?.has_not_seen_photos,
      hasOtherRequestsContent: documentsPreview?.has_photos,
      prefix: 'Progress photos',
    },
    combined: {
      hasThisRequestContent:
        documentsPreview?.has_not_seen_docs || documentsPreview?.has_not_seen_photos,
      hasOtherRequestsContent: documentsPreview?.has_docs || documentsPreview?.has_photos,
      prefix: 'Documents / progress photos',
    },
  };

  const { hasThisRequestContent, hasOtherRequestsContent, prefix } = config[contentType];

  if (hasThisRequestContent) {
    return `${prefix} in this request`;
  }
  if (hasOtherRequestsContent) {
    return `${prefix} in other requests`;
  }
  return `No ${prefix.toLowerCase()} yet`;
};
