import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

const borrowerCompletionRate: MilestoneListColumnTypeV2 = {
  field: 'borrower_completion_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Borrower completion (%)',
  description: 'Cumulative borrower request completion',
  editable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  minWidth: 120,
};

export default borrowerCompletionRate;
