import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import uniqBy from 'lodash/uniqBy';

import { Popup } from '@components';
import { ILoanError, PopupTypeEnum } from '@interfaces';
import { useErrorsPopup } from './controller';
import { useLaunchDarklyFlags } from '@context';

interface LoanErrorsPopupProps {
  errors: ILoanError[];
  setErrors: Dispatch<SetStateAction<ILoanError[]>>;
}

const StyledCell = styled(TableCell)(() => ({
  border: 0,
  padding: '12px 0',
}));

const ErrorRow: FC<{ title: string; loanValue: string; budgetValue: string }> = ({
  title,
  loanValue,
  budgetValue,
}) => {
  return (
    <TableRow>
      <StyledCell>
        <Typography variant="body2">{title}</Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="body2SemiBold">{loanValue}</Typography>
      </StyledCell>
      <StyledCell sx={{ textAlign: 'end' }}>
        <Typography variant="body2SemiBold">{budgetValue}</Typography>
      </StyledCell>
    </TableRow>
  );
};

const LoanErrorsPopupV2: FC<LoanErrorsPopupProps> = ({ errors, setErrors }) => {
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();
  const {
    budgetAmounts,
    loanAmounts,
    showPrefundingCosts,
    showScheduleOfValues,
    showLoanCommitment,
  } = useErrorsPopup({ errors });
  const navigate = useNavigate();
  const showTable = useMemo(
    () =>
      errors?.find((item) => item.loan || item.budget) &&
      (showPrefundingCosts || showScheduleOfValues || showLoanCommitment),
    [errors, showPrefundingCosts, showScheduleOfValues, showLoanCommitment],
  );

  if (!errors?.length) return null;

  return (
    <Popup open type={PopupTypeEnum.ERROR} title="Project activation error">
      <Stack sx={{ width: '100%' }}>
        <Stack spacing={2}>
          {uniqBy(errors, 'message').map((error, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            >
              {error.loan_fields ? (
                <>
                  <br />
                  <b>{error.message}:</b> {error.loan_fields}
                </>
              ) : (
                error.message
              )}
            </Typography>
          ))}
        </Stack>
        {showTable && (
          <>
            <Divider sx={{ mt: 3, mb: 1 }} />
            <TableContainer sx={{ px: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCell />
                    <StyledCell>
                      <Typography variant="body2">Loan details</Typography>
                    </StyledCell>
                    <StyledCell sx={{ textAlign: 'center' }}>
                      <Typography variant="body2">Budget</Typography>
                    </StyledCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showPrefundingCosts && (
                    <ErrorRow
                      title="Prefunding cost"
                      loanValue={loanAmounts.prefundingCost}
                      budgetValue={budgetAmounts.prefundingCost}
                    />
                  )}
                  {showScheduleOfValues && (
                    <ErrorRow
                      title="Schedule of values"
                      loanValue={loanAmounts.scheduleOfValues}
                      budgetValue={budgetAmounts.scheduleOfValues}
                    />
                  )}
                  {flags?.['ENG_10303_loc_commitment_validation'] && showLoanCommitment && (
                    <ErrorRow
                      title="Loan commitment"
                      loanValue={loanAmounts.loanCommitment}
                      budgetValue={budgetAmounts.loanCommitment}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={() => {
              setErrors(null);
            }}
            data-cy="project_activation_error__confirmation_dialog__cancel__button"
          >
            Cancel
          </Button>
          <Button
            variant="new"
            color="secondary"
            onClick={() => {
              setErrors(null);
              navigate(`/projects/${projectId}/budget`);
            }}
            data-cy="project_activation_error__confirmation_dialog__edit_budget__button"
          >
            Edit budget
          </Button>
          <Button
            onClick={() => navigate(`/projects/${projectId}/settings/loan`)}
            data-cy="project_activation_error__confirmation_dialog__edit_loan__button"
          >
            Edit loan
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default LoanErrorsPopupV2;
