import React, { useContext, useMemo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';

import { LabelAndValue, StatusChip, StyledLink, ProjectStatusClicableChip } from '@components';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { statusMap } from '@constants';
import { colors } from '@theme';
import { useProjectNameLabel } from './controller';
interface ComponentProps {
  showStatusChip?: boolean;
  prId?: string;
  isLink?: boolean;
}
const ProjectNameLabel = ({ showStatusChip = false, prId, isLink = true }: ComponentProps) => {
  const {
    settings: { display: staticStatuses },
    isCurrentProjectActive,
  } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();
  const { project } = useProjectNameLabel({ prId });
  const statusValue = useMemo(
    () => (staticStatuses ? statusMap(project?.status, staticStatuses, 'project') : {}),
    [project?.status, staticStatuses],
  );

  return (
    <Stack direction="row" spacing={1}>
      {isLink ? (
        <LabelAndValue label="Project:" size="body2" marginRightAfterLabel={false}>
          <StyledLink to={`/projects/${project?.id}`}>{project?.name}</StyledLink>
        </LabelAndValue>
      ) : (
        <Typography
          variant="h1"
          style={{ color: colors.text.heading }}
          data-cy="project__overview__name__value"
        >
          {project?.name}
        </Typography>
      )}
      {!flags?.['ENG_9657_show_inspections_in_services'] && showStatusChip && statusValue && (
        <Tooltip
          title={
            isCurrentProjectActive ? (
              ''
            ) : (
              <>
                Reason:{' '}
                <Typography
                  variant="body3"
                  dangerouslySetInnerHTML={{ __html: project?.status_change_reason }}
                />
              </>
            )
          }
        >
          <span>
            <StatusChip
              color={statusValue.color}
              backgroundColor={statusValue.backgroundColor}
              label={statusValue.text}
              size="medium"
            />
          </span>
        </Tooltip>
      )}
      {flags?.['ENG_9657_show_inspections_in_services'] && showStatusChip && (
        <ProjectStatusClicableChip />
      )}
    </Stack>
  );
};

export default ProjectNameLabel;
