import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const customer_name: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'customer.name',
  graphNested: {
    customer: ['name'],
  },
  headerName: 'Customer',
  minWidth: 130,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) => row?.customer?.name,
  flex: 1,
});

export default customer_name;
