import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, percentFormatter } from '@utils';

const lender_allowance_rate: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'lender_allowance_rate',
  graphFields: ['lender_allowance_rate'],
  headerName: 'Lender allowance (%)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
});

export default lender_allowance_rate;
