import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  DataTableV3,
  DocumentEditModal,
  DocumentSummaryList,
  Gallery,
  MessagePanel,
  PDFViewerNew,
  RightDrawer,
  SearchBox,
  ServiceMessage,
  SmartSummaryButton,
  TablePaginationNew,
} from '@components';
import { HookState, TableKeyEnum } from '@interfaces';
import { NoDataImage } from '@svgAsComponents';
import { IDocumentsWrapperProps } from '../../interface';
import { DownloadAllButton, UploadButton } from '../../components';
import { useDocumentsData } from '@hooks';
import { useDocumentsTable } from './controller';

const DocumentsTable: FC<
  IDocumentsWrapperProps & {
    handleOpenDocumentUploader: () => void;
  }
> = ({
  source,
  isAllDocumentsTab,
  prId,
  drawRequestId,
  deleteDocument,
  requestDocuments,
  milestoneId,
  inspectionId,
  milestoneSubmitId,
  serviceOrderId,
  restoreDocument,
  handleOpenDocumentUploader,
}) => {
  const {
    state,
    isLoading,
    documents,
    projectName,
    tablePagination,
    documentsCount,
    searchQuery,
    setSearchQuery,
  } = useDocumentsData({
    projectId: prId,
    drawRequestId,
    milestoneId,
    serviceOrderId,
    inspectionId,
  });

  const controller = useDocumentsTable({
    prId,
    requestId: drawRequestId,
    requestDocuments,
    milestoneId,
    milestoneSubmitId,
    inspectionId,
    serviceOrderId,
    source,
    isAllDocumentsTab,
    restoreDocument,
    documents,
  });

  const {
    columns,
    pdf,
    gallery,
    close,
    rightMenu,
    isRightDrawerDocs,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    openPdfViewer,
    activeDocument,
    closeDocumentEditModal,
    isDocumentUploadAllowed,
    manuallySelectedRowId,
  } = controller;

  switch (state) {
    case HookState.ERROR:
      return <ServiceMessage text="documents" />;

    case HookState.LOADING:
    case HookState.SUCCESS:
      return (
        <Stack sx={{ flex: 1, marginBottom: isRightDrawerDocs ? 9 : 0 }}>
          {!documents?.length && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ pb: 2, pr: 2, pt: 1 }}
            >
              <Box sx={{ width: '300px', ml: 2 }}>
                {searchQuery && (
                  <SearchBox
                    height="32px"
                    search={searchQuery}
                    onSubmit={(value) => setSearchQuery(value)}
                    clearSearch={() => setSearchQuery('')}
                  />
                )}
              </Box>
              <Stack direction="row" spacing={1.5}>
                {isDocumentUploadAllowed && (
                  <UploadButton onClick={handleOpenDocumentUploader} source={source} />
                )}
                <DownloadAllButton
                  documents={documents}
                  source={source}
                  drawRequestId={drawRequestId}
                  buttonLabel="Download"
                />
              </Stack>
            </Stack>
          )}

          {Boolean(
            state === HookState.SUCCESS &&
              !documents?.length &&
              ((!milestoneId && !milestoneSubmitId) || !isRightDrawerDocs),
          ) && (
            <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
              <NoDataImage size={200} />
              <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
                {searchQuery ? 'No documents match your search' : 'No documents yet'}
              </Typography>
            </Stack>
          )}

          {Boolean(documents?.length) && (
            <DataTableV3
              tableKey={TableKeyEnum.DOCUMENTS_LIST}
              columns={columns as GridColDef[]}
              rows={documents.filter((doc) => !!doc.id) || []}
              controllerLoading={isLoading}
              withBuiltInSearch={false}
              withoutAdaptiveScroll
              selectedRowId={manuallySelectedRowId}
              headerRight={[
                {
                  Component: (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1.5}
                    >
                      <SmartSummaryButton
                        size="small"
                        onClick={openAllDocsSummary}
                        source={source}
                      />
                      {isDocumentUploadAllowed && (
                        <UploadButton onClick={handleOpenDocumentUploader} source={source} />
                      )}
                      <DownloadAllButton
                        documents={documents}
                        source={source}
                        drawRequestId={drawRequestId}
                        buttonLabel="Download"
                      />
                    </Stack>
                  ),
                },
              ]}
              footerRight={[
                {
                  Component: (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1.5}
                    >
                      {Boolean(documents?.length) && (
                        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2, pr: 2 }}>
                          <TablePaginationNew
                            page={tablePagination.page}
                            rowsPerPage={tablePagination.rowsPerPage}
                            rowsPerPageOptions={tablePagination.rowsPerPageOptions}
                            itemsCount={documentsCount}
                            onPageChange={tablePagination.onPageChange}
                            onRowsPerPageChange={tablePagination.onRowsPerPageChange}
                            source={`${source}__list`}
                          />
                        </Stack>
                      )}
                    </Stack>
                  ),
                },
              ]}
              headerLeft={[
                {
                  Component: (
                    <SearchBox
                      height="32px"
                      search={searchQuery}
                      onSubmit={(value) => setSearchQuery(value)}
                      clearSearch={() => setSearchQuery('')}
                    />
                  ),
                },
              ]}
            />
          )}

          {activeDocument && (
            <DocumentEditModal
              document={activeDocument}
              closeDocumentEditModal={closeDocumentEditModal}
              deleteDocument={deleteDocument}
              prId={prId}
            />
          )}
          {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
          {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
          <RightDrawer {...rightMenu}>
            <MessagePanel {...rightDrawerParams} source={`${source}__list__comments`} />
          </RightDrawer>
          <RightDrawer
            {...docSummaryMenu}
            drawerWidth="720px"
            right={isRightDrawerDocs ? '600px' : '0'}
          >
            <DocumentSummaryList
              {...docSummaryParams}
              projectName={projectName}
              rows={documents}
              source={`${source}__list__summary`}
              openPdfViewer={openPdfViewer}
              tablePagination={tablePagination}
              documentsCount={documentsCount}
            />
          </RightDrawer>
        </Stack>
      );

    default:
      return null;
  }
};

export default DocumentsTable;
