import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType, currencyFormatter } from '@utils';

const construction_holdback_available: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'construction_holdback_available',
  graphFields: ['construction_holdback_available'],
  headerName: 'Remaining construction holdback ($))',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default construction_holdback_available;
