import { useGridApiRef } from '@mui/x-data-grid-premium';
import useTableSettings from './useTableSettings';
import useTableScrollPin from './useTableScrollPin';
import useTableDataUpdate from './useTableDataUpdate';
import useTableState from './useTableState';
import { UseTableInterface } from '../interface';

const useDataTable = ({
  withoutAdaptiveScroll,
  tableKey,
  errors,
  rowUpdateApi,
  rows,
  totals,
  columns,
  bulkUpdateApi,
  rowReordering,
  withColumnIndex,
  withSingleClickHandle,
  withProgressiveColumns,
  selectedRowId,
  inContainer,
  masterContainerRef,
}: UseTableInterface) => {
  const apiRef = useGridApiRef();
  const tableScroll = useTableScrollPin(
    apiRef,
    withoutAdaptiveScroll,
    rows.length,
    inContainer,
    masterContainerRef,
  );
  const state = useTableState(
    apiRef,
    columns,
    rows,
    errors,
    totals,
    rowReordering,
    withColumnIndex,
    withSingleClickHandle,
    tableKey,
    withProgressiveColumns,
    selectedRowId,
  );
  const settings = useTableSettings(
    tableKey,
    apiRef,
    state.setTableLoadingBlock,
    state.memoColumns,
    rowReordering,
    withColumnIndex,
  );
  const update = useTableDataUpdate(state.setTableLoadingBlock, rowUpdateApi, bulkUpdateApi);

  return {
    apiRef,
    ...settings,
    ...tableScroll,
    ...state,
    ...update,
  };
};

export default useDataTable;
