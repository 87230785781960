import {
  FunctionArguments,
  FunctionReturnType,
  NestedKeys,
  FilteredDataByGraph,
} from '@interfaces';
import { dataProviders } from '../provider';
import { useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import useGraph from './useGraph';
import { filterDataByGraphStructure } from '@utils';

export function useGraphQuery<
  P extends keyof typeof dataProviders,
  ReturnType = FunctionReturnType<(typeof dataProviders)[P]>,
  IsResultsType = ReturnType extends { results: unknown[] } ? true : false,
  IsArrayType = ReturnType extends Array<unknown> ? true : false,
  DataType = IsResultsType extends true
    ? ReturnType extends { results: (infer R)[] }
      ? R
      : never
    : IsArrayType extends true
      ? ReturnType extends Array<infer R>
        ? R
        : never
      : ReturnType,
  K extends keyof DataType = keyof DataType,
  N extends NestedKeys<DataType> = object,
  O extends { skip?: boolean } = { skip?: boolean },
  A extends FunctionArguments<(typeof dataProviders)[P]> = FunctionArguments<
    (typeof dataProviders)[P]
  >,
>({
  type,
  keys,
  args,
  nested,
  options,
}: {
  type: P;
  keys?: K[];
  nested?: N;
  args: A;
  options?: O;
}) {
  type FilteredType = FilteredDataByGraph<DataType, K, N>;
  type ReturnDataType = IsResultsType extends true
    ? { results: FilteredType[]; count: number; previous: string; next: string }
    : IsArrayType extends true
      ? FilteredType[]
      : FilteredType;

  const queryClient = useQueryClient();
  const queryKey = useMemo<[P, A]>(() => [type, args], [type, args]);
  const shortKey = useMemo<string>(() => JSON.stringify(queryKey), [queryKey]);
  const shortKeys = useMemo<string>(() => JSON.stringify({ keys, nested }), [keys, nested]);

  const { graphReady, isPostLoading } = useGraph(queryKey, {
    keys: keys as string[],
    nested: nested as Record<string, string[]>,
  });

  const { dataUpdatedAt, data, ...queryData } = useQuery(
    queryKey,
    queryClient.getGraphBoundFunction(queryKey),
    {
      enabled: graphReady && !options?.skip,
      select: (data) => {
        if (data && typeof data === 'object') {
          if ('results' in data) {
            return data;
          } else if (Array.isArray(data)) {
            return data.map((item) =>
              filterDataByGraphStructure(item, {
                keys: keys as string[],
                nested: nested as Record<string, string[]>,
              }),
            );
          } else if (typeof data === 'object') {
            const filtered = filterDataByGraphStructure(data, {
              keys: keys as string[],
              nested: nested as Record<string, string[]>,
            });
            return filtered;
          }
        }
        return data;
      },
    },
  );

  useEffect(() => {
    if (!options?.skip) {
      queryClient.graphRegister(
        queryKey,
        { keys: keys as string[], nested: nested as Record<string, string[]> },
        { automaticReady: true },
      );
    }
  }, [options?.skip, shortKey, shortKeys]);

  return { data: data as ReturnDataType, ...queryData, isPostLoading };
}
