import { ColumnType, getServiceAgencyNameByRole } from '@utils';

const serviceProvider: ColumnType = ({ permissions }) => ({
  field: 'service_agency.display_name',
  graphNested: {
    service_agency: ['display_name'],
  },
  headerName: 'Provider',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) =>
    getServiceAgencyNameByRole({ serviceAgency: row?.service_agency, permissions }),
});

export default serviceProvider;
