import React, { FC } from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { IMilestoneTag } from '@interfaces';
import { colors } from '@theme';

const TAG_COLORS = [
  {
    text: colors.fundingSource.green.medium,
    background: colors.fundingSource.green.lighter,
  },
  {
    text: colors.fundingSource.blue.medium,
    background: colors.fundingSource.blue.lighter,
  },
  {
    text: colors.fundingSource.orange.medium,
    background: colors.fundingSource.orange.lighter,
  },
  {
    text: colors.fundingSource.violet.medium,
    background: colors.fundingSource.violet.lighter,
  },
  {
    text: colors.fundingSource.yellow.medium,
    background: colors.fundingSource.yellow.lighter,
  },
];

export interface LineItemTagChipProps {
  tag: IMilestoneTag;
  colorIndex: number;
  onDelete?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  variant?: 'default' | 'outlined';
}

const LineItemTagChip: FC<LineItemTagChipProps> = ({
  tag,
  colorIndex,
  onDelete,
  onClick,
  variant = 'default',
}) => {
  const tagColor = TAG_COLORS[colorIndex % TAG_COLORS.length];

  return (
    <Tooltip title={tag.name} arrow>
      <Chip
        key={tag.id}
        label={tag.name}
        size="small"
        onClick={onClick}
        onDelete={onDelete}
        icon={
          <Box
            component="span"
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: tagColor.text,
              ml: 1,
            }}
          />
        }
        sx={{
          maxWidth: 150,
          height: 24,
          backgroundColor: variant === 'default' ? tagColor.background : 'transparent',
          borderRadius: '2px',
          '& .MuiChip-label': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            px: 1.5,
            color: tagColor.text,
            fontWeight: 400,
            fontSize: '0.875rem',
          },
          '& .MuiChip-icon': {
            ml: 1,
            mr: -0.5,
            order: -1,
          },
          '& .MuiChip-deleteIcon': {
            color: tagColor.text,
            '&:hover': {
              color: tagColor.text,
              opacity: 0.7,
            },
          },
        }}
      />
    </Tooltip>
  );
};

export default LineItemTagChip;
