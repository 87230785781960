import { percentFormatter } from '@utils';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const gap: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'gap',
  graphFields: ['gap'],
  headerName: 'Variance to lender allowance (%)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
});

export default gap;
