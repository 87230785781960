import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const addressCity: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'address.city',
  graphNested: {
    address: ['city'],
  },
  headerName: 'City',
  minWidth: 130,
  groupable: false,
  aggregable: false,
  valueGetter: (_params, row: Partial<IProjectsPortfolioItem>) => row?.address?.city,
  flex: 1,
});

export default addressCity;
