import { useDayJsFormatter } from '@hooks';
import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const loan_close_date: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'loan.close_date',
  graphNested: {
    loan: ['close_date'],
  },
  headerName: 'Funding date',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const { dateFormatter } = useDayJsFormatter();
    return dateFormatter({ date: row?.loan?.close_date });
  },
});

export default loan_close_date;
