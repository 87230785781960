import { dataProvider, dataProviderV2 } from '@api';
import {
  projectsCompaniesApi,
  projectsInspectionAgenciesApi,
  projectsTeamsApi,
  projectsUsersApi,
  projectsTypesApi,
  projectsRolesApi,
  projectsServiceAgenciesApi,
  projectApi,
  projectsDrawRequestsApi,
  projectsDocumentTypesApi,
  projectsApi,
  projectsServiceOrdersApi,
} from '../../paths';
import {
  ArgumentsProjectsCompaniesApi,
  ArgumentsProjectApi,
  ArgumentsProjectsUserApi,
  ArgumentsServiceAgenciesApi,
} from '../../args';
import {
  IApiPath,
  ICompany,
  IRoleNew,
  ITeam,
  IUser,
  IProject,
  IDrawRequest,
  IDocumentType,
  IServiceAgency,
  IServiceOrder,
} from '@interfaces';

export const getProjectsCompaniesV2 = async (
  args: ArgumentsProjectsCompaniesApi,
): Promise<{ results: ICompany[] }> => dataProvider.get(projectsCompaniesApi(args)).json();

export const getProjectsUsersV2 = async (
  args: ArgumentsProjectsUserApi,
): Promise<{ results: IUser[] }> => dataProvider.get(projectsUsersApi(args)).json();

export const getProjectsTeamsV2 = async (
  args: ArgumentsProjectsUserApi,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectsTeamsApi(args)).json();

export const getProjectsInspectionAgenciesV2 = async (
  args: IApiPath,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectsInspectionAgenciesApi(args)).json();

export const getProjectsServiceAgenciesV2 = async (
  args: ArgumentsServiceAgenciesApi,
): Promise<{ results: IServiceAgency[] }> =>
  dataProvider.get(projectsServiceAgenciesApi(args)).json();

export const getProjectsTypesV2 = async (args: IApiPath): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectsTypesApi(args)).json();

export const getProjectDocumentTypesV2 = async (args: IApiPath): Promise<IDocumentType[]> =>
  dataProvider.get(projectsDocumentTypesApi(args)).json();

export const getProjectsRolesV2 = async (
  args: ArgumentsProjectApi,
): Promise<{ results: IRoleNew[] }> => dataProvider.get(projectsRolesApi(args)).json();

export const getProjectV2 = async (args: ArgumentsProjectApi): Promise<IProject> =>
  dataProvider.get(projectApi(args)).json();

export const getProjectsV2 = async (
  args: IApiPath,
): Promise<{ results: IProject[]; count: number }> => dataProviderV2.get(projectsApi(args)).json();

export const getProjectsDrawRequestsV2 = async (
  args: IApiPath,
): Promise<{ results: IDrawRequest[]; count: number }> => {
  return dataProviderV2.get(projectsDrawRequestsApi(args)).json();
};

export const getServiceOrdersListV2 = async (
  args: IApiPath,
): Promise<{ results: IServiceOrder[] }> => dataProvider.get(projectsServiceOrdersApi(args)).json();

// TODO: maybe need common function for next page
export const getNextPage = async (directUrls: string): Promise<any> =>
  await dataProvider.get(directUrls).json();
