import { IProjectsPortfolioItem } from '@interfaces';
import { ColumnType } from '@utils';

const borrower_name: ColumnType<IProjectsPortfolioItem> = () => ({
  field: 'borrower_name',
  graphFields: ['borrower_name'],
  headerName: 'Borrower',
  minWidth: 120,
  groupable: false,
  aggregable: false,
});

export default borrower_name;
