import React, { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import {
  ColumnsFilter,
  DrawRequestsListFilters,
  FiltersTypes,
  Filters,
  ProjectsStats,
  RightDrawer,
  SetupNotificationsPopup,
  StyledBox,
  TableItemRightDrawer,
  TablePaginationNew,
  VerticalMenu,
  ViewSwitcher,
  ProjectsBulkPanel,
  AssignCoordinatorPopup,
  AssignInvestorPopup,
} from '@components';
import { useProjects } from './controller';
import { PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import ProjectsList from '../ProjectsList';
import { WithPermission } from '@utils';
import ProjectCardsList from '../ProjectCardsList';
import ProjectsListV2 from '../ProjectsListV2';
import { useLaunchDarklyFlags } from '@context';

const Projects = () => {
  const {
    tablePagination,
    isListView,
    updateListView,
    addProjectMenuItems,
    setFilterStringQuery,
    columns,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    rightMenu,
    rightDrawerParams,
    isLoading,
    isFetching,
    isError,
    projects,
    filteredProjectsCount,
    handleSortClick,
    sortValue,
    selectedProjects,
    unSelectAllProjects,
    showNotificationsPopup,
    setShowNotificationsPopup,
    showNotificationSetupPopup,
    onFiltersReady,
    filterStringQuery,
    filtersReady,
    isLender,
    selectedProjectCompanyIds,
    canApplyBulkPolicies,
    coordinatorPopupOpen,
    setCoordinatorPopupOpen,
    currentProject,
    investorPopupOpen,
    setInvestorPopupOpen,
  } = useProjects();
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    tablePagination;
  const flags = useLaunchDarklyFlags();
  const filters = useMemo(
    () => [
      'textSearchFilter',
      ...(flags?.['ENG_10222_inactive_projects_visibility']
        ? ['projectStatusFilterV2']
        : ['projectStatusFilter']),
      'projectTypeFilter',
      'borrowerFilter',
      'projectHealthFilter',
      'loansTypeFilter',
      'propertyExistingTypeFilter',
      'propertyProposedTypeFilter',
      'customerFilter',
      'investorFilter',
      'lenderFilter',
      'coordinatorProjectFilter',
      'isBudgetLockedFilter',
      'statesFilter',
    ],
    [flags?.['ENG_10222_inactive_projects_visibility']],
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flexGrow: 1,
          p: 2,
        }}
      >
        <StyledBox
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography variant="h2" sx={{ mr: 2 }}>
            Projects {filteredProjectsCount ? `(${filteredProjectsCount})` : ''}
          </Typography>
          <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
            <Stack direction="row" spacing={2} ml={5}>
              <VerticalMenu
                menuItems={addProjectMenuItems}
                closeOnItemClick={false}
                anchorOriginHorizontal="left"
              >
                <Button data-cy="projects__add__menu" size="small">
                  Add project
                </Button>
              </VerticalMenu>
            </Stack>
          </WithPermission>
        </StyledBox>
        {!!filteredProjectsCount && (
          <ProjectsStats filterStringQuery={filterStringQuery} filtersReady={filtersReady} />
        )}
        <StyledBox
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            px: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            sx={{ mb: 3 }}
          >
            <Filters
              onReady={onFiltersReady}
              tableKey={TableKeyEnum.RISK_RADAR}
              source="projects__table"
              setFilterStringQuery={setFilterStringQuery}
              LeftComponent={DrawRequestsListFilters}
              padding={0}
              filters={filters as FiltersTypes[]}
              onPageChange={onPageChange}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                '& a': { textDecoration: 'none' },
                ml: 2,
                minWidth: '430px',
              }}
            >
              <ViewSwitcher
                isListView={isListView}
                source="projects"
                handleChangeView={(listView) => updateListView({ listView })}
              />
              <ColumnsFilter
                columns={columns}
                hiddenColumns={hiddenColumns}
                changeFieldVisibility={changeFieldVisibility}
                isUpdating={isColumnFilterUpdating}
                source="projects__table"
                disabled={!isListView}
              />
            </Stack>
          </Stack>
          {isListView ? (
            flags?.[`ENG_7895_table_v3__${TableKeyEnum.RISK_RADAR}`] ? (
              <ProjectsListV2 />
            ) : (
              <ProjectsList
                columns={columns}
                hiddenColumns={hiddenColumns}
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                projects={projects}
                handleSortClick={handleSortClick}
                sortValue={sortValue}
              />
            )
          ) : (
            <ProjectCardsList
              projects={projects}
              isLoading={isLoading || isFetching}
              isError={isError}
            />
          )}

          {/* Pagination at the bottom of the table */}
          {filteredProjectsCount > 0 && (
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2, pr: 2 }}>
              <TablePaginationNew
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={filteredProjectsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                source="projects"
              />
            </Stack>
          )}
        </StyledBox>
      </Stack>
      <RightDrawer {...rightMenu}>
        <TableItemRightDrawer
          projectId={rightDrawerParams.projectId}
          activeTab={rightDrawerParams.activeTab}
          source="projects__right_drawer"
        />
      </RightDrawer>

      {selectedProjects.length > 0 && (isLender || canApplyBulkPolicies) && (
        <ProjectsBulkPanel
          selectedProjectsIds={selectedProjects}
          selectedProjectCompanyIds={selectedProjectCompanyIds}
          unSelectAllProjects={unSelectAllProjects}
          onWatchComplete={showNotificationSetupPopup}
          isLender={isLender}
          canApplyBulkPolicies={canApplyBulkPolicies}
        />
      )}

      {showNotificationsPopup && (
        <SetupNotificationsPopup onClose={() => setShowNotificationsPopup(false)} />
      )}

      {investorPopupOpen && (
        <AssignInvestorPopup
          open={investorPopupOpen}
          projectId={[currentProject?.id]}
          onClose={() => setInvestorPopupOpen(false)}
        />
      )}

      {coordinatorPopupOpen && (
        <AssignCoordinatorPopup
          open={coordinatorPopupOpen}
          prId={currentProject?.id}
          initCoordinator={currentProject?.coordinator}
          onClose={() => setCoordinatorPopupOpen(false)}
        />
      )}
    </Stack>
  );
};

export default Projects;
