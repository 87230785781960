import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { CenteredStyledBox, FullScreenDialog, LoadingSkeleton } from '@components';
import { colors } from '@theme';
import { TutorialIcon } from '@svgAsComponents';
import { useServiceProviderSelection } from './controller';
import { IServiceAgency } from '@interfaces';
import { CustomRadioGroupGridLayout } from './components';

interface ServiceProviderSelectionProps {
  serviceAgenciesList: IServiceAgency[];
  serviceAgenciesListIsLoading: boolean;
  agencySelected: IServiceAgency | null;
  setAgencySelected: (value: IServiceAgency) => void;
  defaultInspectionAgency: Pick<IServiceAgency, 'id' | 'display_name' | 'service_provider'> | null;
}

const ServiceProviderSelection: React.FC<ServiceProviderSelectionProps> = ({
  serviceAgenciesList = [],
  serviceAgenciesListIsLoading,
  agencySelected,
  setAgencySelected,
  defaultInspectionAgency = null,
}) => {
  const {
    handleWhatIsTheBestOptionClick,
    handleInfoIconClick,
    detailsPopup,
    setDetailsPopup,
    allOptions,
    radioOptionSelected,
    setRadioOptionSelected,
    manualServiceField,
  } = useServiceProviderSelection(
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    defaultInspectionAgency,
  );

  return (
    <CenteredStyledBox>
      <Stack spacing={2} direction="row" alignItems="center" mb={4}>
        <Typography variant="h3">Service provider</Typography>
        {serviceAgenciesListIsLoading && <LoadingSkeleton type="overviewBlock" />}

        {!!serviceAgenciesList?.length && (
          <Stack
            onClick={handleWhatIsTheBestOptionClick}
            direction="row"
            alignItems="center"
            sx={{
              backgroundColor: colors.status.information.lighter,
              borderRadius: 0.5,
              minHeight: 24,
              py: 0.5,
              px: 1,
              cursor: 'pointer',
            }}
            spacing={0.5}
          >
            <TutorialIcon color={colors.status.information.medium} size={16} />
            <Typography
              variant="label"
              sx={{ color: colors.status.information.medium, fontWeight: 600 }}
            >
              What is the best option?
            </Typography>
          </Stack>
        )}
      </Stack>

      <Box sx={{ width: '100%' }}>
        <CustomRadioGroupGridLayout
          dataTestName="order_service__service_agencies__radio__group"
          value={radioOptionSelected || ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value === 'manual_input') {
              const selectedManualAgency = serviceAgenciesList.find(
                (agency) => agency.id === manualServiceField.value?.value,
              );
              if (selectedManualAgency) {
                setAgencySelected(selectedManualAgency);
              }
            } else {
              const selectedAgency = serviceAgenciesList.find((agency) => agency.id === value);
              if (selectedAgency) {
                setAgencySelected(selectedAgency);
              }
            }
            setRadioOptionSelected(value);
          }}
          options={allOptions}
          handleInfoIconClick={handleInfoIconClick}
          isLoading={serviceAgenciesListIsLoading}
          columnsCount={2}
        />
      </Box>

      <FullScreenDialog
        open={detailsPopup.open}
        title={detailsPopup.title}
        actionButtons={null}
        onClose={() => setDetailsPopup({ open: false, title: '', content: <></> })}
        content={() => detailsPopup.content}
      />
    </CenteredStyledBox>
  );
};

export default ServiceProviderSelection;
