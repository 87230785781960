import React, { ChangeEvent, FC } from 'react';
import {
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  Box,
  SxProps,
  IconButton,
} from '@mui/material';
import { colors } from '@theme';
import { InfoIcon, InspectionIcon, ManualServiceIcon } from '@svgAsComponents';
import { isServiceProviderManual } from '@utils';
import { LoadingSkeleton } from '@components';
import { IServiceAgency } from '@interfaces';

type CustomOption = IServiceAgency & {
  customComponent?: React.ReactNode;
  value?: string;
  label?: string;
};

interface CustomRadioGroupProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: CustomOption[];
  dataTestName?: string;
  disabled?: boolean;
  disabledTooltipText?: string;
  radioButtonStyle?: SxProps;
  columnsCount?: number;
  handleInfoIconClick?: (item: CustomOption) => void;
  isLoading?: boolean;
}

const renderLabel = (option: CustomOption, disabled?: boolean, disabledTooltipText?: string) => {
  if (option.customComponent) {
    return <Box sx={{ width: '100%' }}>{option.customComponent}</Box>;
  }

  if (option.logo) {
    return (
      <img
        alt="service agency logo"
        src={option.logo}
        style={{ minHeight: '34px', maxHeight: '34px' }}
      />
    );
  }

  if (isServiceProviderManual(option.service_provider)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          backgroundColor: colors.main.primary.lightest,
          height: '40px',
          padding: '0 8px',
          borderRadius: '2px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <ManualServiceIcon
          size={24}
          color={disabled ? colors.neutral.dark : colors.neutral.darker}
        />
        <Typography variant="labelSemiBold" noWrap>
          {option.display_name}
        </Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={disabled ? disabledTooltipText : ''}>
      <Typography variant="body2" sx={disabled ? { color: colors.neutral.dark } : {}}>
        {option.display_name}
      </Typography>
    </Tooltip>
  );
};

export const CustomRadioGroupGridLayout: FC<CustomRadioGroupProps> = ({
  value,
  onChange,
  options,
  dataTestName,
  disabled,
  disabledTooltipText,
  radioButtonStyle = {},
  columnsCount = 3,
  handleInfoIconClick,
  isLoading,
}) => {
  if (isLoading) {
    return <LoadingSkeleton type="overviewBlock" />;
  }

  const handleCustomOptionClick = () => {
    const event = {
      target: {
        value: 'manual_input',
        name: 'inputType',
      },
    } as unknown as ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  return (
    <RadioGroup
      aria-label="inputType"
      name="inputType"
      value={value}
      onChange={onChange}
      data-cy={dataTestName}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
          gap: 2,
          alignItems: 'start',
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            data-cy={`${dataTestName}_${option.id.toLowerCase()}`}
            control={
              <Radio
                disabled={disabled}
                onClick={() => {
                  if (option.customComponent) {
                    handleCustomOptionClick();
                  }
                }}
                sx={{
                  padding: '4px',
                }}
              />
            }
            label={
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{
                  width: '100%',
                  minHeight: '32px',
                }}
              >
                {renderLabel(option, disabled, disabledTooltipText)}

                {option.is_premium && !option.customComponent && (
                  <Chip
                    sx={{
                      backgroundColor: colors.status.orange.lighter,
                      borderRadius: '2px',
                      height: '24px',
                      padding: '4px 2px',
                      '& .MuiChip-label': { padding: 0 },
                      flexShrink: 0,
                    }}
                    label={
                      <Typography
                        variant="labelSemiBold"
                        sx={{
                          color: colors.status.orange.medium,
                          letterSpacing: '0.3px',
                          padding: '0 4px',
                        }}
                      >
                        Premium
                      </Typography>
                    }
                    icon={<InspectionIcon size={16} color={colors.status.orange.medium} />}
                  />
                )}
                {option.description && !option.customComponent && (
                  <IconButton
                    onClick={() => handleInfoIconClick?.(option)}
                    sx={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      padding: '4px',
                      flexShrink: 0,
                    }}
                  >
                    <InfoIcon size={20} color={colors.status.information.medium} />
                  </IconButton>
                )}
              </Box>
            }
            sx={{
              margin: 0,
              '& .MuiFormControlLabel-label': {
                display: 'flex',
                width: '100%',
              },
              alignItems: 'center',
              ...radioButtonStyle,
            }}
          />
        ))}
      </Box>
    </RadioGroup>
  );
};
